import React from "react";
import Immutable from "immutable";

import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Groups from "js/common/groups";
import * as Rata from "js/common/utils/remote-data";
import * as Users from "js/common/users";

export const dashboardColumns = 48;
export const columnSpacing = 13;

export const createBlankDashboard = dataConfig => Immutable.fromJS({
  name: "My New Dashboard",
  configType: "DASHBOARD",
  ownerId: Users.getCurrentUser().get("id"),
  json: {
    dataConfig,
    components: [],
    layoutType: "infinite"
  }
});

export const updateQualifierForLoggedInUserOrGroup = qualifier => {
  if (qualifier.get("id") === 0) {
    if (qualifier.get("type") === "USER") {
      return qualifier.set("id", Users.getCurrentUser().get("id"));
    } else if (qualifier.get("type") === "GROUP") {
      return qualifier.set("id", Users.getCurrentUser().get("groupId"));
    }
  } else {
    return qualifier;
  }
};

export const renderUserOrGroupDetails = (config) => {
  let groupId;
  let isCurrentlyViewingUser;
  const updatedQualifier = updateQualifierForLoggedInUserOrGroup(config.get("qualifier"));
  if (updatedQualifier.get("type") === "USER") {
    groupId = Users.getUser(updatedQualifier.get("id")).get("groupId");
    isCurrentlyViewingUser = true;
  } else {
    groupId = updatedQualifier.get("id");
    isCurrentlyViewingUser = false;
  }

  const breadcrumbs = Groups.getGroupBreadcrumbs(groupId);
  const dividerStyle = {fontSize: "0.725rem", paddingLeft: 5, paddingRight: 5};
  return (
      <span style={{paddingLeft: 5}}>
              {breadcrumbs.map((crumb, i) => {
                const isFirstCrumb = i === 0;
                const isLastCrumb = i === breadcrumbs.length - 1;
                return (
                    <span key={`crumb-${i}`}>
                          {`${isFirstCrumb ? "for " : ""}${crumb.name}`}
                      {!isLastCrumb &&
                          <i className="fa fa-chevron-right" style={dividerStyle} />}
                      </span>
                );
              })}
        {isCurrentlyViewingUser &&
            <span><i className="fa fa-chevron-right" style={dividerStyle} />
              {Users.getUser(updatedQualifier.get("id")).get("fullName")}
                </span>
        }
          </span>);
};

export const configToQualifierAjaxParams = config => {
  const qualifier = updateQualifierForLoggedInUserOrGroup(config.get("qualifier"));
  return qualifierToAjaxParams(qualifier.get("id"), qualifier.get("type"));
};

export const qualifierToAjaxParams = (id, type) => {
  switch (type) {
    case "GROUP":
      return {groupId: id};
    case "USER":
      return {userId: id};
    default:
      throw new Error("unexpected qualifier type: " + type);
  }
};

export const applyParentConfigToDashboard = (dashboard, parentConfig, componentTypeToTemplate) => {
  return dashboard
      .setIn(["json", "dataConfig"], parentConfig)
      .updateIn(["json", "components"], cs => cs.map(component => {
        const template = componentTypeToTemplate.get(component.get("type"));
        const applyParent = template.get("applyParentConfig");
        return component.update("dataConfig", dataConfig => applyParent(parentConfig, dataConfig));
      }));
};

export const LoadingBalls = React.memo(() => {
  return <div className="novo-loading">
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
  </div>;
});

export const useEditorFlags = () => {
  const isFullEditor = React.useMemo(
      () => Users.hasPermission(Users.getCurrentUser(), "DASHBOARD_FULL_EDITOR"),
      []);
  const isBasicEditor = React.useMemo(
      () => isFullEditor || Users.hasPermission(Users.getCurrentUser(), "DASHBOARD_BASIC_EDITOR"),
      [isFullEditor]);
  return {isFullEditor, isBasicEditor};
};

const incrementLoadIdForComponent = (componentIdToLoadIdRef, componentId) => {
  const componentIdToLoadId = componentIdToLoadIdRef.current;
  componentIdToLoadIdRef.current = componentIdToLoadId.update(componentId, 0, x => x + 1);
};

export const loadDataForDashboard = (componentIdToLoadIdRef, dashboard, queueDataLoad, setIdToData, componentTypeToTemplate) => {
  let newIdToData = Immutable.Map();
  const components = dashboard.getIn(["json", "components"]).sortBy(component => component.getIn(["layout", "y"]));
  for (const component of components) {
    incrementLoadIdForComponent(componentIdToLoadIdRef, component.get("id"));
    const id = getDataId(componentIdToLoadIdRef.current, component);
    const initialData = getInitialComponentData(component, componentTypeToTemplate);
    const template = componentTypeToTemplate.get(component.get("type"));
    const load = template.get("load");
    if (load) {
      newIdToData = newIdToData.set(id, Rata.toLoading(initialData));
      queueDataLoad(() => {
        return load(component).then(
            response => {
              setIdToData(idToData => idToData.update(id, data => Rata.toLoaded(data, response)));
            },
            error => {
              setIdToData(idToData => idToData.update(id, data => Rata.toError(data, error)));
            });
      });
    } else {
      newIdToData = newIdToData.set(id, Rata.toLoaded(initialData, Immutable.Map()));
    }
  }
  setIdToData(newIdToData);
};

export const loadDataForComponent = (
    componentId,
    componentIdToLoadIdRef,
    dashboard,
    queueDataLoad,
    setIdToData,
    componentTypeToTemplate
) => {
  const component = dashboard.getIn(["json", "components"], Immutable.List()).find(c => c.get("id") === componentId);
  incrementLoadIdForComponent(componentIdToLoadIdRef, component.get("id"));
  const dataId = getDataId(componentIdToLoadIdRef.current, component);
  const load = componentTypeToTemplate.get(component.get("type")).get("load");
  const initialData = getInitialComponentData(component, componentTypeToTemplate);
  if (load) {
    setIdToData(idToData => idToData.set(dataId, Rata.toLoading(initialData)));

    queueDataLoad(() => {
      return load(component).then(
          response => {
            setIdToData(idToData => idToData.update(dataId, data => Rata.toLoaded(data, response)));
          },
          error => {
            setIdToData(idToData => idToData.update(dataId, data => Rata.toError(data, error)));
          });
    });
  } else {
    setIdToData(idToData => idToData.set(dataId, Rata.toLoaded(initialData, Immutable.Map())));
  }
};

export const useDataLoadingQueue = () => {
  const concurrency = 4;
  const fnQueueRef = React.useRef({running: 0, queue: []});

  const ensureRunning = React.useCallback(() => {
    const {queue} = fnQueueRef.current;

    while (queue.length > 0 && fnQueueRef.current.running < concurrency) {
      fnQueueRef.current.running++;
      const {fn, promiseResolve, promiseReject} = queue.shift();
      fn()
          .then(result => promiseResolve(result), error => promiseReject(error))
          .finally(() => {
            fnQueueRef.current.running--;
            ensureRunning();
          });
    }
  }, []);

  const clear = React.useCallback((shouldDropItemFn = () => true) => {
    fnQueueRef.current.queue = fnQueueRef.current.queue.filter(item => !shouldDropItemFn(item));
  }, []);

  const queue = React.useCallback((fn, {componentId, componentLoadId, priority = false} = {}) => {
    const {queue} = fnQueueRef.current;
    let promiseResolve;
    let promiseReject;
    const promise = new Promise((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });
    const item = {fn, componentId, componentLoadId, promiseResolve, promiseReject};
    if (priority) {
      queue.unshift(item);
    } else {
      queue.push(item);
    }
    ensureRunning();
    return promise;
  }, [ensureRunning]);

  return {
    clear,
    queue
  };
};

export const getInitialComponentData = (component, componentTypeToTemplate) => {
  const template = componentTypeToTemplate.get(component.get("type"));
  return Rata.wrapInitialValue(template.get("getDefaultData")());
};

export const getInitialComponentIdToData = (componentIdToLoadId, dashboard, componentTypeToTemplate) => {
  return dashboard
      .getIn(["json", "components"])
      .reduce(
          (m, component) => {
            const initialData = getInitialComponentData(component, componentTypeToTemplate);
            return m.set(getDataId(componentIdToLoadId, component), initialData);
          },
          Immutable.Map());
};

export const isBasicEditor = () => Users.getCurrentUser().hasPermission("DASHBOARD_BASIC_EDITOR");

export const getDataId = (componentIdToLoadId, component) => {
  const componentId = component.get("id");
  const loadId = componentIdToLoadId.get(componentId, 0);
  return "component" + componentId + "_load" + loadId;
};

export const getDefaultParentConfig = () => {
  const currentUser = Users.getCurrentUser();
  let qualifier;
  if (currentUser.get("dataVisibility") === "SELF") {
    qualifier = {
      type: "USER",
      id: currentUser.get("id")
    };
  } else {
    qualifier = {
      type: "GROUP",
      id: Groups.getRootGroup().get("id")
    };
  }
  return Immutable.fromJS({
    timeframe: TimeframeRepo.getDefaultForClient().getRawJson(),
    tagFilter: {
      matchAnyTagIds: Immutable.Set(),
      matchAllTagIds: Immutable.Set(),
      excludedTagIds: Immutable.Set()
    },
    clientFilter: {
      allClientIds: Immutable.Set(),
      clientIds: Immutable.Set(),
      clientSetIds: Immutable.Set()
    },
    qualifier
  });
};

export const defaultInheritKeys = Immutable.Set(["timeframe", "qualifier", "tagFilter", "clientFilter"]);

const alwaysInheritKeys = Immutable.Set(["entityToIds"]);

export const applyInheritanceForKeys = (parentConfig, componentDataConfig) => componentDataConfig
    .get("inheritKeys", Immutable.Set())
    .toSet()
    .union(alwaysInheritKeys)
    .reduce(
        (c, key) => {
          if (parentConfig.has(key)) {
            return c.set(key, parentConfig.get(key));
          } else {
            return c;
          }
        },
        componentDataConfig);

export const lockPageScroll = () => {
  window.scrollTo(0, 0);
  document.querySelector("body").style.overflowY = "hidden";
  document.querySelector("body").classList.add("forceOverflowHidden");
};

export const enablePageScroll = () => {
  document.querySelector("body").style.overflowY = "auto";
  document.querySelector("body").classList.remove("forceOverflowHidden");
};
