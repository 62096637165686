import React, {useContext, useMemo} from "react";
import Immutable from "immutable";
import moment from "moment";
import {FormControlLabel, MenuItem, Radio, RadioGroup, Select as Dropdown} from "@mui/material";
import ReactTooltip from "react-tooltip";


import {indexBy} from "js/common/utils/collections";
import {clamp} from "js/common/utils/numbers";
import * as Groups from "js/common/groups";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import {betterMemo} from "js/common/utils/more-memo";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import EntityAndServerColumnPicker from "js/admin/kpis/edit-kpis/tabs/common/entity-and-server-column-picker";
import Select from "js/common/views/inputs/immutable-react-select";
import DelayedTextField from "js/common/views/inputs/simple-text-input";
import MultiSelectWithCustomOption from "js/admin/kpis/edit-kpis/tabs/common/multi-select-with-custom-option";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const minPercent = -999999999;
const maxPercent = 999999999;

const datePickerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: 6,
  position: "relative"
};
export const blankCondition = Immutable.Map({
  type: "CONDITION",
  value: "",
  joinPath: undefined,
  operator: "IN",
  entityColumnId: undefined
});

export const blankGroup = Immutable.Map({
  type: "GROUP",
  combination: "AND",
  children: Immutable.List([blankCondition])
});

export const blankParent = Immutable.Map({
  type: "GROUP",
  combination: "AND",
  children: Immutable.List()
});

const columnTypeDefaults = {
  "STRING": {operator: "IN", value: Immutable.List()},
  "DATE": {operator: "IN", value: Immutable.List([moment().format("YYYY-MM-DD")])},
  "INTEGER": {operator: "IN", value: Immutable.List()},
  "CURRENCY": {operator: "IN", value: Immutable.List()},
  "PERCENTAGE": {operator: "IN", value: Immutable.List()},
  "BOOLEAN": {operator: "IN", value: Immutable.List([true])}
};

const percentToFloat = (percentStr, min, max) => {
  return clamp(parseFloat(percentStr).toFixed(2), min, max) / 100;
};

const floatToPercent = (num) => {
  if (num || num === 0) {
    // We clamp here to avoid JavaScript's precision handling leading to huge precision after multiplication
    const clampFractional = (num * 100).toFixed(2);
    return +clampFractional;
  }
};

const populateOperatorOptions = (typeToOperatorToComponentWrapper) => {
  const options = [];
  Object.keys(typeToOperatorToComponentWrapper).forEach(value => {
    const {label} = typeToOperatorToComponentWrapper[value];
    options.push({
      label,
      value
    });
  });

  return Immutable.fromJS(options);
};
const handleAddCondition = (path, filters, onChange) =>
    onChange([...path, "children"], filters.push(blankCondition));

const handleAddGroup = (path, filters, onChange) =>
    onChange([...path, "children"], filters.push(blankGroup));

const handleColumnChange = (colId, joinPath, index, path, filter, onChange, idToColumn) => {
  const columnType = idToColumn.get(colId)?.get("dataType");
  const updatedFilter = filter
      .set("entityColumnId", colId)
      .set("joinPath", joinPath)
      .set("operator", columnTypeDefaults[columnType]["operator"])
      .set("value", columnTypeDefaults[columnType]["value"]);
  onChange([...path, "children", index], updatedFilter);
};

const handleOperatorChange = (index, newOperatorValue, path, onChange, filters, columnType) => {
  const existingOperatorValue = filters.getIn([index, "operator"]);
  let value = filters.getIn([index, "value"]);

  if (existingOperatorValue === "IN" || existingOperatorValue === "NOT_IN") {
    if (newOperatorValue !== "IN" && newOperatorValue !== "NOT_IN") {
      value = undefined;
    } else {
      value = value || columnTypeDefaults[columnType].value;
    }
  } else {
    if (newOperatorValue === "IN" || newOperatorValue === "NOT_IN") {
      value = Immutable.List();
    } else {
      value = undefined;
    }
  }

  if (newOperatorValue === "IS_BLANK" || newOperatorValue === "NOT_BLANK") {
    value = null;
  }

  const updatedFilters = filters.setIn([index, "operator"], newOperatorValue).setIn([index, "value"], value);
  onChange([...path, "children"], updatedFilters);
};

const handleValueChange = (index, value, path, onChange) =>
    onChange([...path, "children", index, "value"], value);

const handleCombinationChange = (index, value, path, onChange) =>
    onChange([...path, "combination"], value);

const handleDelete = (index, path, filters, onChange) => onChange([...path, "children"], filters.delete(index));

const unwrapSingleNestedValue = value => {
  return Immutable.isIndexed(value) && value.size === 1
      ? value.first()
      : value;
};

const intToString = int => typeof int === 'number' && !isNaN(int) ? int.toString() : ""

const renderCombinationToggle = (currentCombinationType, index, theme, path, filters, onChange, readOnly) => {
  return (
      <div
          style={{
            color: theme.palette.primary.main,
            cursor: "pointer",
            fontSize: 13,
            fontFamily: theme.typography.fontFamilyBold,
            fontWeight: 600,
            padding: "10px 0"
          }}
      >
        <Dropdown
            variant="standard"
            disableUnderline
            MenuProps={{style: {zIndex: 9999999}}}
            value={currentCombinationType}
            disabled={readOnly}
            sx={{
              display: "flex",
              justifySelf: "flex-end",
              width: 56,
              fontSize: 13,
              fontWeight: 600,
              color: theme.palette.primary.main,
              borderBottom: "none !important",
              textTransform: "uppercase",
              "& .MuiSvgIcon-root": {
                color: theme.palette.primary.main
              }
            }}
            inputProps={{"aria-label": "Without label"}}
        >
          <MenuItem
              value="AND"
              onClick={() => handleCombinationChange(index, "AND", path, onChange)}
              disabled={readOnly}>And</MenuItem>
          <MenuItem
              value="OR"
              onClick={() => handleCombinationChange(index, "OR", path, onChange)}
              disabled={readOnly}>Or</MenuItem>
        </Dropdown>
      </div>
  );
};

const typeToOperatorToComponentWrapper = {
  STRING: {
    STARTS_WITH: {
      label: "Starts With",
      component: ({theme, disabled, allowAutofocus, shouldAutoFocus, index, path, onChange, stringValue}) => (
          <DelayedTextField
              label="Value"
              type="text"
              placeholder="Enter a value..."
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value, path, onChange)}
              value={stringValue}
          />
      )
    },
    CONTAINS: {
      label: "Contains",
      component: ({theme, disabled, allowAutofocus, shouldAutoFocus, index, path, onChange, stringValue}) => (
          <DelayedTextField
              label="Value"
              type="text"
              placeholder="Enter a value..."
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value, path, onChange)}
              value={stringValue}
          />
      )
    },
    DOES_NOT_CONTAIN: {
      label: "Does Not Contain",
      component: ({theme, disabled, allowAutofocus, shouldAutoFocus, index, path, onChange, stringValue}) => (
          <DelayedTextField
              label="Value"
              type="text"
              placeholder="Enter a value..."
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value, path, onChange)}
              value={stringValue}
          />
      )
    },
    IN: {
      label: "Is",
      component: ({filter, disabled, allowAutofocus, shouldAutoFocus, index, path, onChange}) => (
          <MultiSelectWithCustomOption
              autoFocus={allowAutofocus && shouldAutoFocus}
              label="Value"
              joinPath={filter.get("joinPath")}
              selectedValues={filter.get("value") || Immutable.List()}
              disabled={disabled}
              onChange={(value) => handleValueChange(index, value, path, onChange)}
          />
      )
    },
    NOT_IN: {
      label: "Is Not",
      component: ({filter, disabled, allowAutofocus, shouldAutoFocus, index, path, onChange}) => (
          <MultiSelectWithCustomOption
              autoFocus={allowAutofocus && shouldAutoFocus}
              label="Value"
              joinPath={filter.get("joinPath")}
              selectedValues={filter.get("value") || Immutable.List()}
              disabled={disabled}
              onChange={(value) => handleValueChange(index, value, path, onChange)}
          />
      )
    },
    IS_BLANK: {
      label: "Is Blank",
      component: () => <div />
    },
    NOT_BLANK: {
      label: "Is Not Blank",
      component: () => <div />
    },
  },
  DATE: {
    IN: {
      label: "On",
      component: ({filter, index, path, onChange, disabled}) => (
          <DatePicker
              isDisabled={disabled}
              className="filters-date-picker"
              label="Date"
              inlineErrors={true}
              style={datePickerStyle}
              value={filter.get("value")?.first() && moment(filter.get("value").first())}
              onDateChange={(value) => handleValueChange(
                  index,
                  Immutable.List([value.format("YYYY-MM-DD")]),
                  path,
                  onChange)}
          />
      )
    },
    NOT_IN: {
      label: "Not On",
      component: ({filter, index, path, onChange, disabled}) => (
          <DatePicker
              isDisabled={disabled}
              className="filters-date-picker"
              label="Date"
              inlineErrors={true}
              style={datePickerStyle}
              value={filter.get("value")?.first() && moment(filter.get("value").first())}
              onDateChange={(value) => handleValueChange(
                  index,
                  Immutable.List([value.format("YYYY-MM-DD")]),
                  path,
                  onChange)}
          />
      )
    },
    GREATER_THAN_OR_EQUAL: {
      label: "On Or After",
      component: ({filter, index, path, onChange, disabled}) => (
          <DatePicker
              isDisabled={disabled}
              className="filters-date-picker"
              label="Date"
              inlineErrors={true}
              style={datePickerStyle}
              value={filter.get("value") && moment(filter.get("value"))}
              onDateChange={(value) => handleValueChange(index, value.format("YYYY-MM-DD"), path, onChange)}
          />
      )
    },
    LESS_THAN_OR_EQUAL: {
      label: "On Or Before",
      component: ({filter, index, path, onChange, disabled}) => (
          <DatePicker
              isDisabled={disabled}
              className="filters-date-picker"
              label="Date"
              inlineErrors={true}
              style={datePickerStyle}
              value={filter.get("value") && moment(filter.get("value"))}
              onDateChange={(value) => handleValueChange(index, value.format("YYYY-MM-DD"), path, onChange)}
          />
      )
    },
    IS_BLANK: {
      label: "Is Blank",
      component: () => <div />
    },
    NOT_BLANK: {
      label: "Is Not Blank",
      component: () => <div />
    },
  },
  INTEGER: {
    IN: {
      label: "Is",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([Number(value)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(filter.get("value").first())}
          />
      )
    },
    NOT_IN: {
      label: "Is Not",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([Number(value)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(filter.get("value").first())}
          />
      )
    },
    GREATER_THAN: {
      label: "Greater Than",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus, uniqueInputId}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    GREATER_THAN_OR_EQUAL: {
      label: "Greater Than Or Equal To",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    LESS_THAN: {
      label: "Less Than",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    LESS_THAN_OR_EQUAL: {
      label: "Less Than Or Equal To",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    IS_BLANK: {
      label: "Is Blank",
      component: () => <div />
    },
    NOT_BLANK: {
      label: "Is Not Blank",
      component: () => <div />
    },
  },
  PERCENTAGE: {
    IN: {
      label: "Is",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([percentToFloat(value, minPercent, maxPercent)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))} />
      )
    },
    NOT_IN: {
      label: "Is Not",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([percentToFloat(value, minPercent, maxPercent)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))}
          />
      )
    },
    GREATER_THAN: {
      label: "Greater Than",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value && percentToFloat(value, minPercent, maxPercent),
                  path,
                  onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))}
          />
      )
    },
    GREATER_THAN_OR_EQUAL: {
      label: "Greater Than Or equal to",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value && percentToFloat(value, minPercent, maxPercent),
                  path,
                  onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))}
          />
      )
    },
    LESS_THAN: {
      label: "Less Than",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && percentToFloat(value, minPercent, maxPercent), path, onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))}
          />
      )
    },
    LESS_THAN_OR_EQUAL: {
      label: "Less Than Or Equal To",
      component: ({filter, index, path, onChange, disabled, theme, allowAutofocus, shouldAutoFocus}) => (
          <DelayedTextField
              label="Amount"
              type="number"
              min={minPercent}
              max={maxPercent}
              step={"any"}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix="%"
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value && percentToFloat(value, minPercent, maxPercent),
                  path,
                  onChange)}
              value={intToString(floatToPercent(unwrapSingleNestedValue(filter.get("value"))))}
          />
      )
    },
    IS_BLANK: {
      label: "Is Blank",
      component: () => <div />
    },
    NOT_BLANK: {
      label: "Is Not Blank",
      component: () => <div />
    },
  },
  CURRENCY: {
    IN: {
      label: "Is",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([Number(value)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(filter.get("value").first())}
          />
      )
    },
    NOT_IN: {
      label: "Is Not",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(
                  index,
                  value ? Immutable.List([Number(value)]) : Immutable.List(),
                  path,
                  onChange)}
              value={intToString(filter.get("value").first())}
          />
      )
    },
    GREATER_THAN: {
      label: "Greater Than",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    GREATER_THAN_OR_EQUAL: {
      label: "Greater Than Or Equal To",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    LESS_THAN: {
      label: "Less Than",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    LESS_THAN_OR_EQUAL: {
      label: "Less Than Or Equal To",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        theme,
        allowAutofocus,
        shouldAutoFocus,
        rootCurrencySymbol
      }) => (
          <DelayedTextField
              label="Amount"
              type="number"
              maxLength={128}
              customStyle={{border: `2px solid ${theme.palette.border.main}`, height: 42}}
              blurErrorMessage="Required field"
              disabled={disabled}
              prefix={rootCurrencySymbol}
              autoFocus={allowAutofocus && shouldAutoFocus}
              onChange={(value) => handleValueChange(index, value && Number(value), path, onChange)}
              value={intToString(filter.get("value"))}
          />
      )
    },
    IS_BLANK: {
      label: "Is Blank",
      component: () => <div />
    },
    NOT_BLANK: {
      label: "Is Not Blank",
      component: () => <div />
    },
  },
  BOOLEAN: {
    IN: {
      label: "Is",
      component: ({
        filter,
        index,
        path,
        onChange,
        disabled,
        booleanOptions
      }) => {
        const defaultValue = true;
        const value = filter.get("value");
        let unwrappedValue;
        if (Immutable.isCollection(value) && typeof value.first() === "boolean") {
          unwrappedValue = value.first()
        } else if (typeof value === "boolean") {
          unwrappedValue = value;
        } else {
          unwrappedValue = defaultValue;
        }
        return (
            <RadioGroup
                row
                value={unwrappedValue}
                onChange={(event) => {
                  const value = event.target.value;
                  // onChange value is a string, so we convert back to a boolean
                  const booleanValue = (value.toLowerCase() === "true");
                  return handleValueChange(index, Immutable.List([booleanValue]), path, onChange);
                }}>
              {booleanOptions.map(option => <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio size="small" disabled={disabled} />}
                  label={option.label} />)}
            </RadioGroup>
        )
      }
    }
  }
};

const filterCondition = (filter, index, theme, columns, path, groupFilters, onChange, allowAutofocus, disabled, rootCurrencySymbol, idToColumn) => {
  const operator = filter.get("operator");
  const entityColumnId = filter.get("entityColumnId");
  const column = idToColumn.get(entityColumnId);
  const columnType = column?.get("dataType");
  const operatorToComponentWrapper = columnType && typeToOperatorToComponentWrapper[columnType];
  const operatorOptions = columnType && populateOperatorOptions(operatorToComponentWrapper);
  const booleanOptions = [
    {value: true, label: column?.get("trueValueLabel")},
    {value: false, label: column?.get("falseValueLabel")}];
  const valueSectionWidth = columnType !== "BOOLEAN" ? "49%" : "66%";

  const commonProps = {
    filter,
    index,
    path,
    onChange,
    theme,
    allowAutofocus,
    disabled,
    stringValue: Immutable.isList(filter.get("value")) ? filter.get("value").first() : filter.get("value"),
    shouldAutoFocus: !filter.get("value") || filter.get("value") === "" || (Immutable.isList(filter.get("value"))
        && filter.get("value").isEmpty()),
    handleValueChange: handleValueChange,
    rootCurrencySymbol,
    booleanOptions
  };

  return (
      <div>
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <div style={{flexGrow: 1, width: "31%"}}>
            <EntityAndServerColumnPicker
                entityColumnId={filter.get("entityColumnId")}
                joinPath={filter.get("joinPath")}
                columns={columns}
                label="Field"
                disabled={disabled}
                onColumnSelect={(colId, joinPath) => handleColumnChange(
                    colId,
                    joinPath,
                    index,
                    path,
                    filter,
                    onChange,
                    idToColumn)}
            />
          </div>
          {columnType ? (
              <>
                {columnType !== "BOOLEAN" &&
                    <div style={{width: "16%", marginLeft: "1%"}}>
                      <Select
                          label="Operator"
                          selectedValue={filter.get("operator")}
                          isMulti={false}
                          isClearable={false}
                          searchable={false}
                          placeholder="Select..."
                          isDisabled={disabled}
                          style={{fontSize: 13}}
                          onChange={value => handleOperatorChange(
                              index,
                              value,
                              path,
                              onChange,
                              groupFilters,
                              columnType)}
                          options={operatorOptions}
                      ></Select>
                    </div>}
                <div
                    style={{
                      width: disabled ? "50%" : `calc(${valueSectionWidth} - 30px)`,
                      marginLeft: "1%"
                    }}>{operatorToComponentWrapper[operator]?.component(commonProps)}</div>
              </>
          ) : (
              <div style={{width: "64%"}} />
          )}
          {!disabled && <div style={{flexGrow: 1, textAlign: "right", paddingLeft: 8, width: 30}}>
            <i
                onClick={() => handleDelete(index, path, groupFilters, onChange)}
                className="bhi-delete-o"
                style={{position: "relative", top: -6, color: theme.palette.button.alert, cursor: "pointer"}} />
          </div>}
        </div>
      </div>
  );
};

const filterGroup = (filter, index, theme, columns, path, filters, onChange, groupLimit, totalChildren, allowAutofocus, readOnly, rootCurrencySymbol, idToColumn) => {
  const newPath = [...path, "children", index];
  if (filter.get("children").isEmpty()) {
    return <div />;
  } else {
    return (
        <div
            key={`group-${index}`}
            style={{
              borderLeft: `5px solid ${theme.themeId === "light"
                  ? theme.palette.background.paper
                  : theme.palette.background.card}`, padding: "5px 0 5px 10px"
            }}>
          {filtersMapper(
              newPath,
              onChange,
              columns,
              filter.get("combination"),
              filter.get("type"),
              filter.get("children"),
              theme,
              groupLimit,
              totalChildren,
              allowAutofocus,
              readOnly,
              rootCurrencySymbol,
              idToColumn)}
        </div>
    );
  }
};

const filtersMapper = (path, onChange, columns, combinationType, type, filters, theme, groupLimit, totalChildren, allowAutofocus, readOnly, rootCurrencySymbol, idToColumn) => {
  return (
      <div>
        {filters.map((filter, i) => (
            <div key={`${filter.get("type")}-filter-${i}`}>
              {filter.get("type") === "CONDITION" ? (
                  filterCondition(
                      filter,
                      i,
                      theme,
                      columns,
                      path,
                      filters,
                      onChange,
                      allowAutofocus,
                      readOnly,
                      rootCurrencySymbol,
                      idToColumn)
              ) : (
                  filterGroup(
                      filter,
                      i,
                      theme,
                      columns,
                      path,
                      filters,
                      onChange,
                      groupLimit,
                      totalChildren,
                      allowAutofocus,
                      readOnly,
                      rootCurrencySymbol,
                      idToColumn
                  )
              )}
              {i + 1 < filters.count() && renderCombinationToggle(
                  combinationType,
                  i,
                  theme,
                  path,
                  filters,
                  onChange,
                  readOnly)}
            </div>
        ))}
        {!readOnly && renderNewActions(theme, path, filters, onChange, groupLimit, totalChildren)}
      </div>
  );
};

const renderNewActions = (theme, path, filters, onChange, groupLimit, totalChildren) => {
  const filterLimit = 100;
  let addGroupEnabled = path.length / 2 + 1 < groupLimit && totalChildren < filterLimit;
  let addConditionEnabled = totalChildren < filterLimit;
  const groupLimitExceededTooltipId = "tooltip-group-limit-exceeded";
  const filterLimitExceededTooltipId = "tooltip-filter-limit-exceeded";

  return (
      <>
        <div
            data-for={addConditionEnabled ? undefined : filterLimitExceededTooltipId}
            data-tip=""
            style={{
              color: theme.palette.primary.main,
              textTransform: "uppercase",
              fontSize: 11,
              paddingTop: 12,
              display: "inline-block"
            }}
        >
        <span
            onClick={() => addConditionEnabled && handleAddCondition(path, filters, onChange)}
            style={{
              opacity: addConditionEnabled ? "1" : 0.5,
              cursor: addConditionEnabled ? "pointer" : "not-allowed",
              marginRight: 20
            }}
        >
          Add Condition
          <i className="bhi-filter" style={{marginLeft: 5}} />
        </span>
          <span
              data-for={addGroupEnabled ? undefined : groupLimitExceededTooltipId}
              data-tip=""
              style={{opacity: addGroupEnabled ? "1" : 0.5, cursor: addGroupEnabled ? "pointer" : "not-allowed"}}
              onClick={() => addGroupEnabled && handleAddGroup(path, filters, onChange)}
          >
          Add Group
          <i className="bhi-list" style={{marginLeft: 5}} />
        </span>
          {!addGroupEnabled && addConditionEnabled && (
              <ReactTooltip
                  textColor={theme.palette.text.inverted}
                  backgroundColor={theme.palette.background.inverted}
                  place="top"
                  effect="solid"
                  id={groupLimitExceededTooltipId}>
                <p style={{textTransform: "none", width: 300, fontSize: 11, margin: 0, padding: 0}}>Limit Reached:
                  You've reached the maximum number of nested groups allowed for this filter.</p>
              </ReactTooltip>
          )}
        </div>
        {!addConditionEnabled && (
            <ReactTooltip
                textColor={theme.palette.text.inverted}
                backgroundColor={theme.palette.background.inverted}
                place="top"
                effect="solid"
                id={filterLimitExceededTooltipId}>
              <p style={{width: 300, fontSize: 11, margin: 0, padding: 0}}>Limit Reached: You've reached the maximum
                number of conditions/groups allowed for this filter. To add more conditions, please remove existing
                ones
                or adjust your criteria.</p>
            </ReactTooltip>
        )}
      </>
  );
};

// Function to get total children count and depth
// Return both at once so that we only need to do the recursion once
const getTotalChildrenCountAndDepth = (filter) => {
  const children = filter.get("children");
  if (children) {
    const childrenResults = children.map(child => getTotalChildrenCountAndDepth(child));
    const totalDepth = childrenResults.reduce((maxDepth, result) => {
      return Math.max(maxDepth, result.get("totalDepth"));
    }, 0) + 1;
    const totalChildren = childrenResults.reduce((totalCount, result) => {
      return totalCount + 1 + result.get("totalChildren");
    }, 0);
    return Immutable.Map({totalDepth, totalChildren});
  } else {
    return Immutable.Map({totalDepth: 0, totalChildren: 0});
  }
};

const Filters = betterMemo(
    {displayName: "Filters"},
    ({
      onChange,
      filter = blankParent,
      columns,
      allowAutofocus,
      readOnly
    }) => {
      const {theme} = useContext(CustomThemeContext);
      // Recursive function to remove empty groups
      const handleChange = (path, value) => {
        if (path[path.length - 1] === "children" && path.length > 1 && value.isEmpty()) {
          const newPath = [...path.slice(0, -2)];
          handleChange(newPath, filter.getIn(newPath).remove(path[path.length - 2]));
        } else {
          onChange(filter.setIn(path, value));
        }
      };

      const totalChildrenCountAndDepth = useMemo(() => getTotalChildrenCountAndDepth(filter), [filter]);
      const rootGroup = Groups.getRootGroup();
      const rootCurrencySymbol = currencyRepo.getSymbol(rootGroup.get("currencyCode"));
      const totalChildren = totalChildrenCountAndDepth.get("totalChildren");
      const totalDepth = totalChildrenCountAndDepth.get("totalDepth") - 1;
      const groupLimit = totalDepth < 3 ? 3 : totalDepth;

      if (!columns || columns.isEmpty()) {
        return <div />;
      }

      const idToColumn = indexBy(c => c.get("entityColumnId"), columns);

      return filtersMapper(
          [],
          handleChange,
          columns,
          filter.get("combination"),
          filter.get("type"),
          filter.get("children"),
          theme,
          groupLimit,
          totalChildren,
          allowAutofocus,
          readOnly,
          rootCurrencySymbol,
          idToColumn);
    }
);

export default Filters;
