/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";
import Immutable from "immutable";

import * as DashboardUtils from "js/dashboards/utils";
import * as Overlays from "js/dashboards/components/overlays";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Divider = React.memo(({
  dimensions,
  config,
  isEditing,
}) => {
  const divider = config.get("divider");

  if (!divider) {
    return <Overlays.Message coverOtherContent={false}>
      Configure your divider
    </Overlays.Message>;
  } else {
    return (
        <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}>
            <span style={{
              position: "relative",
              background: "rgba(0,0,0,0.1)",
              height: divider === "VERTICAL" ? `calc(100% - ${isEditing ? 22 : 0}px)` : "2px",
              width: divider === "VERTICAL" ? "2px" : `calc(100% - ${isEditing ? 22 : 0}px)`,
              left: isEditing && divider === "HORIZONTAL" ? 22 : 0,
              top: isEditing && divider === "VERTICAL" ? 22 : 0
            }}/>
        </span>
    );
  }
});

const DividerEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const config = component.get("dataConfig");

  const { theme } = React.useContext(CustomThemeContext);

  const onConfigChange = React.useCallback(newDataConfig => {
    onComponentChange(component.set("dataConfig", newDataConfig));
  }, [onComponentChange, component]);

  const onDividerChange = (orientation) => {
    onConfigChange(config.set("divider", orientation));
  };

  const labelStyle = {color: theme.palette.text.main};

  const switcherStyle = css`
        border-radius: 8px;
        background: ${theme.palette.background.card};
        border: 1px solid ${theme.palette.background.paper};
        box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        z-index: 1;
  `;

  const panelStyle = (attribute, value) => css`
        background: ${config.get(attribute) === value ? theme.themeId === "light" ? "#e4ecf7" : "#000" : "transparent"};
        display: flex;
        justify-content: center;
        flex-grow: 1;
        font-size: 16px;
        text-align: center;
        padding: 10px 10px;
        cursor: pointer;
        border-left: 1px solid ${theme.palette.background.paper};
        &:first-of-type {
          border-left: none;
        }
        &:hover {
          background: ${theme.palette.background.paper};
        }
  `;

  return <div style={{padding: "0.5rem 1rem"}}>
    <div style={{width: 300, marginBottom: "1rem"}}>
      <label style={labelStyle}>Orientation</label>
      <div css={switcherStyle}>
        <span css={panelStyle("divider","VERTICAL")} onClick={() => onDividerChange("VERTICAL")}>
          <i className="fa fa-minus fa-rotate-90"/>
        </span>
        <span css={panelStyle("divider","HORIZONTAL")} onClick={() => onDividerChange("HORIZONTAL")}>
          <i className="fa fa-minus"/>
        </span>
      </div>
    </div>
  </div>

});

export default Immutable.fromJS({
  type: "Divider",
  label: "Divider",
  titleIsEditable: false,
  variant: "transparent",
  getDefaultData: () => Immutable.Map(),
  getReactComponent: () => Divider,
  getEditorReactComponent: () => DividerEditor,
  getTitle: () => null,
  canFullScreen: false,
  layout: {
    min: {width: 1, height: 1},
    max: {width: 48, height: 100},
    init: {width: 48, height: 1}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
});
