import React from "react";
import * as Immutable from "immutable";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Drawer from "js/common/views/drawer";
import Dialog from "js/common/views/tabs-dialog";
import SavedConfigsMenu from "js/common/views/sharing/saved-configs-menu";
import ConfirmRemoveDialog from "js/common/views/sharing/confirm-remove-dialog";
import ShareReport from "js/common/views/sharing/share-config";

import * as Rata from "js/common/utils/remote-data";
import * as SavedConfigs from "js/common/saved-configs";
import * as Popups from "js/common/popups";
import {useForceRender} from "js/common/hooks/use-force-render";

const EasyShareDrawer = React.memo(({
  open,
  onRequestClose,
  headerLabel,
  ownershipTypeToConfigsWrapper,
  setOwnershipTypeToConfigsWrapper,
  ownershipTypeToCustomDisplayName,
  onRequestReload,
  onRemoved,
  onSelect,
  isSharingEnabled,
  isSharingReadOnly = false,
  isEditSharingPermissionEnabled = false,
  configType
}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const handleSelectConfig = React.useCallback((config, ownershipType) => {
    onSelect(config, ownershipType);
    onRequestClose();
  }, [onSelect, onRequestClose]);

  const [typeAndConfigToRemove, setTypeAndConfigToRemove] = React.useState(null);
  const closeRemoveDialog = React.useCallback(() => setTypeAndConfigToRemove(null), []);
  const openRemoveDialog = React.useCallback(
      (config, type) => setTypeAndConfigToRemove({type, config}),
      []);

  const handleRemove = React.useCallback(() => {
    const {type, config} = typeAndConfigToRemove;
    let removePromise;
    if (type === "ownedConfigs") {
      removePromise = SavedConfigs.remove(config.get("id"));
    } else if (type === "sharedUserConfigs") {
      removePromise = SavedConfigs.unshareFromCurrentUser(config.get("id"));
    }

    removePromise.then(
        () => {
          setOwnershipTypeToConfigsWrapper(ownershipTypeToConfigsWrapper => {
            return Rata.updateValue(
                ownershipTypeToConfigsWrapper,
                x => x.update(type, configs => {
                  return configs.filter(c => c.get("id") !== config.get("id"));
                }));
          });
          Popups.success(`Removed '${config.get("name")}'`);
          closeRemoveDialog();
          onRemoved(config.get("id"));
        },
        reason => {
          let errorMsg;
          if (reason.responseJSON && reason.responseJSON.message) {
            errorMsg = reason.responseJSON.message;
          } else {
            errorMsg = `Unable to remove '${config.get("name")}'`;
          }
          Popups.error(errorMsg);
          closeRemoveDialog();
        });
  }, [typeAndConfigToRemove, closeRemoveDialog, setOwnershipTypeToConfigsWrapper, onRemoved]);

  const [originalConfigShareData, setOriginalConfigShareData] = React.useState(Immutable.Map());
  const [configShareDataWrapper, setConfigShareDataWrapper] = React.useState(Rata.wrapInitialValue(Immutable.Map()));
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [configToShare, setConfigToShare] = React.useState(null);
  const closeShareDialog = React.useCallback(() => {
    setConfigToShare(null);
    setErrorMessage(null);
    setOriginalConfigShareData(Immutable.Map());
    setConfigShareDataWrapper(Rata.wrapInitialValue(Immutable.Map()));
  }, []);
  const openShareDialog = React.useCallback(config => {
    setConfigToShare(config);
    setOriginalConfigShareData(Immutable.Map());
    setConfigShareDataWrapper(x => Rata.toLoading(x));
    SavedConfigs
        .getShareList(config.get("id"))
        .then(
            configShareData => {
              setOriginalConfigShareData(configShareData);
              setConfigShareDataWrapper(x => Rata.toLoaded(x, configShareData));
            },
            () => setConfigShareDataWrapper(x => Rata.toError(x, "Unable to load sharing data")));
  }, []);

  const handleShare = React.useCallback(() => {
    setConfigShareDataWrapper(x => Rata.toLoading(x));
    SavedConfigs
        .updateShareList(configToShare.get("id"), Rata.getValue(configShareDataWrapper))
        .then(
            () => {
              Popups.success("Your sharing changes have been saved");
              onRequestReload();
              closeShareDialog();
              setErrorMessage(null);
            },
            res => {
              let errorMsg;
              if (res.responseJSON && res.responseJSON.message) {
                errorMsg = res.responseJSON.message;
              } else {
                errorMsg = "Unable to save your sharing changes";
              }
              setErrorMessage(errorMsg);
              setConfigShareDataWrapper(x => Rata.toLoaded(x, Rata.getValue(configShareDataWrapper)));
            });
  }, [configToShare, configShareDataWrapper, closeShareDialog, onRequestReload]);

  const forceRender = useForceRender();
  const currentHomeId = SavedConfigs.getHome(configType);
  const extraButtons = React.useMemo(() => Immutable.fromJS([
    {
      disabled: false,
      onClick: configId => SavedConfigs
          .toggleHome(configType, configId)
          .then(forceRender),
      faClass: "fa fa-home",
      tooltipText: "Set as Home Page",
      activeTooltipText: "Unset as Home Page",
      isActive: configId => currentHomeId === configId
    }]), [forceRender, currentHomeId, configType]);

  // NOTE We must close the drawer when showing the share dialog is open otherwise the search boxes cannot be typed in
  const isDrawerOpen = open && !configToShare;
  const isSystemDefaultConfig = configToShare?.get("systemDefault");
  return <>
    <Drawer
        open={isDrawerOpen}
        width={500}
        onRequestClose={onRequestClose}>
      <SavedConfigsMenu
          headerLabel={headerLabel}
          ownershipTypeToSavedConfigs={Rata.getValue(ownershipTypeToConfigsWrapper)}
          ownershipTypeToCustomDisplayName={ownershipTypeToCustomDisplayName}
          onRefreshConfigsRequest={onRequestReload}
          isLoading={Rata.isLoading(ownershipTypeToConfigsWrapper)}
          onSavedConfigSelect={handleSelectConfig}
          isSavedConfigSharingEnabled={isSharingEnabled}
          onShareSavedConfigRequest={openShareDialog}
          onRemoveSavedConfigRequest={openRemoveDialog}
          extraButtons={extraButtons}
      />
    </Drawer>
    {typeAndConfigToRemove && <ConfirmRemoveDialog
        reportName={typeAndConfigToRemove.config.get("name")}
        onRemoveRequest={handleRemove}
        onCancelRequest={closeRemoveDialog} />}
    {configToShare && <Dialog
        height="85%"
        width="85%"
        label={
          <span
              style={{
                color: theme.themeId === "light"
                    ? theme.palette.text.main
                    : theme.palette.primary.main
              }}>{`Share ${configToShare.get("name")}`}</span>}
        onRequestClose={closeShareDialog}
        content={
          <ShareReport
              config={configToShare}
              isLoading={Rata.isLoading(configShareDataWrapper)}
              reportSharingSettings={Rata.getValue(configShareDataWrapper)}
              originalConfigShareData={originalConfigShareData}
              onReportSharingSettingsChange={configShareData => setConfigShareDataWrapper(x => Rata.toLoaded(
                  x,
                  configShareData))}
              hasReportSharingSettingsChanged={!Immutable.is(
                  originalConfigShareData,
                  Rata.getValue(configShareDataWrapper))}
              onCancelChangesRequest={() => setConfigShareDataWrapper(x => Rata.toLoaded(x, originalConfigShareData))}
              onShareReportRequest={handleShare}
              isSharingReadOnly={isSharingReadOnly}
              isEditSharingPermissionEnabled={!isSystemDefaultConfig && isEditSharingPermissionEnabled}
              shareReportError={Rata.getError(configShareDataWrapper) || errorMessage} />}
        flex={1}
        contentOverflowAutoScroll={false} />}
  </>;
});

export default EasyShareDrawer;
