import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";

import Tooltip from "js/common/views/tooltips";
import ErrorMsg from "js/common/views/error";
import pure from "js/common/views/pure";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import {Button as FlatButton, IconButton} from "@mui/material";

const SavedConfigsMenu = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    headerLabel: ReactPropTypes.string.isRequired,
    ownershipTypeToSavedConfigs: ImmutablePropTypes.map.isRequired,
    onSavedConfigSelect: ReactPropTypes.func.isRequired,
    onRefreshConfigsRequest: ReactPropTypes.func,
    onAddConfigRequest: ReactPropTypes.func,
    onRemoveSavedConfigRequest: ReactPropTypes.func,
    onGenerateSavedConfigAsCsvRequest: ReactPropTypes.func,
    onShareSavedConfigRequest: ReactPropTypes.func,
    mainContent: ReactPropTypes.element,
    ownershipTypeToCustomDisplayName: ImmutablePropTypes.map,
    error: ReactPropTypes.string,
    isLoading: ReactPropTypes.bool,
    isGenerateSavedConfigAsCsvEnabled: ReactPropTypes.bool,
    extraButtons: ImmutablePropTypes.list,
    isSavedConfigSharingEnabled: ReactPropTypes.bool,
    generatingAsCsvConfigId: ReactPropTypes.number
  },

  getDefaultProps() {
    return {
      ownershipTypeToCustomDisplayName: Immutable.Map()
    };
  },

  render() {
    const {
      headerLabel,
      ownershipTypeToSavedConfigs,
      ownershipTypeToCustomDisplayName,
      onSavedConfigSelect,
      isLoading,
      error,
      onRemoveSavedConfigRequest,
      extraButtons,
      isSavedConfigSharingEnabled,
      onShareSavedConfigRequest,
      onRefreshConfigsRequest,
      onAddConfigRequest,
      isGenerateSavedConfigAsCsvEnabled,
      onGenerateSavedConfigAsCsvRequest,
      generatingAsCsvConfigId,
      mainContent,
      theme
    } = this.props;
    const ownershipTypes = ["ownedConfigs", "sharedUserConfigs", "sharedGroupConfigs"];
    const deletableOwnershipTypes = ["ownedConfigs", "sharedUserConfigs"];
    const prefixWithNameOwnershipTypes = ["sharedUserConfigs", "sharedGroupConfigs"];
    const ownershipTypeToDisplayName = Immutable.fromJS({
      ownedConfigs: "Owned by me",
      sharedUserConfigs: "Shared with me",
      sharedGroupConfigs: "Shared with my group"
    });
    const isGeneratingCsv = !!generatingAsCsvConfigId;
    return (
        <div style={{display: "flex", overflowY: "auto", paddingBottom: 20}}>
          <div style={{width: "100%"}}>
            <Header
                theme={theme}
                label={headerLabel}
                onRefreshClick={onRefreshConfigsRequest}
                onAddClick={onAddConfigRequest} />

            {error && <ErrorMsg text={error} style={{margin: 0}} />}

            {isLoading ?
                <LoadingReports /> :
                ownershipTypes.map(ownershipType => <OwnershipTypeSection
                    theme={theme}
                    key={ownershipType}
                    ownershipTypeToCustomDisplayName={ownershipTypeToCustomDisplayName}
                    ownershipTypeToDisplayName={ownershipTypeToDisplayName}
                    ownershipTypeToSavedConfigs={ownershipTypeToSavedConfigs}
                    deletableOwnershipTypes={deletableOwnershipTypes}
                    prefixWithNameOwnershipTypes={prefixWithNameOwnershipTypes}
                    isGenerateSavedConfigAsCsvEnabled={isGenerateSavedConfigAsCsvEnabled}
                    extraButtons={extraButtons}
                    isSavedConfigSharingEnabled={isSavedConfigSharingEnabled}
                    ownershipType={ownershipType}
                    generatingAsCsvConfigId={generatingAsCsvConfigId}
                    onSavedConfigSelect={onSavedConfigSelect}
                    isGeneratingCsv={isGeneratingCsv}
                    onGenerateSavedConfigAsCsvRequest={onGenerateSavedConfigAsCsvRequest}
                    onShareSavedConfigRequest={onShareSavedConfigRequest}
                    onRemoveSavedConfigRequest={onRemoveSavedConfigRequest}
                />)
            }
          </div>

          {mainContent &&
              <div style={{width: "75%", height: "100vh", borderLeft: "solid 1px #1a1a1a"}}>
                {mainContent}
              </div>}
        </div>
    );
  }

});

const Header = pure(({label = "", onRefreshClick = () => {}, onAddClick, theme}) => {
  const headerContainerStyle = theme => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.card
  });
  return (
      <div
          style={{
            ...headerContainerStyle(theme),
            borderBottom: `1px solid ${theme.themeId === "light" ? theme.palette.border.main : "rgba(0,0,0,0.2)"}`
          }}>
        <h1 style={headerStyle(theme)}>{label}</h1>
        <div style={{display: "flex"}}>
          {onAddClick && <Tooltip text="Add new" position="left">
            <IconButton
                style={{...iconButtonStyle(theme), marginRight: 8}}
                onClick={onAddClick}
                size="large">
              <FontAwesomeIcon icon={faPlus} color={"white"} />
            </IconButton>
          </Tooltip>}
          <Tooltip text="Refresh" position="left">
            <IconButton
                style={{...iconButtonStyle(theme), marginRight: 8}}
                onClick={onRefreshClick}
                size="large">
              <i className="bhi-refresh" style={{color: theme.palette.text.main, fontSize: 18}} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
  );
});

const OwnershipTypeSection = pure(({
  ownershipTypeToCustomDisplayName,
  ownershipTypeToDisplayName,
  ownershipTypeToSavedConfigs,
  deletableOwnershipTypes,
  isGenerateSavedConfigAsCsvEnabled,
  extraButtons,
  isSavedConfigSharingEnabled,
  ownershipType,
  generatingAsCsvConfigId,
  onSavedConfigSelect,
  isGeneratingCsv,
  onGenerateSavedConfigAsCsvRequest,
  onShareSavedConfigRequest,
  onRemoveSavedConfigRequest,
  theme
}) => {
  const customReportTypeSectionName = ownershipTypeToCustomDisplayName.get(ownershipType);
  const sectionHeader = customReportTypeSectionName
      ? customReportTypeSectionName
      : ownershipTypeToDisplayName.get(ownershipType);
  const savedConfigs = ownershipTypeToSavedConfigs.get(ownershipType);
  const isDeletable = deletableOwnershipTypes.includes(ownershipType);
  const currentUserId = Users.getCurrentUser().get("id");
  const subheaderStyle = theme => ({
    ...headerStyle(theme),
    background: theme.themeId === "light" ? theme.palette.background.paper : "rgba(0,0,0,0.3)",
    fontWeight: 400,
    fontSize: "13px",
    color: theme.palette.text.main,
    textTransform: "uppercase"
  });

  return (
      <div>
        <h2 style={subheaderStyle(theme)}>{sectionHeader}</h2>
        {(!savedConfigs || savedConfigs.isEmpty()) && <NothingToView theme={theme} />}

        {savedConfigs && savedConfigs
            .sortBy(config => config.get("name").toLowerCase())
            .map(config => <MenuItemRow
                key={config.get("id")}
                config={config}
                isDeletable={isDeletable}
                isGenerateSavedConfigAsCsvEnabled={isGenerateSavedConfigAsCsvEnabled}
                extraButtons={extraButtons}
                isSavedConfigSharingEnabled={isSavedConfigSharingEnabled}
                ownershipType={ownershipType}
                generatingAsCsvConfigId={generatingAsCsvConfigId}
                onSavedConfigSelect={onSavedConfigSelect}
                isGeneratingCsv={isGeneratingCsv}
                onGenerateSavedConfigAsCsvRequest={onGenerateSavedConfigAsCsvRequest}
                onShareSavedConfigRequest={onShareSavedConfigRequest}
                onRemoveSavedConfigRequest={onRemoveSavedConfigRequest}
                theme={theme}
                removeEnabled={config.get("ownerId") === currentUserId}
            />)
        }
      </div>
  );
});

const MenuItemRow = React.memo(({
  config,
  generatingAsCsvConfigId,
  onSavedConfigSelect,
  isGenerateSavedConfigAsCsvEnabled,
  isGeneratingCsv,
  onGenerateSavedConfigAsCsvRequest,
  extraButtons,
  isSavedConfigSharingEnabled,
  onShareSavedConfigRequest,
  isDeletable,
  onRemoveSavedConfigRequest,
  ownershipType,
  theme,
  removeEnabled
}) => {
  const menuItemStyle = theme => ({
    backgroundColor: theme.palette.background.card,
    borderTop: `1px solid ${theme.themeId === "light" ? theme.palette.border.lightest : "rgba(0,0,0,0.2)"}`,
    borderBottom: `1px solid ${theme.themeId === "light" ? "#fff" : theme.palette.border.light}`,
    display: "flex",
    padding: "3px 0",
    justifyContent: "center",
    alignItems: "center"
  });

  const buttonsWidth = Immutable.List([isGenerateSavedConfigAsCsvEnabled, isSavedConfigSharingEnabled, isDeletable])
      .concat(extraButtons)
      .filter(b => b)
      .size * 33;

  const nameWidth = 500 - buttonsWidth;

  const editableBy = [];

  config.getIn(["sharedWith", "groups"])
      .filter(g => g.get("permission") === "EDIT")
      .forEach(g => editableBy.push(Groups.getGroup(g.get("id")).get("name")));
  config.getIn(["sharedWith", "users"])
      .filter(u => u.get("permission") === "EDIT")
      .forEach(u => editableBy.push(Users.getUser(u.get("id")).get("fullName")));

  return (
      <div data-test-id={"saved-config-" + config.get("id")} style={menuItemStyle(theme)}>
        {ownershipType === "sharedGroupConfigs" || ownershipType === "sharedUserConfigs" ? <Tooltip
                customStyle={{width: "100%"}}
                wrap={true}
                width={200}
                tooltipHtml={() => (
                    <div style={{textAlign: "left"}}>
                      <span style={{opacity: 0.6}}>{config.get("name")}</span><br />
                      Creator: {Users.getUser(config.get("ownerId"))?.get("fullName")}<br />
                      {editableBy.length > 0 &&
                          <span>
                            Editable by: {
                            editableBy
                                .sort()
                                .slice(0, 3)
                                .join(", ")}
                            {editableBy.length > 3 && `+${editableBy.length - 3} more`}
                          </span>
                      }
                    </div>
                )}
                styleType={theme.themeId === "light" ? "dark" : "light"}
                position="top">
              <ConfigItem
                  config={config}
                  width={nameWidth}
                  prefixWithName={false}
                  ownershipType={ownershipType}
                  onSavedConfigSelect={onSavedConfigSelect} />
            </Tooltip> :
            <ConfigItem
                config={config}
                width={nameWidth}
                prefixWithName={false}
                ownershipType={ownershipType}
                onSavedConfigSelect={onSavedConfigSelect} />
        }


        {isGenerateSavedConfigAsCsvEnabled &&
            <GenerateCsvButton
                config={config}
                generatingAsCsvConfigId={generatingAsCsvConfigId}
                isGeneratingCsv={isGeneratingCsv}
                onGenerateSavedConfigAsCsvRequest={onGenerateSavedConfigAsCsvRequest} />}

        {extraButtons &&
            extraButtons.map(extraButton => (
                <CustomButton
                    theme={theme}
                    key={config.get("id")}
                    config={config}
                    extraButton={extraButton} />))}

        {isSavedConfigSharingEnabled && Users.getCurrentUser().get("dataVisibility") !== "SELF" &&
            <ShareButton
                theme={theme}
                config={config}
                isGeneratingCsv={isGeneratingCsv}
                onShareSavedConfigRequest={onShareSavedConfigRequest} />}

        {isDeletable && removeEnabled &&
            <DeleteButton
                theme={theme}
                config={config}
                isGeneratingCsv={isGeneratingCsv}
                onRemoveSavedConfigRequest={onRemoveSavedConfigRequest}
                ownershipType={ownershipType} />}
      </div>
  );
});

const ConfigItem = React.memo(({
  config,
  width,
  prefixWithName,
  onSavedConfigSelect,
  ownershipType
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  let namePrefix = "";
  if (prefixWithName) {
    const owner = Users.getUser(config.get("ownerId"));
    if (owner) {
      namePrefix = owner.get("firstName") + "'s ";
    }
  }
  const reportNameLimit = 60;
  const isLongName = config.get("name").length + namePrefix.length >= reportNameLimit;
  const reportName = isLongName
      ? config.get("name").substring(0, reportNameLimit - namePrefix.length) + "..."
      : config.get("name");

  return (
      <div style={{width: "100%", height: "auto", lineHeight: 1}}>
        <Tooltip
            wrap={true}
            text={config.get("name")}
            disable={!isLongName}
            maxWidth={300}
            position={ownershipType === "ownedConfigs" ? "bottom" : "top"}>
          <FlatButton
              data-test-id={"load-saved-config"}
              style={{
                backgroundColor: theme.palette.background.card,
                justifyContent: "flex-start",
                textAlign: "left",
                height: "auto",
                padding: "0",
                maxWidth: width
              }}
              onClick={() => onSavedConfigSelect(config, ownershipType)}>
            <div
                style={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  fontSize: 12,
                  padding: "5px 13px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}>
              {namePrefix}{reportName}
            </div>
          </FlatButton>
        </Tooltip>
      </div>
  );
});

const GenerateCsvButton = React.memo(({
  config,
  generatingAsCsvConfigId,
  isGeneratingCsv,
  onGenerateSavedConfigAsCsvRequest
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const id = config.get("id");
  const isGeneratingReportAsCsv = id === generatingAsCsvConfigId;
  return (
      <Tooltip text="Generate as CSV" position="top">
        <IconButton
            style={iconButtonStyle(theme)}
            disabled={isGeneratingCsv}
            onClick={() => onGenerateSavedConfigAsCsvRequest(config)}
            size="large">
          <i
              className={isGeneratingReportAsCsv ? "fa fa-spinner fa-pulse fa-1x" : "fa fa-file"}
              style={{fontSize: 14}} />
        </IconButton>
      </Tooltip>
  );
});

const CustomButton = pure(({theme, config, extraButton}) => {
  const pageId = config.get("id");
  const isActive = extraButton.get("isActive")(pageId);
  const tooltipText = isActive ? extraButton.get("activeTooltipText") :
      extraButton.get("tooltipText");

  return (
      <Tooltip text={tooltipText} position="left">
        <IconButton
            style={{...iconButtonStyle(theme)}}
            disabled={extraButton.get("disabled")}
            onClick={() => extraButton.get("onClick")(pageId)}
            size="large">
          <i
              className={extraButton.get("faClass")}
              style={isActive ? {color: theme.palette.primary.main, fontSize: 14} : {fontSize: 14}} />
        </IconButton>
      </Tooltip>
  );
});

const ShareButton = pure(({
  config,
  isGeneratingCsv,
  onShareSavedConfigRequest,
  theme
}) => (
    <Tooltip text="Share" position="left">
      <IconButton
          data-test-id="share-saved-config"
          style={{...iconButtonStyle(theme)}}
          disabled={isGeneratingCsv}
          onClick={() => onShareSavedConfigRequest(config)}
          size="large">
        <i
            className="bhi-share"
            style={{fontSize: 14}} />
      </IconButton>
    </Tooltip>
));

const DeleteButton = React.memo(({
  config,
  isGeneratingCsv,
  onRemoveSavedConfigRequest,
  ownershipType
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const isSystemDefault = config.get("systemDefault");
  const isOwnedConfig = ownershipType === "ownedConfigs";
  const tooltipText = isSystemDefault && isOwnedConfig
      ? "System defaults cannot be removed"
      : "Remove";
  return (
      <Tooltip text={tooltipText} position="left">
        <IconButton
            data-test-id="delete-saved-config"
            style={{...iconButtonStyle(theme), color: "#A4262C"}}
            disabled={isGeneratingCsv || isSystemDefault}
            onClick={() => onRemoveSavedConfigRequest(config, ownershipType)}
            size="large">
          <i
              className={"bhi-times"}
              style={{color: "inherit", fontSize: 13}} />
        </IconButton>
      </Tooltip>
  );
});

const LoadingReports = () => (
    <div style={{textAlign: "center", textTransform: "uppercase", color: "#bbb", padding: 10}}>
      <span style={{paddingRight: 8}}>Loading</span><i className="fa fa-spinner fa-pulse fa-1x" />
    </div>
);

const NothingToView = ({theme}) => {
  const emptyMenuStyle = theme => ({
    backgroundColor: theme.palette.background.card,
    borderTop: `1px solid ${theme.palette.border.lightest}`,
    borderBottom: `1px solid ${theme.themeId === "light" ? "#fff" : theme.palette.border.light}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    fontSize: "14px",
    textAlign: "center",
    color: "#bbb"
  });
  return (<div style={emptyMenuStyle(theme)}>Nothing to view</div>);
};

const headerStyle = theme => ({
  fontSize: theme.themeId === "light" ? "20px" : "11px",
  fontWeight: theme.themeId === "light" ? "600" : "400",
  padding: "15px 15px",
  color: theme.palette.text.main,
  lineHeight: 1
});

const iconButtonStyle = theme => ({
  color: theme.palette.text.main,
  backgroundColor: "transparent",
  padding: 0,
  width: 36,
  height: 24
});


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SavedConfigsMenu theme={theme} {...props} />;
};


SavedConfigsMenu.displayName = "SavedConfigsMenu";
export default Wrapper;
