import React from "react";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import Tooltip from "js/common/views/tooltips";
import UnsavedChangesIndicator from "js/dashboards/toolbar/unsaved-changes-indicator";

const buttonStyle = {marginRight: "0.5rem", fontSize: "12px", paddingRight: 15, paddingLeft: 15};

const SaveButton = ({
  disabled = false,
  label = "Save",
  showUnsavedChangesIndicator = false,
  onClick,
  tooltipText
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return (
      <div>
        <Tooltip
            disable={!tooltipText}
            width={200}
            styleType={theme.themeId === "light" ? "dark" : "light"}
            wrap={true}
            pointerStyle={{top: "20%"}}
            text={tooltipText}
            position="right">
          <TextButton
              dataProductId="save-dashboard-button"
              testId="save-dashboard-button"
              disabled={disabled}
              style={buttonStyle}
              type="inverted"
              iconType="bhi"
              icon="save"
              label={label}
              onClick={onClick} />
        </Tooltip>
        {showUnsavedChangesIndicator && <UnsavedChangesIndicator />}
      </div>);
};

export default SaveButton;