import React from "react";
import ReactTooltip from "react-tooltip";
import {v4 as uuid} from "uuid";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const baseLabelStyle = {
  fontSize: "0.8rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "0.2rem",
  width: "fit-content"
};

const tooltipIconStyle = (theme) => ({
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: 5,
  border: `1px solid ${theme.palette.primary.main}`,
  width: 14,
  height: 14,
  borderRadius: "50%",
  display: "flex"
});

const infoIconStyle = (theme) => ({
  color: theme.palette.primary.main,
  fontSize: "8px",
});

const tooltipBodyStyle = {
  fontSize: "0.8rem",
  textTransform: "none",
  maxWidth: 300,
  margin: 0,
  padding: 0
};

const TooltipLabel = React.memo(({label, tooltip, customLabelStyle, customTooltipStyle}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const id = uuid();
  const labelTooltipId = "label-tooltip-" + id;

  return (
      <span style={{...baseLabelStyle, ...customLabelStyle}}>
        {label}
        {tooltip &&
            <div
                data-tip=""
                data-for={labelTooltipId}
                style={tooltipIconStyle(theme)}>
              <i
                  className="fa fa-info"
                  style={infoIconStyle(theme)} />
              <ReactTooltip
                  textColor={theme.palette.text.inverted}
                  backgroundColor={theme.palette.background.inverted}
                  zIndex={9999999}
                  place="top"
                  effect="solid"
                  id={labelTooltipId}>
                <p style={{...tooltipBodyStyle, ...customTooltipStyle}}>{tooltip}</p>
              </ReactTooltip>
            </div>
        }
      </span>
  );
});

export default TooltipLabel;