import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import MultiSelect from "js/admin/kpis/edit-kpis/tabs/common/multi-select";
import Immutable from "immutable";

const ConfigOwnership = betterMemo(
    {displayName: "ConfigOwnership"},
    ({
      kpi,
      entityTypeToLabel,
      availableOwners = Immutable.List(),
      onChange,
      disabled
    }) => {
      const rootGroupingEntity = kpi.get("readOnlyRootGroupingEntity")
          ?? kpi.getIn(["readOnlyCombined", "readOnlyRootGroupingEntity"]);
      const isSplitRootEntity = rootGroupingEntity === "PLACEMENT_SPLIT";

      const currentValue = kpi.getIn(["config", "entityToOwnerTypes"], Immutable.List());
      const currentValueOptions = currentValue
          .entrySeq()
          .flatMap(([entity, ownerTypes]) => {
            const options = []
            ownerTypes.forEach(ownerType => {
              let entityLabel;
              if (entity === "RECORD") {
                entityLabel = entityTypeToLabel.get(rootGroupingEntity);
              } else {
                entityLabel = entityTypeToLabel.get(entity);
                if (entity === rootGroupingEntity) {
                  entityLabel = "Parent " + entityLabel;
                }
              }
              const ownerLabel = ownerType === "OWNER" ? "Owner" : "Assignee";
              options.push({value: `${entity}:${ownerType}`, label: `${entityLabel} - ${ownerLabel}`});
            });
            return Immutable.fromJS(options);
      });

      let labelTooltip = "Select the ownership fields that this metric should use to determine who is credited with the activity.";
      if (isSplitRootEntity) {
        const ownedOnlyByRecordOwner= Immutable.is(currentValue, Immutable.fromJS({"RECORD":["OWNER"]}))
        if (ownedOnlyByRecordOwner) {
          disabled = ownedOnlyByRecordOwner;
          labelTooltip =
              "Ownership change disabled due to selected metric type. Use the equivalent “Ignoring Splits” type if you wish to use alternative ownership.";
        }
      }

      const linkedEntitiesOptions = [];
      // Add the readOnlyRootGroupingEntity
      if(rootGroupingEntity) {
        const rootOwnerLabel = `${entityTypeToLabel.get(rootGroupingEntity)} - Owner`;
        const rootAssigneeLabel = `${entityTypeToLabel.get(rootGroupingEntity)} - Assignee`;
        if(rootGroupingEntity !== "PLACEMENT") {
          linkedEntitiesOptions.push({value: `RECORD:OWNER`, label: rootOwnerLabel})
        }

        if (!isSplitRootEntity) {
          linkedEntitiesOptions.push({value: `RECORD:ASSIGNEE`, label: rootAssigneeLabel});
        }
      }

      availableOwners
          .forEach(entity => {
              // Added this check to ensure that the entity exists, due clients not having LEAD entity
            if (entityTypeToLabel.has(entity)) {
                if(rootGroupingEntity === entity) {
                    linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: `Parent ${entityTypeToLabel.get(entity)} - Owner`});
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: `Parent ${entityTypeToLabel.get(entity)} - Assignee`})
                } else {
                    const ownerLabel = `${entityTypeToLabel.get(entity)} - Owner`;
                    const assigneeLabel = `${entityTypeToLabel.get(entity)} - Assignee`;
                    // Add other entities
                    if (entity !== "PLACEMENT") {
                      linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: ownerLabel});
                    }
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: assigneeLabel})
                }
            }
          });
      const updateEntityToOwnerType = (ownerTypes) => {
        const ots = ownerTypes.groupBy(ot => ot.split(":")[0]).map(x => x.map(y => y.split(":")[1])).toMap();
        onChange(ots)
      }

      let options = Immutable.fromJS(linkedEntitiesOptions).toList();
      const optionSet = options.toSet();
      currentValueOptions.forEach(option => {
        if (!optionSet.has(option)) {
          options = options.push(option);
        }
      })

      return <MultiSelect
          selectedValues={currentValueOptions.map(option => option.get("value"))}
          onChange={updateEntityToOwnerType}
          placeholder="Select Ownership Type"
          options={options}
          label="Owners"
          labelTooltip={labelTooltip}
          disabled={disabled} />
    });

export default ConfigOwnership;
