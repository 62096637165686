/** @jsxImportSource @emotion/react */

import React from "react";
import ReactSelect from "react-select";
import Immutable from "immutable";
import {v4 as uuid} from "uuid";
import {hasValue} from "js/common/utils/value-checking";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const formatOptionLabel = ({value, label, itemDescription}, {context}) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{label}</div>
      {itemDescription && context === "menu" &&
        <div style={{fontSize: 11, margin: "5px 0", opacity: 0.8}}>
          {itemDescription}
        </div>
      }
    </div>
);

const ImmutableReactSelect = React.memo(
    ({
      options = Immutable.List(),
      keys: [valueKey, labelKey] = [],
      isMulti = true,
      isClearable = true,
      placeholder = "Search",
      selectedValues = null,
      style,
      theme = "default",
      selectedValue = null,
      label,
      onChange = () => {},
      dataTestId,
      showItemDescriptions = false,
      ...props
    }) => {
      if ((isMulti && selectedValue) || (!isMulti && selectedValues)) {
        throw new Error("do not mix up multi/single picker props (multi, selectedValue, selectedValues)");
      }
      if (labelKey && valueKey) {
        options = options.map(o => Immutable.Map({
          label: o.get(labelKey),
          value: o.get(valueKey)
        }));
      }

      const uniqueInputId = uuid();
      style = style !== undefined ? style.border : undefined;
      const { palette, themeId, typography } = React.useContext(CustomThemeContext).theme;
      const borderStyle = props.borderStyle || style || `2px solid ${palette.background.paper}`;
      const defaultStyles = {
        menu: (provided) => ({
          ...provided,
          backgroundColor: `${palette.primary.main}`,
          zIndex: 9999,
          marginTop: 0,
          borderBottom: `${themeId === "light" ? "none" : `2px solid ${palette.primary.main}`}`,
          overflow: "auto"
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0
        }),
        control: (provided, state) => ({
          ...provided,
          margin: theme === "cube2021" && "5px 0 10px 0",
          height: isMulti ? 'auto' : 42,
          backgroundColor: state.isDisabled ? palette.action.hover : palette.background.card,
          color: state.isDisabled ? palette.text.disabled : palette.text.primary,
          border: props.error ? `2px solid ${palette.error.main}` : borderStyle,
          boxShadow: "none",
          minHeight: 0,
          cursor: "pointer",
          ":hover": {
            backgroundColor: palette.action.hover,
            border: props.error ? `1px solid ${palette.error.main}` : borderStyle
          }
        }),
        input: (provided, state) => ({
          ...provided,
          color: palette.text.main,
          fontFamily: `${typography.fontFamily} !important`,
          input: {
            fontFamily: `${typography.fontFamily} !important`,
            color: palette.text.main,
          }
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? palette.text.disabled : palette.text.primary,
          ":hover": state.isDisabled ? palette.text.disabled : palette.text.primary
        }),
        indicatorSeparator: () => ({display: "none"}),
        placeholder: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? palette.text.disabled : palette.text.primary,
          whiteSpace: "nowrap",
          fontSize: "0.875rem"
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
              ? palette.background.paper
              : palette.background.card,
          color: state.isFocused ? palette.primary.main : palette.text.primary,
          fontSize: theme === "cube2021" ? "13px !important" : "0.875rem",
          padding: "4.5px 10px",
        }),
        multiValue: (provided, state) => ({...provided,
          color: `${state.isDisabled
              ? palette.text.disabled
              : palette.text.primary} !important`,
          backgroundColor: state.isDisabled ? (theme.themeId === "dark" ? palette.grey["800"] : palette.grey.A200) : `${theme.themeId === "light" ? palette.grey.A100 : palette.background.paper}`,
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? palette.text.disabled : palette.text.main
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          ...(state.isDisabled && {display: "none"}),
          ":hover": {
            color: palette.action.focus
          },
          color: palette.text.disabled,
          backgroundColor: `${themeId === "light" ? palette.grey.A200 : palette.background.paper}`,
          //borderLeft: `1px solid ${palette.border.light}`,
          padding: "0 2px"
        }),
        singleValue: (provided, state) => ({
          ...provided,
          fontSize: theme === "cube2021" ? "13px !important" : "0.875rem",
          color: `${state.isDisabled
              ? palette.text.disabled
              : palette.text.primary} !important`,
          padding: "3px 0"
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: palette.action.disabled,
          paddingRight: 0
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontSize: "0.875rem",
          textAlign: "left"
        })
      };

      let value;
      if (isMulti) {
        if (hasValue(selectedValues)) {
          value = options.filter(o => selectedValues.contains(o.get("value")));
        }
      } else if (hasValue(selectedValue)) {
        value = options.find(o => o.get("value") === selectedValue);
      }

      return (
          <>
            {label && <label style={{fontSize: "0.8rem"}} htmlFor={uniqueInputId}>{label}</label>}
            <div data-test-id={dataTestId}>
              <ReactSelect
                  {...props}
                  id={uniqueInputId}
                  styles={defaultStyles}
                  placeholder={placeholder}
                  options={options.toJS()}
                  isMulti={isMulti}
                  isClearable={isClearable}
                  backspaceRemovesValue={!!isMulti}
                  formatOptionLabel={showItemDescriptions && formatOptionLabel}
                  value={value ? value.toJS() : placeholder}
                  onChange={(selectedOpts) => {
                    if (isMulti) {
                      const newSelectedValues = Immutable.fromJS(selectedOpts).map(opt => opt.get("value"));
                      onChange(newSelectedValues);
                    } else {
                      if (!selectedOpts) {
                        const value = isClearable ? null : selectedValue;
                        onChange(value);
                      } else {
                        const newValue = selectedOpts.value;
                        onChange(newValue);
                      }
                    }
                  }} />
              </div>
          </>);
    });

ImmutableReactSelect.displayName = "ImmutableReactSelect";
export default ImmutableReactSelect;
