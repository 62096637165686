import React from "react";
import Immutable from "immutable";
import moment from "moment";
import store from "store";
import promiseLimit from "promise-limit";

import Drawer from "js/common/views/drawer";
import currentClient from "js/common/repo/backbone/current-client";
import useMountEffect from "js/common/utils/use-mount-effect";
import {safeColors} from "js/common/colors-list";
import {betterMemo} from "js/common/utils/more-memo";
import {indexBy} from "js/common/utils/collections";
import {getUniqueName} from "js/common/utils/unique-naming";

import {
  createNewCombinedKpi,
  createNewWrappedKpi,
  getDefaultTestConfig,
  getQueryType,
  getQueryTypeReferences,
  matchWords,
  recursiveDependencyLookup,
  useCalculatedImmutableState,
  useMountUnmountEffect,
  visibleComparison
} from "js/admin/kpis/edit-kpis/utils";
import {
  getIssuesWithWrappedKpi,
  getNameForValidation,
  hasTestError,
  isValidKpi
} from "js/admin/kpis/edit-kpis/validation";
import * as Popups from "js/common/popups";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Kpis from "js/common/kpis";
import * as Users from "js/common/users";
import * as Ajax from "js/common/ajax";
import * as Groups from "js/common/groups";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as KeyKpis from "js/admin/kpis/key-kpis/key-kpis";
import * as Time from "js/common/utils/time";
import * as Permissions from "js/common/permissions";

import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import Checkbox from "js/common/views/inputs/checkbox";
import ErrorMsg from "js/common/views/error";
import Overlay from "js/common/views/overlay";
import UnsavedChangesDialog from "js/common/views/unsaved-changes-dialog";
import LoadingSpinner from "js/common/views/loading-spinner";
import {TextButton} from "js/common/views/inputs/buttons";
import {MultiMetricImportExportDialog} from "js/admin/kpis/edit-kpis/tabs/import-export";
import AddKpiDialog from "js/admin/kpis/edit-kpis/add-kpi-dialog";
import SaveReasonDialog from "js/admin/kpis/edit-kpis/save-reason-dialog";
import ChangeSubmissionsMenu from "js/admin/kpis/edit-kpis/change-submissions-menu";
import KpiChangeSubmission from "js/admin/kpis/edit-kpis/kpi-change-submission";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {LinearProgress} from "@mui/material";
import * as Colors from "js/common/cube19-colors";
import EditKpiEntity from "js/admin/kpis/edit-kpis/edit-kpi-entity";
import Dialog from "js/common/views/dialog";
import * as auditor from "js/common/auditer";

const testKpiConcurrency = 1;
const limit = promiseLimit(testKpiConcurrency);

// TODO soft delete metric will probably be necessary when mistakes are made
//    should "invisible" become "deleted"?
//    all pickers must show invisible/deleted metrics if already selected
//    don't show deleted metrics by default
//    deleted sounds less usuable than invisible (clients tend to reactivate broken invisible metrics)
//    a slight obstacle will really help here

const keysNotRequiringTest = Immutable.Set.of(
    "name",
    "trueName",
    "visible",
    "order",
    "explanation",
    "columnsKpiId"
);

const pathsRequiringSpeculativeChange = Immutable.Set.of(
    Immutable.List.of("templateId"),
    Immutable.List.of("queryParams", "forwardReport"),
    Immutable.List.of("config", "forwardReport"),
    Immutable.List.of("queryParams", "entity"),
    Immutable.List.of("config", "entity"));

const pathsRequiringComplexColumnChange = Immutable.Set.of(
    Immutable.List.of("queryParams", "kpisToSum"));

const getParentKpiId = (kpi, masterKpiTypeToKpiId) => {
  const combineWithKpiId = kpi.get("combineWithKpiId");
  const combineWithMasterMetricType = kpi.get("combineWithMasterMetricType");

  return combineWithKpiId ??
      masterKpiTypeToKpiId.get(combineWithMasterMetricType) ??
      null;
};

const getParentWrappedKpi = (kpi, masterKpiTypeToKpiId, idToWrappedKpi) => {
  const kpiId = getParentKpiId(kpi, masterKpiTypeToKpiId);
  return kpiId ? idToWrappedKpi.get(kpiId) : null;
};

const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";

const sortWrappedKpis = idToWrappedKpi => {
  const wrappedKpis = idToWrappedKpi.toList();
  const visible = wrappedKpis
      .filter(wk => wk.getIn(["kpi", "visible"]))
      .map(wk => wk.getIn(["kpi", "id"]));
  const hidden = wrappedKpis
      .filter(wk => !wk.getIn(["kpi", "visible"]))
      .sortBy(wk => wk.getIn(["kpi", "name"]))
      .map(wk => wk.getIn(["kpi", "id"]));
  return visible.concat(hidden);
};

const getChangedKpisForTests = idToWrappedKpi => {
  return idToWrappedKpi
      .valueSeq()
      .filter(wk => !wk.get("isUnsaved") && wk.get("sendForTests"))
      .map(wk => wk.get("kpi"));
};

const generateKpiIdToColor = (kpis, storedKpiIdToColor = Immutable.Map()) =>
    kpis
        .groupBy(kpi => kpi.get("readOnlyRootGroupingEntity"))
        .map((groupKpis) => {
          const kpiIdsNeedingColor = groupKpis.map(kpi => kpi.get("combinedKpi") || kpi.get("id")).toList();
          const existingKpiIdToColor = storedKpiIdToColor.filter((v, k) => kpiIdsNeedingColor.includes(k));
          const usedColors = existingKpiIdToColor.toSet();
          let availableColors = safeColors.filter(color => !usedColors.has(color)).toList();
          const remainingKpiIds = kpiIdsNeedingColor.filter(kpiId => !existingKpiIdToColor.has(kpiId)).toList();

          while (remainingKpiIds.size > availableColors.size) {
            availableColors = availableColors.push(generateRandomColor(usedColors));
          }

          const remainingKpiIdToColor = Immutable.Map(remainingKpiIds.zip(availableColors));
          return remainingKpiIdToColor.merge(existingKpiIdToColor);
        })
        .flatMap(kpi => kpi);

const generateRandomColor = (usedColors) => {
  let randomColor;
  while (!randomColor || usedColors.has(randomColor)) {
    const letters = "0123456789ABCDEF";
    randomColor = "#";
    for (let i = 0; i < 6; i++) {
      randomColor += letters[Math.floor(Math.random() * 16)];
    }
  }
  return randomColor;
};

const assignMirrorColor = (idToWrappedKpi, kpiId) => {
  if (!idToWrappedKpi.getIn([kpiId, "mirrorColor"])) {
    const usedColors = idToWrappedKpi.toList().map(wk => wk.get("mirrorColor")).toSet();
    const availableColors = safeColors.filter(color => !usedColors.has(color));
    if (availableColors.isEmpty()) {
      return idToWrappedKpi.setIn([kpiId, "mirrorColor"], generateRandomColor(usedColors));
    } else {
      return idToWrappedKpi.setIn([kpiId, "mirrorColor"], availableColors.first());
    }
  }
  return idToWrappedKpi;
};

const findCombinationReferencesToKpiIds = (idToWrappedKpi, masterKpiTypeToKpiId, kpiIds) => {
  const idToParentId = idToWrappedKpi.map(wk => getParentKpiId(wk.get("kpi"), masterKpiTypeToKpiId));
  const idToDependencies = Immutable.Map().withMutations(map => {
    idToParentId.forEach((parentId, id) => parentId && map.update(parentId, Immutable.List(), deps => deps.push(id)));
  });
  return recursiveDependencyLookup(idToDependencies, kpiIds);
};

const getKpiIdToQueryTypeDependencies = (idToWrappedKpi, masterKpiTypeToKpiId, idToTemplate) => {
  const idToReferences = idToWrappedKpi.map(wk => getQueryTypeReferences(
      wk.get("kpi"),
      masterKpiTypeToKpiId,
      idToTemplate));
  return Immutable.Map().withMutations(map => {
    idToReferences.forEach((references, id) => {
      references.forEach(ref => map.update(ref, Immutable.List(), deps => deps.push(id)));
    });
  }).map(dependencies => dependencies.map(id => idToWrappedKpi.getIn([id, "kpi"])));
};

const getEntityLabelForKpi = (kpi, entityTypeToLabel) => {
  const groupingEntity = kpi.get("readOnlyRootGroupingEntity");
  if (!groupingEntity) {
    return "Other";
  }
  let labelEntity;
  if (groupingEntity === "PLACEMENT_SPLIT") {
    labelEntity = "PLACEMENT";
  } else {
    labelEntity = groupingEntity;
  }
  return entityTypeToLabel.get(labelEntity);
};

const RevertToLegacyFormatDialog = ({idToWrappedKpi, masterKpiTypeToKpiId, kpiId, onRevert, onCancel}) => {
  const newFormatChildren = findCombinationReferencesToKpiIds(
      idToWrappedKpi,
      masterKpiTypeToKpiId,
      Immutable.List([kpiId]))
      .map(id => idToWrappedKpi.get(id))
      .filter(wk => wk.getIn(["kpi", "config"]));
  const confirmationMessage = <div><p>This metric will be reverted to the legacy config. If this change is saved, it
    will not be possible to return the metric to the new format.</p></div>;
  const unableToRevertMessage = <div>
    <p>The following metrics inherit from this metric and use the new format:</p>
    {newFormatChildren.map(wk => <p>{wk.getIn(["kpi", "name"])}</p>)}
  </div>;
  return <Dialog
      autoDetectWindowHeight={true}
      titleStyle={{color: "#f9ec33", fontSize: "1rem"}}
      bodyStyle={{overflow: "visible", color: "#fff"}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      title={newFormatChildren.isEmpty() ? "Revert to legacy format" : "Can't revert"}
      open={true}
      onBackdropClick={onCancel}
      actions={[
        <TextButton
            key="close"
            type="dark"
            label={"Close"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={onCancel} />,
        newFormatChildren.isEmpty() && <TextButton
            key="close"
            type="alert"
            label={"Revert"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={() => onRevert(idToWrappedKpi.get(kpiId))} />]}>
    {newFormatChildren.isEmpty() ? confirmationMessage : unableToRevertMessage}
  </Dialog>;
};

const DisableMetricDialog = ({idToWrappedKpi, kpiId, onDisable, onCancel}) => {
  return <Dialog
      autoDetectWindowHeight={true}
      titleStyle={{color: "#f9ec33", fontSize: "1rem"}}
      bodyStyle={{overflow: "visible", color: "#fff"}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      title="Disable Metric"
      open={true}
      onBackdropClick={onCancel}
      actions={[
        <TextButton
            key="close"
            type="dark"
            label={"Close"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={onCancel} />,
        <TextButton
            key="close"
            type="alert"
            label={"Disable"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={() => onDisable(idToWrappedKpi.get(kpiId))} />]}>
    <div>
      <p>Disabling a metric will cause it to display as 0 in all areas of the system, including all saved reports.
        Please reach out to the client to make them aware as soon as this action is taken.</p></div>
  </Dialog>;
};

const DeleteMetricDialog = ({idToWrappedKpi, kpiId, onDelete, onCancel}) => {
  const kpiName = idToWrappedKpi.getIn([kpiId, "kpi", "name"]);
  return <Dialog
      autoDetectWindowHeight={true}
      titleStyle={{color: "#f9ec33", fontSize: "1rem"}}
      bodyStyle={{overflow: "visible", color: "#fff"}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      title={`Delete ${kpiName}`}
      open={true}
      onBackdropClick={onCancel}
      actions={[
        <TextButton
            key="close"
            type="dark"
            label={"Close"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={onCancel} />,
        <TextButton
            key="close"
            type="alert"
            label={"Delete"}
            style={{marginLeft: "0.5rem", color: "#fff", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={() => onDelete(idToWrappedKpi.get(kpiId))} />]}>
    <div>
      <p>Are you sure you want to delete {kpiName}? This will cause the metric to be unavailable for selection in all
        areas of the system.</p></div>
  </Dialog>;
};

const EditKpisApp = betterMemo({displayName: "MetricAdminPage"}, () => {
  const storeKeyRoot = React.useMemo(() => {
    const currentClientId = currentClient.get("id");
    const currentUserId = Users.getCurrentUser().get("id");
    return "cube19.admin.metrics." + currentClientId + "." + currentUserId;
  }, []);

  const [filterText, setFilterText] = React.useState("");
  const [searchFocused, setSearchFocused] = React.useState(false);
  const [showOnlyEditableKpis, setShowOnlyEditableKpis] = React.useState(false);
  const [showOnlyFailingKpis, setShowOnlyFailingKpis] = React.useState(false);
  const [showOnlyChangedKpis, setShowOnlyChangedKpis] = React.useState(false);

  const [showImportExportDialog, setShowImportExportDialog] = React.useState(false);

  const [expandedEntityLabels, setExpandedEntityLabels] = React.useState(Immutable.Set());
  const [expandedKpiIds, setExpandedKpiIds] = React.useState(Immutable.Set());
  const [originalIdToWrappedKpi, setOriginalIdToWrappedKpi] = React.useState(Immutable.Map());
  const [idToWrappedKpi, setIdToWrappedKpi] = React.useState(Immutable.Map());
  const [idToTemplate, setIdToTemplates] = React.useState(Immutable.Map());
  const [entityTypeToLabel, setEntityTypeToLabel] = React.useState(Immutable.Map());
  const [typeToGroupingEntity, setTypeToGroupingEntity] = React.useState(Immutable.Map());
  const [actionTypes, setActionTypes] = React.useState(Immutable.Set());
  const [trendTypes, setTrendTypes] = React.useState(Immutable.Set());
  const [aggregationTypes, setAggregationTypes] = React.useState(Immutable.Set());
  const [idToEntityColumn, setIdToEntityColumn] = React.useState(Immutable.Map());
  const [storedChangeDateTime, setStoredChangeDateTime] = React.useState(() => retrieveStoredChangeDateTime(storeKeyRoot));
  const [loading, setLoading] = React.useState(true);
  const [showLoadingOverlay, setShowLoadingOverlay] = React.useState(false);
  const [kpiIdToMasterKpis, setkpiIdToMasterKpis] = React.useState(Immutable.OrderedMap());
  const [masterKpiTypeToKpiId, setMasterKpiTypeToKpiId] = React.useState(Immutable.OrderedMap());
  const [testAllProgress, setTestAllProgress] = React.useState(null);
  const [isAddKpiOpen, setIsAddKpiOpen] = React.useState(false);
  const [kpiIdToRevertToLegacyFormat, setKpiIdToRevertToLegacyFormat] = React.useState(null);
  const [kpiIdToDisable, setKpiIdToDisable] = React.useState(null);
  const [kpiIdToDelete, setKpiIdToDelete] = React.useState(null);
  const [changeSubmissions, setChangeSubmissions] = React.useState(Immutable.List());
  const [loadingChangeSubmissions, setLoadingChangeSubmissions] = React.useState(false);
  const [showChangeSubmissionsDrawer, setShowChangeSubmissionsDrawer] = React.useState(false);
  const [showSaveReasonDialog, setShowSaveReasonDialog] = React.useState(false);
  const [pendingSaveData, setPendingSaveData] = React.useState(null);
  const [windowLocation, setWindowLocation] = React.useState(null);
  const testsCancelled = React.useRef(false);
  const unmounted = React.useRef(false);
  const {theme} = React.useContext(CustomThemeContext);
  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useMountEffect(() => {
    KeyKpis.getAll()
        .then(keyKpis => {
          setkpiIdToMasterKpis(keyKpis
              .groupBy(k => k.get("selectedKpiId"))
              .delete(null));
          setMasterKpiTypeToKpiId(indexBy(mk => mk.get("type"), keyKpis)
              .map(mk => mk.get("selectedKpiId"))
              .filter(id => id));
        });
  });

  const currentUser = Users.getCurrentUser();
  const [userIsKpiEditor, isCube19User, clientHasEdit, isSwitchingUser] = React.useMemo(() => {
    return [
      Users.currentHasPermission(Permissions.userPermissions.canSubmitKpis),
      Users.isCube19User(currentUser),
      currentClient.hasPermission(Permissions.clientPermissions.canEditKpiConfigs),
      !!currentUser.get("adminConsoleUsername")];
  }, [currentUser]);


  const filteredSimpleSumKpiIds = useCalculatedImmutableState(
      () => {
        return idToWrappedKpi
            .toList()
            .filter(wrappedKpi => getQueryType(wrappedKpi.get("kpi"), idToTemplate) === "SIMPLE_SUM")
            .filter(wrappedKpi => matchWords(
                wrappedKpi,
                filterText,
                showOnlyFailingKpis,
                hasTestError,
                showOnlyChangedKpis,
                showOnlyEditableKpis,
                isCube19User,
                idToTemplate))
            .map(wrappedKpi => wrappedKpi.getIn(["kpi", "id"]))
            .sort((a, b) => visibleComparison(a, b, idToWrappedKpi))
            .sort((a, b) => idToWrappedKpi.get(a).get("name")?.localeCompare(idToWrappedKpi.get(b).get("name")));
      },
      [
        idToTemplate,
        filterText,
        idToWrappedKpi,
        showOnlyFailingKpis,
        showOnlyEditableKpis,
        hasTestError,
        showOnlyChangedKpis]);

  const NoRootEntityToFilteredKpiIds = useCalculatedImmutableState(
      () => {
        return idToWrappedKpi
            .toList()
            .filter(wrappedKpi =>
                !wrappedKpi.getIn(["combinedKpi", "readOnlyRootGroupingEntity"]) &&
                getQueryType(wrappedKpi.get("kpi"), idToTemplate) !== "SIMPLE_SUM")
            .filter(wrappedKpi => matchWords(
                wrappedKpi,
                filterText,
                showOnlyFailingKpis,
                hasTestError,
                showOnlyChangedKpis,
                showOnlyEditableKpis,
                isCube19User,
                idToTemplate))
            .map(wk => wk.getIn(["kpi", "id"]))
            .sort((a, b) => visibleComparison(a, b, idToWrappedKpi))
            .sort((a, b) => idToWrappedKpi.get(a).get("name")?.localeCompare(idToWrappedKpi.get(b).get("name")));
      },
      [
        idToTemplate,
        filterText,
        idToWrappedKpi,
        showOnlyFailingKpis,
        hasTestError,
        showOnlyChangedKpis,
        showOnlyEditableKpis]);

  const rootEntityToFilteredKpiId = useCalculatedImmutableState(
      () =>
          idToWrappedKpi
              .toList()
              .filter(wrappedKpi => matchWords(
                  wrappedKpi,
                  filterText,
                  showOnlyFailingKpis,
                  hasTestError,
                  showOnlyChangedKpis,
                  showOnlyEditableKpis,
                  isCube19User,
                  idToTemplate))
              .map(wrappedKpi => wrappedKpi.getIn(["kpi", "id"]))
              .sort((a, b) => visibleComparison(a, b, idToWrappedKpi))
              .groupBy(id => {
                    let groupingEntity = idToWrappedKpi.getIn([id, "combinedKpi", "readOnlyRootGroupingEntity"]);
                    groupingEntity = groupingEntity === "PLACEMENT_SPLIT" ? "PLACEMENT" : groupingEntity;
                    return entityTypeToLabel.get(groupingEntity);
                  }
              ),
      [
        idToTemplate,
        entityTypeToLabel,
        filterText,
        idToWrappedKpi,
        showOnlyFailingKpis,
        hasTestError,
        showOnlyChangedKpis,
        showOnlyEditableKpis]);

  const rootEntityToCombinedKpis = useCalculatedImmutableState(() => idToWrappedKpi
      .toList()
      .map(wk => wk.get("combinedKpi"))
      .groupBy(kpi => kpi.get("readOnlyRootGroupingEntity")), [idToWrappedKpi]);

  const columnsKpiIdToKpis = useCalculatedImmutableState(() => idToWrappedKpi
      .toList()
      .map(wk => wk.get("kpi"))
      .groupBy(kpi => kpi.get("columnsKpiId") || kpi.get("id")), [idToWrappedKpi]);

  const loadAndSetData = React.useCallback(() => {
    setLoading(true);
    return Promise
        .all([
          Kpis.loadEditableKpis(),
          Kpis.loadEntities(),
          Kpis.loadTemplates(),
          ((userIsKpiEditor && isCube19User) || clientHasEdit) ? loadEntities() : Promise.resolve(Immutable.List()),
          loadEntityColumns(),
          Kpis.loadActionTypes(),
          Kpis.loadEditorOptions()])
        .then(([kpis, entityTypeToLabel, templates, entities, entityColumns, actionTypes, editorOptions]) => {
          const kpiIdToColor = generateKpiIdToColor(kpis, Immutable.Map());
          const defaultTestConfig = getDefaultTestConfig();
          const wrappedKpis = kpis
              .map(kpi => Immutable.Map({
                kpi,
                testConfig: defaultTestConfig,
                mirrorColor: kpiIdToColor.get(kpi.get("id")),
                savedName: kpi.get("name"),
                combinedKpi: kpi.get("readOnlyCombined") ?? kpi,
                combineError: kpi.get("hasCombineError") && "Inheritance Failure"
              }));
          let idToWrappedKpi = indexBy(wk => wk.getIn(["kpi", "id"]), wrappedKpis);
          setAggregationTypes(editorOptions.get("aggregationTypes"));
          setTrendTypes(editorOptions.get("trendTypes"));
          setOriginalIdToWrappedKpi(idToWrappedKpi);
          setIdToWrappedKpi(idToWrappedKpi);
          setActionTypes(actionTypes);
          setEntityTypeToLabel(entityTypeToLabel);
          setIdToTemplates(indexBy(t => t.get("id"), templates));
          setIdToEntityColumn(indexBy(e => e.get("id"), entityColumns));
          setTypeToGroupingEntity(indexBy(e => e.get("entity"), entities));
          setLoading(false);
        });
  }, [isCube19User, userIsKpiEditor, clientHasEdit]);

  React.useEffect(() => {
    loadAndSetData();
  }, [loadAndSetData]);

  const loadChangeSubmissions = React.useCallback(() => {
    if (isCube19User && userIsKpiEditor) {
      setLoadingChangeSubmissions(true);
      loadSubmissions().then(
          submissions => {
            setChangeSubmissions(submissions);
            setLoadingChangeSubmissions(false);
          },
          () => {
            setLoadingChangeSubmissions(false);
          }
      );
    }
  }, [isCube19User, userIsKpiEditor]);

  React.useEffect(() => {
    loadChangeSubmissions();
  }, [loadChangeSubmissions]);

  const hasChanges = React.useMemo(
      () => !idToWrappedKpi
          .toList()
          .filter(wk => wk.get("changed") || wk.get("columnsChanged"))
          .isEmpty(),
      [idToWrappedKpi]);

  const nameToCount = React.useMemo(
      () => idToWrappedKpi
          .toList()
          .groupBy(wk => getNameForValidation(wk.get("kpi")))
          .map(wks => wks.map(wk => wk.getIn(["kpi", "id"])).count()),
      [idToWrappedKpi]);

  const hasValidationErrors = React.useMemo(
      () => {
        const invalidKpis = idToWrappedKpi
            .toList()
            .filter(wk => !isValidKpi(wk.get("kpi"), nameToCount));
        return invalidKpis.count() > 0;
      },
      [nameToCount, idToWrappedKpi]);

  useMountUnmountEffect(ref => {
    window.onbeforeunload = () => {
      const [hasChanges, expandedKpiIds, expandedEntityLabels, idToWrappedKpi] = ref.current;
      if (hasChanges) {
        const wrappedKpisWithoutTestResult = idToWrappedKpi.map(wk => wk.delete("testResult")).toList();
        saveChangesToLocalStorage(storeKeyRoot, expandedKpiIds, expandedEntityLabels, wrappedKpisWithoutTestResult);
        return unsavedChangesMessage;
      }
    };
    return () => {
      window.onbeforeunload = null;
      const [hasChanges, expandedKpiIds, expandedEntityLabels, idToWrappedKpi] = ref.current;
      if (hasChanges) {
        alert(unsavedChangesMessage);
        const wrappedKpisWithoutTestResult = idToWrappedKpi.map(wk => wk.delete("testResult")).toList();
        saveChangesToLocalStorage(storeKeyRoot, expandedKpiIds, expandedEntityLabels, wrappedKpisWithoutTestResult);
      }
    };
  }, [hasChanges, expandedKpiIds, expandedEntityLabels, idToWrappedKpi]);
  useMountEffect(() => {
    auditor.audit("edit-metrics-admin:loaded");
  });
  const handleDiscardStoredChanges = React.useCallback(() => {
    clearStoredChanges(storeKeyRoot);
    setStoredChangeDateTime(null);
  }, [storeKeyRoot]);

  const handleRetrieveStoredChanges = React.useCallback(() => {
    const {expandedKpiIds, expandedEntityLabels, wrappedKpis} = retrieveStoredChanges(storeKeyRoot);
    setExpandedKpiIds(expandedKpiIds);
    setExpandedEntityLabels(expandedEntityLabels);
    const idToWrappedKpi = indexBy(wk => wk.getIn(["kpi", "id"]), wrappedKpis);
    setIdToWrappedKpi(idToWrappedKpi);

    handleDiscardStoredChanges();
  }, [storeKeyRoot, handleDiscardStoredChanges]);

  const loadColumnsForKpiId = React.useCallback((idToWrappedKpi, kpiId) => {
    const template = idToTemplate.get(idToWrappedKpi.getIn([kpiId, "kpi", "templateId"])) || Immutable.Map();
    if (!idToWrappedKpi.hasIn([kpiId, "columns"]) && template.get("columnsEditable")) {
      Kpis
          .loadColumnsForKpi(kpiId)
          .then(
              columns => setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.setIn([kpiId, "columns"], columns)),
              () => Popups.contactSupport());
    }
  }, [idToTemplate]);

  const loadAvailableOwnersForKpiId = React.useCallback((idToWrappedKpi, kpiId) => {
    if (!idToWrappedKpi.hasIn([kpiId, "availableOwners"])) {
      Kpis
          .loadAvailableOwnersForKpi(kpiId)
          .then(
              owners => setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.setIn([kpiId, "availableOwners"], owners)),
              () => Popups.contactSupport());
    }
  }, []);

  const loadAvailableServerColumnsForKpiId = React.useCallback((idToWrappedKpi, kpiId) => {
    if (!idToWrappedKpi.hasIn([kpiId, "availableServerColumns"])) {
      Kpis
          .loadServerColumnsForKpi(kpiId)
          .then(
              cols => setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.setIn(
                  [kpiId, "availableServerColumns"],
                  cols)));
    }
  }, []);

  const handleExpandEntityClick = React.useCallback((entityLabel, alreadyExpanded) => {
    setExpandedEntityLabels(entityLabels => {
      return alreadyExpanded ? entityLabels.delete(entityLabel) :
          entityLabels.add(entityLabel);
    });
  }, []);

  const handleExpandClick = React.useCallback((kpi, alreadyExpanded) => {
    setExpandedKpiIds(expandedKpiIds => alreadyExpanded ? expandedKpiIds.delete(kpi.get("id")) :
        expandedKpiIds.add(kpi.get("id")));

    if (!alreadyExpanded) {
      const kpiId = kpi.get("id");
      const columnsKpiId = kpi.get("columnsKpiId") || kpiId;
      setIdToWrappedKpi(idToWrappedKpi => {
        loadColumnsForKpiId(idToWrappedKpi, columnsKpiId);
        loadAvailableOwnersForKpiId(idToWrappedKpi, kpiId);
        loadAvailableServerColumnsForKpiId(idToWrappedKpi, kpiId);
        return idToWrappedKpi;
      });
    }
  }, [loadColumnsForKpiId, loadAvailableOwnersForKpiId, loadAvailableServerColumnsForKpiId]);

  const handleDisableMetricToggle = React.useCallback((kpiId, handleKpiChange) => {
    const kpi = idToWrappedKpi.getIn([kpiId, "kpi"]);
    const isCurrentlyEnabled = kpi.get("enabled");
    if (isCurrentlyEnabled) {
      // Show Disabled Kpi Dialog
      setKpiIdToDisable(kpiId);
    } else {
      // Re-enable it
      handleKpiChange(kpi.set("enabled", !isCurrentlyEnabled));
    }
    if (isCurrentlyEnabled) {
      auditor.audit(
          "edit-metrics-admin:disable-metric",
          {kpiId: kpi.get("id")});
    } else {
      auditor.audit(
          "edit-metrics-admin:enable-metric",
          {kpiId: kpi.get("id")});
    }

  }, [idToWrappedKpi, setKpiIdToDisable]);

  const handleDeleteMetric = React.useCallback((kpiId) => {
    setKpiIdToDelete(kpiId);
  }, [setKpiIdToDelete]);

  const handleChangeTab = React.useCallback((kpiId, tabId) => {
    setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.setIn([kpiId, "tabId"], tabId));
  }, []);

  const handleSpecChange = React.useCallback((wrappedKpi, previousKpi) => {
    const kpi = wrappedKpi.get("kpi");
    setShowLoadingOverlay(true);
    const isUnsaved = wrappedKpi.get("isUnsaved");
    Kpis.loadSpeculativeChange(
        isUnsaved ? kpi.delete("id") : kpi,
        (previousKpi && isUnsaved) ? previousKpi.delete("id") : previousKpi)
        .then(specChange => {
          const rootChanged = specChange.get("changed");
          const returnedKpi = specChange.get("kpi").set("id", kpi.get("id"));
          const availableOwners = specChange.get("availableOwners");
          const serverColumns = specChange.get("availableServerColumns");

          if (wrappedKpi.get("rootChanged") || rootChanged) {
            handleUnmirror(kpi);
            const cleanKpi = returnedKpi.set("columnsKpiId", null);

            setIdToWrappedKpi(idToWrappedKpi => {

              let updatedIdToWrappedKpi = idToWrappedKpi.update(cleanKpi.get("id"), wk => wk
                  .set("kpi", cleanKpi)
                  .set("availableOwners", availableOwners)
                  .set("rootChanged", true)
                  .set("columns", specChange.get("columns"))
                  .set("availableServerColumns", serverColumns)
                  .set("columnsChanged", true));

              const hasParent = !!getParentKpiId(cleanKpi, masterKpiTypeToKpiId);

              const combinationDependencyIds = findCombinationReferencesToKpiIds(
                  updatedIdToWrappedKpi,
                  masterKpiTypeToKpiId,
                  Immutable.Set([cleanKpi.get("id")]));

              if (!hasParent) {
                updatedIdToWrappedKpi = updatedIdToWrappedKpi.setIn([cleanKpi.get("id"), "combinedKpi"], cleanKpi);
              }

              updateCombinations(
                  hasParent ? combinationDependencyIds.add(cleanKpi.get("id")) : combinationDependencyIds,
                  updatedIdToWrappedKpi);

              return updatedIdToWrappedKpi;
            });
          } else {
            setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.update(
                returnedKpi.get("id"),
                wk => wk
                    .set("kpi", returnedKpi)
                    .set("availableServerColumns", serverColumns)
                    .set("availableOwners", availableOwners)));
          }
          setShowLoadingOverlay(false);
        }, () => {
          setShowLoadingOverlay(false);
        });
    // TODO figure how to handle this missing handleUnmirror dep, its not actually a problem atm cos the fn has no deps
  }, []);

  const handleRevertToLegacyFormat = React.useCallback(wrappedKpi => {
    const kpi = wrappedKpi.get("kpi");
    const id = kpi.get("id");
    setShowLoadingOverlay(true);
    Kpis.loadRevertToLegacyFormat(wrappedKpi.get("isUnsaved") ? kpi.delete("id") : kpi)
        .then(legacyKpi => {
          legacyKpi = legacyKpi.set("id", id);
          setIdToWrappedKpi(idToWrappedKpi => {
            let updatedIdToWrappedKpi = idToWrappedKpi.update(id, wk => wk
                .set("kpi", legacyKpi)
                .set("changed", true));

            const hasParent = !!getParentKpiId(legacyKpi, masterKpiTypeToKpiId);
            if (!hasParent) {
              updatedIdToWrappedKpi = updatedIdToWrappedKpi.setIn([id, "combinedKpi"], legacyKpi);
            } else {
              updateCombinations(Immutable.List([id]), updatedIdToWrappedKpi);
            }
            return updatedIdToWrappedKpi;
          });
          setShowLoadingOverlay(false);
        }, () => {
          setShowLoadingOverlay(false);
        });
  }, []);

  const handleComplexColumnsChange = React.useCallback((wrappedKpi, columns) => {
    const kpi = wrappedKpi.get("kpi");
    setShowLoadingOverlay(true);
    Kpis.loadComplexColumnsChange(wrappedKpi.get("isUnsaved") ? kpi.delete("id") : kpi, columns)
        .then(columns => {
          setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.update(kpi.get("id"), wk => wk
              .set("columns", columns)
              .set("columnsChanged", true)));
          setShowLoadingOverlay(false);
        }, () => {
          setShowLoadingOverlay(false);
        });
  }, []);

  const updateCombinations = React.useCallback((kpiIds, idToWrappedKpi) => {
    const modifiedKpis = getChangedKpisForTests(idToWrappedKpi);

    Promise.all(kpiIds
        .map(kpiId => idToWrappedKpi.getIn([kpiId, "kpi"]))
        .filter(kpi => kpi.get("combineType") &&
            (kpi.get("combineType") !== "COMPLEX_MERGE" || kpi.get("combineOptions") != null))
        .map(kpi => Kpis.loadCombination(kpi, modifiedKpis)
            .then(newCombined => Immutable.fromJS({
              kpiId: kpi.get("id"),
              success: true,
              combinedKpi: newCombined.set("id", kpi.get("id"))
            }))
            .catch(error => Immutable.fromJS({
              kpiId: kpi.get("id"),
              success: false,
              message: error && error.responseJSON && error.responseJSON.type !== "UNCAUGHT_ERROR" &&
                  error.responseJSON.message
            }))))
        .then(results => Immutable.List(results))
        .then(results => {
          setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.withMutations(idToWrappedKpi => {
            results.forEach(result => {
              const kpiId = result.get("kpiId");
              if (result.get("success")) {
                idToWrappedKpi.deleteIn([kpiId, "combineError"]);
                const oldCombined = idToWrappedKpi.getIn([kpiId, "combinedKpi"]);
                const newCombined = result.get("combinedKpi");
                if (!Immutable.is(oldCombined, newCombined)) {
                  idToWrappedKpi.setIn([kpiId, "combinedKpi"], newCombined);
                }
              } else {
                const message = result.get("message");
                idToWrappedKpi.setIn([kpiId, "combineError"], message);
              }
            });
          }));
        });
  }, []);

  const handleKpiChange = React.useCallback(
      (newKpi, dependencyIds) => {
        setIdToWrappedKpi(idToWrappedKpi => {
          // TODO do import/export resolution of names / master metric types here (common fn)
          //   fail with alert if no resolution found
          //   best option without some kind of context / redux connect for the nested component
          const kpiId = newKpi.get("id");
          const wrappedKpi = idToWrappedKpi.get(kpiId);
          const oldKpi = wrappedKpi.get("kpi");
          const hasChanged = !Immutable.is(newKpi, oldKpi);
          const requiresTest = !Immutable.is(
              newKpi.filter((v, k) => !keysNotRequiringTest.has(k)),
              oldKpi.filter((v, k) => !keysNotRequiringTest.has(k)));
          idToWrappedKpi = idToWrappedKpi.update(kpiId, wrappedKpi => {
            wrappedKpi = wrappedKpi
                .set("kpi", newKpi)
                .update("changed", oldValue => oldValue || hasChanged)
                .update("sendForTests", oldValue => oldValue || requiresTest)
                .update("requiresTest", oldValue => oldValue || requiresTest);
            if (hasChanged) {
              wrappedKpi = wrappedKpi.delete("submitError");
            }
            return wrappedKpi;
          });

          const keysNotRequiringExplUpdate = Immutable.Set(["decimalPlaces", "columnsKpiId"]);
          const requiresExplanationUpdate = newKpi.get("explanation") && !Immutable.is(
              newKpi.filter((v, k) => !keysNotRequiringTest.concat(keysNotRequiringExplUpdate).has(k)),
              oldKpi.filter((v, k) => !keysNotRequiringTest.concat(keysNotRequiringExplUpdate).has(k)));
          idToWrappedKpi = idToWrappedKpi.updateIn(
              [kpiId, "requiresExplanationUpdate"],
              oldValue => oldValue || requiresExplanationUpdate);

          const explanationChanged = newKpi.get("explanation") !== oldKpi.get("explanation");
          if (explanationChanged) {
            idToWrappedKpi = idToWrappedKpi.setIn([kpiId, "requiresExplanationUpdate"], false);
          }

          const hasVisibilityChange = oldKpi.get("visible") !== newKpi.get("visible");
          if (hasVisibilityChange) {
            if (newKpi.get("visible")) {
              const maxOrder = idToWrappedKpi.map(wk => wk.getIn(["kpi", "order"])).max();
              idToWrappedKpi = idToWrappedKpi.setIn([kpiId, "kpi", "order"], maxOrder + 1);
            }
          }

          const needsSpecChange = pathsRequiringSpeculativeChange
              .some(path => oldKpi.getIn(path) !== newKpi.getIn(path));
          if (needsSpecChange) {
            handleSpecChange(idToWrappedKpi.get(kpiId), oldKpi);
          }
          const needsComplexColumnChange = pathsRequiringComplexColumnChange
              .some(path => oldKpi.getIn(path) !== newKpi.getIn(path));
          if (needsComplexColumnChange) {
            handleComplexColumnsChange(idToWrappedKpi.get(kpiId), wrappedKpi.get("columns"));
          }
          if (oldKpi.get("columnsKpiId") !== newKpi.get("columnsKpiId")) {
            const columnsKpiId = newKpi.get("columnsKpiId");
            loadColumnsForKpiId(idToWrappedKpi, columnsKpiId);
            idToWrappedKpi = assignMirrorColor(idToWrappedKpi, columnsKpiId);
          }

          if ((userIsKpiEditor && isCube19User) || clientHasEdit) {
            const hasParent = !!getParentKpiId(newKpi, masterKpiTypeToKpiId);
            if (!hasParent) {
              idToWrappedKpi = idToWrappedKpi
                  .setIn([kpiId, "combinedKpi"], newKpi)
                  .deleteIn([kpiId, "combineError"]);
            }
            const combinationDependencyIds = findCombinationReferencesToKpiIds(
                idToWrappedKpi,
                masterKpiTypeToKpiId,
                Immutable.Set([kpiId]));
            updateCombinations(
                hasParent ? combinationDependencyIds.add(kpiId) : combinationDependencyIds,
                idToWrappedKpi);
          }
          if (requiresTest) {
            idToWrappedKpi = idToWrappedKpi.withMutations(idToWrappedKpi => {
              dependencyIds.forEach(id => idToWrappedKpi.setIn([id, "requiresTest"], true));
            });
          }
          return idToWrappedKpi;
        });
      },
      [
        handleComplexColumnsChange,
        handleSpecChange,
        loadColumnsForKpiId,
        masterKpiTypeToKpiId,
        idToTemplate,
        updateCombinations,
        isCube19User,
        userIsKpiEditor,
        clientHasEdit]);

  const handleColumnsChange = React.useCallback((columns, columnsKpiId, changedKpiIds) => {
    setIdToWrappedKpi(idToWrappedKpi => {
      idToWrappedKpi = idToWrappedKpi.update(columnsKpiId, wk => wk.set("columns", columns));
      changedKpiIds.forEach(kpiId => {
        idToWrappedKpi = idToWrappedKpi.update(kpiId, wk => wk
            .set("columnsChanged", true)
            .delete("submitError"));
      });
      return idToWrappedKpi;
    });
  }, []);

  const handleTestConfigChange = React.useCallback((kpiId, testConfig) => {
    setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.update(kpiId, wk => wk.set("testConfig", testConfig)));
  }, []);

  const handleUnmirror = React.useCallback((kpiToUnmirror) => {
    setIdToWrappedKpi(idToWrappedKpi => {
      const oldColumnsKpiId = kpiToUnmirror.get("columnsKpiId");
      const kpiToUnmirrorId = kpiToUnmirror.get("id");
      if (oldColumnsKpiId === null || oldColumnsKpiId === undefined) {
        const mirroringWrappedKpis = idToWrappedKpi
            .toList()
            .filter(wk => wk.getIn(["kpi", "columnsKpiId"]) === kpiToUnmirrorId);
        if (mirroringWrappedKpis.count() > 0) {
          const newleaderId = mirroringWrappedKpis.first().getIn(["kpi", "id"]);
          idToWrappedKpi = idToWrappedKpi.update(newleaderId, wk => wk
              .setIn(["kpi", "columnsKpiId"], null)
              .set("changed", true)
              .set("columns", idToWrappedKpi.getIn([kpiToUnmirrorId, "columns"]))
              .set("columnsChanged", true));
          if (mirroringWrappedKpis.count() === 1) {
            idToWrappedKpi = idToWrappedKpi.deleteIn([kpiToUnmirrorId, "mirrorColor"]);
          } else {
            idToWrappedKpi = idToWrappedKpi
                .setIn([newleaderId, "mirrorColor"], idToWrappedKpi.getIn([kpiToUnmirrorId, "mirrorColor"]))
                .deleteIn([kpiToUnmirrorId, "mirrorColor"]);
          }
          return idToWrappedKpi.map(wrappedKpi => {
            if (wrappedKpi.getIn(["kpi", "columnsKpiId"]) === kpiToUnmirrorId) {
              return wrappedKpi
                  .setIn(["kpi", "columnsKpiId"], newleaderId)
                  .set("changed", true);
            } else {
              return wrappedKpi;
            }
          });
        } else {
          return idToWrappedKpi;
        }
      } else {
        idToWrappedKpi = idToWrappedKpi.update(kpiToUnmirrorId, wrappedKpi => wrappedKpi
            .setIn(["kpi", "columnsKpiId"], null)
            .set("changed", true)
            .set("columns", idToWrappedKpi.getIn([oldColumnsKpiId, "columns"]))
            .set("columnsChanged", true));
        if (!idToWrappedKpi.find(wk => wk.getIn(["kpi", "columnsKpiId"]) === oldColumnsKpiId)) {
          idToWrappedKpi = idToWrappedKpi.deleteIn([oldColumnsKpiId, "mirrorColor"]);
        }
        return idToWrappedKpi;
      }
    });
  }, []);

  const handleTest = React.useCallback((kpiId) => {
    setIdToWrappedKpi(idToWrappedKpi => {
      const wrappedKpi = idToWrappedKpi.get(kpiId);
      const kpi = wrappedKpi.get("kpi");
      const combinedKpi = wrappedKpi.get("combinedKpi");

      const columnsKpiId = kpi.get("columnsKpiId") || kpiId;

      const columns = idToWrappedKpi.get(columnsKpiId).get("columns");
      const testConfig = wrappedKpi.get("testConfig");
      const userId = testConfig.get("userId");
      const groupId = testConfig.get("groupId");
      const traceLevel = testConfig.get("traceLevel");
      const timeframe = TimeframeRepo.parse(testConfig.get("timeframe").toJS());

      const template = idToTemplate.get(kpi.get("templateId"));
      const modifiedKpis = getChangedKpisForTests(idToWrappedKpi);

      const storeResult = result => {
        setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.update(kpiId, wk => wk.set("testResult", result)));
      };

      const isTrendable = combinedKpi.get("overrideTrendable") === null
          ? template.get("trendable")
          : combinedKpi.get("overrideTrendable");

      testUserAndGroup(
          kpiId,
          wrappedKpi.get("isUnsaved") ? kpi.delete("id") : kpi,
          columns,
          timeframe,
          userId,
          groupId,
          template,
          modifiedKpis,
          isTrendable,
          traceLevel)
          .then(storeResult);
      auditor.audit("edit-metrics-admin:test-metric", {kpiIdsToTest: [kpiId]});
      return idToWrappedKpi.update(kpiId, wk => wk.set("testResult", Immutable.Map({loading: true})));
    });
  }, [idToTemplate]);

  const kpiProgressFn = () => {
    setTestAllProgress(currentProgress => currentProgress.update("kpisCompleted", kc => kc + 1));
  };

  const setupProgressTracking = wrappedKpisToTest => {
    setShowLoadingOverlay(true);
    setTestAllProgress(Immutable.Map({kpisCompleted: 0, totalKpisToTest: wrappedKpisToTest.count()}));
  };

  const abortTestsFn = React.useCallback(() => {
    return testsCancelled.current || unmounted.current;
  }, []);

  const finishTestingSync = React.useCallback(() => {
    setTestAllProgress(null);
    setExpandedKpiIds(Immutable.Set());
    setShowLoadingOverlay(false);
  }, [setTestAllProgress, setExpandedKpiIds, setShowLoadingOverlay]);

  const finishTesting = React.useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (unmounted.current) {
          return resolve();
        }
        finishTestingSync();
        setTimeout(resolve, 200);
      }, 600);
    });
  }, [finishTestingSync]);

  const formatWrappedKpisForSubmission = React.useCallback((wrappedKpisToUpsert, kpiIdToTestTimings) => {
    return wrappedKpisToUpsert.map(wk => {
      const testTimings = kpiIdToTestTimings.get(wk.getIn(["kpi", "id"]));
      const kpi = wk.get("isUnsaved") ? wk.get("kpi").delete("id") : wk.get("kpi");
      return Immutable.fromJS({config: kpi, columns: wk.get("columns"), testTimings});
    });
  }, []);

  const handleKpiSaveErrors = React.useCallback((changedWrappedKpis, kpisAndErrors, setIdToWrappedKpi) => {
    const wrappedKpisWithErrors = changedWrappedKpis
        .zipWith(
            (wrappedKpi, kpiAndError) => {
              const error = kpiAndError.get("error");
              if (error) {
                return wrappedKpi.set("submitError", error);
              } else {
                return wrappedKpi;
              }
            },
            kpisAndErrors)
        .filter(wrappedKpi => wrappedKpi.get("submitError"));

    setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.merge(indexBy(
        wk => wk.getIn(["kpi", "id"]),
        wrappedKpisWithErrors)));

    createPopupFromError(new Error("Unable to save, see errors"));
    return Promise.reject(new Error("Unable to save, see errors"));
  }, []);

  const handleKpiSaveSuccess = React.useCallback((changedWrappedKpis, kpisAndErrors, kpisUpdated, idToWrappedKpi, setIdToWrappedKpi, setOriginalIdToWrappedKpi, loadChangeSubmissions) => {
    const savedWrappedKpis = changedWrappedKpis
        .zipWith(
            (wrappedKpi, kpiAndError) => {
              const kpi = kpiAndError.get("config");
              return wrappedKpi
                  .set("kpi", kpi)
                  .set("combinedKpi", kpi.get("readOnlyCombined") ?? kpi)
                  .delete("isUnsaved")
                  .delete("changed")
                  .delete("testResult")
                  .delete("rootChanged")
                  .delete("requiresTest")
                  .delete("sendForTests")
                  .delete("columns")
                  .delete("columnsChanged")
                  .delete("requiresExplanationUpdate");
            },
            kpisAndErrors);

    const idToSavedWrappedKpis = indexBy(wk => wk.getIn(["kpi", "id"]), savedWrappedKpis);
    const newIdToWrappedKpi = idToWrappedKpi
        .filter(wrappedKpi => !wrappedKpi.get("isUnsaved"))
        .map(wrappedKpi => wrappedKpi
            .delete("testResult")
            .delete("requiresTest"))
        .merge(idToSavedWrappedKpis);

    setIdToWrappedKpi(newIdToWrappedKpi);

    if (kpisUpdated) {
      KpiRepo.reload();
      setOriginalIdToWrappedKpi(newIdToWrappedKpi);
      Popups.success("Changes have been saved");
    } else {
      Popups.success("Changes have been submitted pending approval");
    }

    auditor.audit("edit-metrics-admin:save-metric", {kpisUpdated});
    loadChangeSubmissions();
    return Promise.resolve();
  }, []);

  const processSaveResults = React.useCallback((results, idToWrappedKpi, setIdToWrappedKpi, setOriginalIdToWrappedKpi, loadChangeSubmissions) => {
    const [changedWrappedKpis, response] = results;
    const kpisUpdated = response.get("kpisUpdated");
    const kpisAndErrors = response.get("kpisAndErrors");
    const hasError = !!(kpisAndErrors.find(kpiAndError => kpiAndError.get("error")));

    if (hasError) {
      return handleKpiSaveErrors(changedWrappedKpis, kpisAndErrors, setIdToWrappedKpi);
    } else {
      return handleKpiSaveSuccess(
          changedWrappedKpis,
          kpisAndErrors,
          kpisUpdated,
          idToWrappedKpi,
          setIdToWrappedKpi,
          setOriginalIdToWrappedKpi,
          loadChangeSubmissions);
    }
  }, [handleKpiSaveErrors, handleKpiSaveSuccess]);

  const performSave = React.useCallback((wrappedKpisToUpsert, kpiIdToTestTimings, reason, idToWrappedKpi, setIdToWrappedKpi, setOriginalIdToWrappedKpi, loadChangeSubmissions, setShowLoadingOverlay, finishTestingSync, abortTestsFn) => {
    setShowLoadingOverlay(true);
    const formattedWrappedKpisToUpsert = formatWrappedKpisForSubmission(wrappedKpisToUpsert, kpiIdToTestTimings);

    return Promise.all([wrappedKpisToUpsert, Kpis.submitKpis(formattedWrappedKpisToUpsert, reason)])
        .then(results => processSaveResults(
            results,
            idToWrappedKpi,
            setIdToWrappedKpi,
            setOriginalIdToWrappedKpi,
            loadChangeSubmissions))
        .catch(error => {
          if (!abortTestsFn()) {
            createPopupFromError(error);
          }
        })
        .finally(() => {
          setShowLoadingOverlay(false);
          finishTestingSync();
        });
  }, [formatWrappedKpisForSubmission, processSaveResults]);

  const handleSaveDialogSubmit = React.useCallback((reason) => {
    setShowSaveReasonDialog(false);
    if (pendingSaveData) {
      const {wrappedKpisToUpsert, kpiIdToTestTimings} = pendingSaveData;
      return performSave(
          wrappedKpisToUpsert,
          kpiIdToTestTimings,
          reason,
          idToWrappedKpi,
          setIdToWrappedKpi,
          setOriginalIdToWrappedKpi,
          loadChangeSubmissions,
          setShowLoadingOverlay,
          finishTestingSync,
          abortTestsFn
      ).finally(() => {
        setPendingSaveData(null);
      });
    }
    setPendingSaveData(null);
    return Promise.resolve();
  }, [
    pendingSaveData,
    idToWrappedKpi,
    setIdToWrappedKpi,
    setOriginalIdToWrappedKpi,
    loadChangeSubmissions,
    setShowLoadingOverlay,
    finishTestingSync,
    abortTestsFn,
    performSave
  ]);

  const handleSaveDialogCancel = React.useCallback(() => {
    setShowSaveReasonDialog(false);
    setPendingSaveData(null);
    finishTestingSync();
    createPopupFromError(new Error("Save cancelled"));
  }, [finishTestingSync]);

  const handleSaveClick = React.useCallback(
      () => {
        let testPromise;
        if (userIsKpiEditor && isCube19User) {
          const wrappedKpisToTest = idToWrappedKpi
              .toList()
              .filter(wk => !wk.getIn(["kpi", "config"]))
              .filter(wk => wk.get("requiresTest"));
          wrappedKpisToTest.size > 0 && setupProgressTracking(wrappedKpisToTest);
          testsCancelled.current = false;
          testPromise = runTests(wrappedKpisToTest, idToWrappedKpi, idToTemplate, true, kpiProgressFn, abortTestsFn)
              .then(results => finishTesting().then(() => results));
        } else {
          testPromise = Promise.resolve(Immutable.List());
        }

        testPromise
            .then(testResults => {
              const processedTestResults = testResults.map(tr => tr.get(0)
                  .setIn(["group", "response", "concurrency"], tr.get(1)));
              const kpiIdToTestTimings = indexBy(tr => tr.get("kpiId"), processedTestResults)
                  .map(tr => tr
                      .delete("kpiId")
                      .mapKeys(userOrGroup => userOrGroup.toUpperCase())
                      .filter(userOrGroup => userOrGroup)
                      .map(userOrGroup => userOrGroup
                          .get("response")
                          .filter(test => test)
                          .mapKeys(test => test.toUpperCase())
                          .map(test => Immutable.Map({
                            timing: test.get("time"),
                            valueCount: test.getIn(["body", "values"]) ? test.getIn(["body", "values"]).size : null
                          }))));

              const failures = processedTestResults.filter(hasTestError);
              if (failures.isEmpty()) {
                if (isSwitchingUser || isCube19User) {
                  setPendingSaveData({
                    wrappedKpisToUpsert: idToWrappedKpi.toList().filter(wk => wk.get("changed") ||
                        wk.get("columnsChanged") ||
                        wk.get("requiresTest")),
                    kpiIdToTestTimings
                  });
                  setShowSaveReasonDialog(true);
                } else {
                  const reason = "";
                  const wrappedKpisToUpsert = idToWrappedKpi.toList().filter(wk => wk.get("changed") ||
                      wk.get("columnsChanged") ||
                      wk.get("requiresTest"));

                  setShowLoadingOverlay(true);
                  const formattedWrappedKpisToUpsert = formatWrappedKpisForSubmission(
                      wrappedKpisToUpsert,
                      kpiIdToTestTimings);
                  return Promise.all([wrappedKpisToUpsert, Kpis.submitKpis(formattedWrappedKpisToUpsert, reason)]);
                }
              } else {
                setIdToWrappedKpi(idToWrappedKpi => mergeIdToWrappedKpiWithResults(
                    idToWrappedKpi,
                    processedTestResults));
                return Promise.reject(new Error("No changes saved, test failures must be fixed first"));
              }
            })
            .then(results => {
              if (results) {
                return processSaveResults(
                    results,
                    idToWrappedKpi,
                    setIdToWrappedKpi,
                    setOriginalIdToWrappedKpi,
                    loadChangeSubmissions);
              }
            })
            .catch(error => {
              if (!abortTestsFn()) {
                createPopupFromError(error);
              }
            })
            .then(finishTestingSync);
      },
      [
        userIsKpiEditor,
        isCube19User,
        finishTestingSync,
        idToWrappedKpi,
        idToTemplate,
        abortTestsFn,
        finishTesting,
        isSwitchingUser,
        formatWrappedKpisForSubmission,
        processSaveResults,
        loadChangeSubmissions]
  );

  const handleTestAllClick = React.useCallback(() => {
    const wrappedKpisToTest = idToWrappedKpi.toList();
    setupProgressTracking(wrappedKpisToTest);
    testsCancelled.current = false;
    runTests(wrappedKpisToTest, idToWrappedKpi, idToTemplate, false, kpiProgressFn, abortTestsFn)
        .then(testResults => {
          //discard concurrency result
          testResults = testResults.map(testResult => testResult.get(0));
          const failures = testResults.filter(hasTestError);
          if (failures.isEmpty()) {
            return Popups.success("All tests have passed");
          } else {
            setIdToWrappedKpi(idToWrappedKpi => mergeIdToWrappedKpiWithResults(idToWrappedKpi, testResults));
            return Promise.reject(new Error(`${failures.count()} test(s) failed`));
          }
        })
        .then(finishTesting)
        .catch(error => {
          if (!abortTestsFn()) {
            createPopupFromError(error);
          }
          finishTestingSync();
        });
  }, [idToWrappedKpi, idToTemplate, abortTestsFn, finishTesting, finishTestingSync]);

  const handleResetClick = React.useCallback(() => {
    setExpandedKpiIds(Immutable.Set());
    setStoredChangeDateTime(null);
    loadAndSetData();
  }, [loadAndSetData]);

  const handleAddClick = () => setIsAddKpiOpen(true);

  const handleAddKpi = React.useCallback(
      (config) => {
        const tempId = Math.random() + "_" + Math.random();
        const newKpi = createNewWrappedKpi(tempId, config);
        handleSpecChange(newKpi, null);

        const updatedIdToWrappedKpi = idToWrappedKpi.set(tempId, newKpi);
        setIdToWrappedKpi(updatedIdToWrappedKpi);
        let entityLabelToExpand = getEntityLabelForKpi(newKpi, entityTypeToLabel);

        setExpandedEntityLabels(entityLabels => entityLabels.includes(entityLabelToExpand)
            ? entityLabels
            : entityLabels.add(entityLabelToExpand));
        setExpandedKpiIds(expandedKpiIds => expandedKpiIds.add(tempId));
        setIsAddKpiOpen(false);

        // 100ms is to give it some time to render
        setTimeout(() => {
          const element = document.getElementById(tempId);
          if (element) {
            element.scrollIntoView({behavior: "smooth"});
          }
        }, 100);
      },
      [handleSpecChange, idToWrappedKpi, entityTypeToLabel]);

  const handleAddCombinedKpi = React.useCallback((config) => {
    const name = config.get("name");
    const tempId = Math.random() + "_" + Math.random();
    const parentKpiId = config.get("combineWithKpiId") ||
        masterKpiTypeToKpiId.get(config.get("combineWithMasterMetricType"));
    const parentKpi = idToWrappedKpi.get(parentKpiId);
    const columnsKpiId = parentKpi.getIn(["kpi", "columnsKpiId"]) || (!parentKpi.get("isUnsaved")
        && parentKpi.getIn(["kpi", "id"]));

    const newKpi = createNewCombinedKpi(tempId, config, parentKpi);

    const shouldRetrieveColumns = !parentKpi.get("columns") && !parentKpi.get("isUnsaved");

    const updatedIdToWrappedKpi = idToWrappedKpi.set(tempId, newKpi);

    if (shouldRetrieveColumns) {
      loadColumnsForKpiId(updatedIdToWrappedKpi, columnsKpiId);
    }
    handleSpecChange(newKpi, null);

    const hasParent = !!getParentKpiId(newKpi, masterKpiTypeToKpiId);
    updateCombinations(
        hasParent ? Immutable.List([tempId]) : Immutable.List(),
        updatedIdToWrappedKpi);

    setIdToWrappedKpi(updatedIdToWrappedKpi);
    setExpandedKpiIds(expandedKpiIds => expandedKpiIds.add(tempId).delete(parentKpiId));

    setIsAddKpiOpen(false);

    auditor.audit("edit-metrics-admin:inherit-metric", {name});
  }, [masterKpiTypeToKpiId, idToWrappedKpi, loadColumnsForKpiId, updateCombinations, handleSpecChange]);

  const handleCloneKpi = React.useCallback(
      (parentKpiId, name) => {

        const tempId = Math.random() + "_" + Math.random();

        setIdToWrappedKpi(currentIdToWrappedKpi => {
          const wrappedParentKpi = currentIdToWrappedKpi.get(parentKpiId);
          const columnsKpiId = wrappedParentKpi.getIn(["kpi", "columnsKpiId"]) || (!wrappedParentKpi.get("isUnsaved")
              && parentKpiId);
          const kpiNames = currentIdToWrappedKpi.map(kpi => kpi.getIn(["kpi", "name"])).valueSeq().toList();
          const newName = name ? name : getUniqueName(wrappedParentKpi.getIn(["kpi", "name"]), kpiNames);
          let newWrappedKpi = wrappedParentKpi
              .setIn(["kpi", "id"], tempId)
              .setIn(["kpi", "name"], newName)
              .set("savedName", `${wrappedParentKpi.get("savedName")} <Copy>`)
              .setIn(["kpi", "trueName"], newName)
              .set("changed", true)
              .set("isUnsaved", true)
              .set("requiresTest", true)
              .set("testConfig", getDefaultTestConfig())
              .deleteIn(["kpi", "createdByOnboarding"])
              .delete("testResult");

          if (columnsKpiId) {
            newWrappedKpi = newWrappedKpi.setIn(["kpi", "columnsKpiId"], columnsKpiId);
          }

          const isParentUnmirrored = !wrappedParentKpi.getIn(["kpi", "columnsKpiId"])
              && !wrappedParentKpi.getIn(["mirrorColor"]);

          if (isParentUnmirrored && !wrappedParentKpi.get("isUnsaved")) {
            currentIdToWrappedKpi = assignMirrorColor(currentIdToWrappedKpi, parentKpiId);
          }

          const hasParent = !!getParentKpiId(newWrappedKpi.get("kpi"), masterKpiTypeToKpiId);
          if (!hasParent) {
            newWrappedKpi = newWrappedKpi
                .set("combinedKpi", newWrappedKpi.get("kpi"))
                .delete("combineError");
          }

          const shouldRetrieveColumns = !wrappedParentKpi.get("columns") && !wrappedParentKpi.get("isUnsaved");

          currentIdToWrappedKpi = currentIdToWrappedKpi.set(tempId, newWrappedKpi);

          if (shouldRetrieveColumns) {
            loadColumnsForKpiId(currentIdToWrappedKpi, columnsKpiId);
          }
          handleSpecChange(newWrappedKpi, wrappedParentKpi.get("kpi"));

          if (hasParent) {
            updateCombinations(
                Immutable.List([tempId]),
                currentIdToWrappedKpi
            );
          }

          return currentIdToWrappedKpi;
        });
        setExpandedKpiIds(expandedKpiIds => expandedKpiIds.add(tempId).delete(parentKpiId));
        setIsAddKpiOpen(false);

        auditor.audit("edit-metrics-admin:duplicate-metric", {name});
      },
      [loadColumnsForKpiId, masterKpiTypeToKpiId, updateCombinations, handleSpecChange]);

  const handleInheritClick = React.useCallback(parentId => {
    const config = Immutable.fromJS(
        {
          name: `Child of ${idToWrappedKpi.get(parentId).get("savedName")}`,
          combineWithKpiId: parentId
        }
    );
    handleAddCombinedKpi(config);
  }, [handleAddCombinedKpi, idToWrappedKpi]);

  const handleDuplicateClick = React.useCallback(parentId => {
    handleCloneKpi(parentId, `Copy of ${idToWrappedKpi.get(parentId).get("savedName")}`);
  }, [handleCloneKpi, idToWrappedKpi]);

  const handleAddKpiClose = () => {
    setIsAddKpiOpen(false);
  };

  const handleClearRequiresExplanationUpdate = React.useCallback(kpiId => {
    setIdToWrappedKpi(idToWrappedKpi => idToWrappedKpi.update(kpiId, wk => wk.set("requiresExplanationUpdate", false)));
  }, []);

  React.useEffect(() => {
    if (windowLocation) {
      window.location.hash = windowLocation;
      setWindowLocation(null);
    }
  }, [filterText, windowLocation]);

  const onNavigationClick = React.useCallback((kpiId, tabId, shouldToggle) => {
    setIdToWrappedKpi(idToWrappedKpi => {

      const kpi = idToWrappedKpi.getIn([kpiId, "kpi"]);

      if (!shouldToggle && !kpi.get("deleted")) {
        const currentHashLocation = window.location.hash.split("#")[1];
        if (parseInt(currentHashLocation) === kpiId) {
          window.location.hash = "";
        }
        setFilterText(filterText => {
          if (filterText.length > 0) {
            setWindowLocation(kpiId);
            return "";
          } else {
            window.location.hash = kpiId;
            return filterText;
          }
        });
      }

      if (kpi.get("deleted")) {
        return idToWrappedKpi;
      } else {
        setExpandedKpiIds(expandedKpiIds => {
          const currentTabId = idToWrappedKpi.getIn([kpiId, "tabId"]);
          if (!expandedKpiIds.includes(kpiId)) {
            return expandedKpiIds.add(kpiId);
          } else if (tabId === currentTabId && shouldToggle) {
            return expandedKpiIds.delete(kpiId);
          } else {
            return expandedKpiIds;
          }
        });

        const kpiIdForColumns = kpi && (kpi.get("columnsKpiId") || kpi.get("id"));
        kpiIdForColumns && loadColumnsForKpiId(idToWrappedKpi, kpiIdForColumns);
        loadAvailableOwnersForKpiId(idToWrappedKpi, kpi.get("id"));
        loadAvailableServerColumnsForKpiId(idToWrappedKpi, kpi.get("id"));


        const entityLabelToExpand = getEntityLabelForKpi(kpi, entityTypeToLabel);
        setExpandedEntityLabels(entityLabels => entityLabels.includes(entityLabelToExpand)
            ? entityLabels
            : entityLabels.add(entityLabelToExpand));

        return idToWrappedKpi.setIn([kpiId, "tabId"], tabId);
      }
    });
  }, [loadColumnsForKpiId, loadAvailableOwnersForKpiId, loadAvailableServerColumnsForKpiId, entityTypeToLabel]);

  const kpiIdToDependentKpis = useCalculatedImmutableState(() => {
    let idToDependencies = Immutable.Map();
    const combinationDependencies = idToWrappedKpi
        .filter(k => getParentKpiId(k.get("kpi"), masterKpiTypeToKpiId))
        .groupBy(k => getParentKpiId(k.get("kpi"), masterKpiTypeToKpiId))
        .map(children => children
            .valueSeq()
            .toSet()
            .map(child => {
              return Immutable.Map({
                id: child.getIn(["kpi", "id"]),
                name: `${child.getIn(["kpi", "name"])}${child.getIn(["kpi", "deleted"]) ? " (deleted)" : ""}`,
                dependencyType: "COMBINATION",
                combineType: child.getIn(["kpi", "combineType"])
              });
            }));

    if (!combinationDependencies.isEmpty()) {
      idToDependencies = idToDependencies.mergeWith((oldVal, newVal) => oldVal.concat(newVal), combinationDependencies);
    }

    const simpleSumKpis = idToWrappedKpi
        .filter(k => getQueryType(k.get("kpi"), idToTemplate) === "SIMPLE_SUM");
    const simpleSumDependencies = getKpiIdToQueryTypeDependencies(simpleSumKpis, masterKpiTypeToKpiId, idToTemplate)
        .map(dependencies => dependencies.map(dependency => Immutable.Map({
              id: dependency.get("id"),
              name: `${dependency.get("name")}${dependency.get("deleted") ? " (deleted)" : ""}`,
              dependencyType: "SIMPLE_SUM"
            })).toSet()
        );

    if (!simpleSumDependencies.isEmpty()) {
      idToDependencies = idToDependencies.mergeWith((oldVal, newVal) => oldVal.concat(newVal), simpleSumDependencies);
    }

    const forwardReportKpis = idToWrappedKpi
        .filter(k => getQueryType(k.get("kpi"), idToTemplate) === "FORWARD_REPORT");
    const forwardReportDependencies = getKpiIdToQueryTypeDependencies(
        forwardReportKpis,
        masterKpiTypeToKpiId,
        idToTemplate)
        .map(dependencies => dependencies.map(dependency => Immutable.Map({
              id: dependency.get("id"),
              name: `${dependency.get("name")}${dependency.get("deleted") ? " (deleted)" : ""}`,
              dependencyType: "FORWARD_REPORT"
            })).toSet()
        );

    if (!forwardReportDependencies.isEmpty()) {
      idToDependencies =
          idToDependencies.mergeWith((oldVal, newVal) => oldVal.concat(newVal), forwardReportDependencies);
    }

    return idToDependencies;
  }, [idToWrappedKpi, masterKpiTypeToKpiId, idToTemplate]);

  const kpiIdToContributingKpis = useCalculatedImmutableState(() => kpiIdToDependentKpis
      .map((dependentKpis, kpiId) => dependentKpis.map(kpi => kpi
          .mapKeys(k => {
            if (k === "id") {
              return "dependentId";
            }
            return k;
          })
          .set("id", kpiId)
          .set(
              "name",
              `${idToWrappedKpi.getIn([kpiId, "kpi", "name"])}${idToWrappedKpi.getIn([kpiId, "kpi", "deleted"])
                  ? " (deleted)"
                  : ""}`)
      ))
      .valueSeq()
      .flatMap(kpis => kpis)
      .toSet()
      .groupBy(kpi => kpi.get("dependentId")), [kpiIdToDependentKpis]);

  const handleRejectSubmission = React.useCallback((submissionId, reason) => {
    setLoadingChangeSubmissions(true);
    rejectSubmission(submissionId, reason)
        .then(
            () => {
              loadChangeSubmissions();
            },
            error => {
              setLoadingChangeSubmissions(false);
              createPopupFromError(error);
            });
  }, [loadChangeSubmissions]);

  const handleRunSubmission = React.useCallback((submissionId) => {
    setLoadingChangeSubmissions(true);
    runSubmission(submissionId)
        .then(
            submission => {
              loadChangeSubmissions();
              if (submission.get("status") === "SUCCEEDED") {
                handleResetClick();
              } else {
                Popups.error("Failed to run submission");
              }
            },
            error => {
              setLoadingChangeSubmissions(false);
              createPopupFromError(error);
            });
  }, [handleResetClick, loadChangeSubmissions]);

  const handleLoadIntoEditor = React.useCallback((idToWrappedKpi, newKpis) => {
    const newWrappedKpis = newKpis.map(kpi => {
      let config = kpi.get("config");
      const wasNew = !config.get("id");
      let isUnsaved;
      if (wasNew) {
        const name = config.get("name");
        const oldWrappedKpi = idToWrappedKpi.find(wrappedKpi => wrappedKpi.getIn(["kpi", "name"]) === name);
        if (oldWrappedKpi) {
          isUnsaved = false;
          config = config.set("id", oldWrappedKpi.getIn(["kpi", "id"]));
        } else {
          isUnsaved = true;
          config = config.set("id", Math.random() + "_" + Math.random());
        }
      } else {
        isUnsaved = false;
      }
      const columns = kpi.get("columns");
      const requiresTest = isUnsaved || !Immutable.is(
          config.filter((v, k) => !keysNotRequiringTest.has(k)),
          idToWrappedKpi.getIn([config.get("id"), "kpi"]).filter((v, k) => !keysNotRequiringTest.has(k)));

      let wrappedKpi = Immutable.Map({
        kpi: config,
        combinedKpi: config,
        changed: true,
        requiresTest: requiresTest,
        sendForTests: !isUnsaved && requiresTest,
        testConfig: getDefaultTestConfig(),
        isUnsaved: isUnsaved
      });
      if (columns) {
        wrappedKpi = wrappedKpi.set("columns", columns);
      }
      return wrappedKpi;
    });
    const newIdToWrappedKpi = indexBy(wk => wk.getIn(["kpi", "id"]), newWrappedKpis);
    idToWrappedKpi = idToWrappedKpi.merge(newIdToWrappedKpi);

    const existingKpiToColour = idToWrappedKpi
        .map(wk => wk.get("mirrorColor"))
        .filter(color => !!color);
    const kpiIdToColor = generateKpiIdToColor(idToWrappedKpi
        .valueSeq()
        .filter(wk => !wk.get("isUnsaved"))
        .map(wk => wk.get("kpi")), existingKpiToColour);
    const sortedKpiIds = sortWrappedKpis(idToWrappedKpi);
    idToWrappedKpi = idToWrappedKpi.withMutations(idToWrappedKpi => {
      sortedKpiIds.forEach(id => {
        idToWrappedKpi.setIn([id, "mirrorColor"], kpiIdToColor.get(id));
      });
    });
    setIdToWrappedKpi(idToWrappedKpi);

    const requiresTestIds = Immutable.Set(newWrappedKpis.filter(wk => wk.get("requiresTest"))
        .map(wk => wk.getIn(["kpi", "id"])));
    const combineDependencies = findCombinationReferencesToKpiIds(
        idToWrappedKpi,
        masterKpiTypeToKpiId,
        requiresTestIds);
    updateCombinations(requiresTestIds.union(combineDependencies), idToWrappedKpi);
    setShowChangeSubmissionsDrawer(false);
    setExpandedKpiIds(Immutable.Set());
  }, [masterKpiTypeToKpiId, updateCombinations]);

  const getOverlayContent = testAllProgress => {
    const kpiProgress = Math.floor((testAllProgress.get("kpisCompleted") / testAllProgress.get("totalKpisToTest"))
        * 100);
    return <div
        style={{
          padding: 20,
          width: 300,
          color: theme.palette.textColor,
          backgroundColor: theme.themeId === "light" ? "#d3d3d3" : Colors.greyLight,
          borderRadius: 5
        }}
        data-test-id="metric-admin-testing-dialog">
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <h3>Running Tests...</h3>
        <div style={{marginBottom: 10}}>Metrics completed: {testAllProgress.get("kpisCompleted")
            + "/"
            + testAllProgress.get("totalKpisToTest")}</div>
      </div>
      <LinearProgress
          variant="determinate"
          value={kpiProgress} />
      <TextButton style={{marginTop: 10}} label="Cancel" onClick={() => testsCancelled.current = true} />
    </div>;
  };

  const anyMetricHasDoubledSplits = React.useMemo(() => {
    const kpis = idToWrappedKpi.valueSeq().map(kpi => kpi.get("kpi")).toList();
    const acceptedTypes = ["DEALING_WITH_DOUBLED_SPLITS_FOR_PLACEMENTS", "DEALING_WITH_DOUBLED_SPLITS_FOR_SALES"];
    return kpis
        .filter(k => !!k.get("config"))
        .some(k => acceptedTypes.includes(k.get("config").get("aggregationType")));
  }, [idToWrappedKpi]);

  const anyMetricHasIdForSumSplit = React.useMemo(() => {
    const kpis = idToWrappedKpi.valueSeq().map(kpi => kpi.get("kpi")).toList();
    const acceptedTypes = ["ID_FOR_SPLIT_SUM"];
    return kpis
        .filter(k => !!k.get("config"))
        .some(k => acceptedTypes.includes(k.getIn(["config", "uniqueColumn", "name"])));
  }, [idToWrappedKpi]);

  const renderEntity = (entityType, label, kpiIds, index, renderIfEmpty) => {
    if (kpiIds.isEmpty() && !renderIfEmpty) {
      return <div key={`entity-${label}-${index}`} />;
    }

    if (label) {
      return <EditKpiEntity
          entityTypeToLabel={entityTypeToLabel}
          entityExpanded={expandedEntityLabels.includes(label)}
          onExpandEntityClick={handleExpandEntityClick}
          key={`entity-${label}-${index}`}
          index={index}
          isEditor={userIsKpiEditor}
          entityType={entityType}
          entityLabel={label}
          kpiIds={kpiIds}
          isCube19User={isCube19User}
          clientHasEdit={clientHasEdit}
          idToWrappedKpi={idToWrappedKpi}
          rootEntityToCombinedKpis={rootEntityToCombinedKpis}
          getParentWrappedKpi={getParentWrappedKpi}
          masterKpiTypeToKpiId={masterKpiTypeToKpiId}
          columnsKpiIdToKpis={columnsKpiIdToKpis}
          nameToCount={nameToCount}
          actionTypes={actionTypes}
          idToTemplate={idToTemplate}
          typeToGroupingEntity={typeToGroupingEntity}
          expandedKpiIds={expandedKpiIds}
          idToEntityColumn={idToEntityColumn}
          onKpiChange={handleKpiChange}
          onTestConfigChange={handleTestConfigChange}
          onTest={handleTest}
          onColumnsChange={handleColumnsChange}
          onUnmirror={handleUnmirror}
          onExpandClick={handleExpandClick}
          kpiIdToDependentKpis={kpiIdToDependentKpis}
          kpiIdToContributingKpis={kpiIdToContributingKpis}
          kpiIdToMasterKpis={kpiIdToMasterKpis}
          onNavigationClick={onNavigationClick}
          handleDuplicateClick={handleDuplicateClick}
          handleInheritClick={handleInheritClick}
          onRevertToLegacyFormat={setKpiIdToRevertToLegacyFormat}
          onDisableMetricToggle={handleDisableMetricToggle}
          onDeleteMetric={handleDeleteMetric}
          onChangeTab={handleChangeTab}
          onClearRequiresExplanationUpdate={handleClearRequiresExplanationUpdate}
          filterText={filterText}
          searchFocused={searchFocused}
          showOnlyFailingKpis={showOnlyFailingKpis}
          hasTestError={hasTestError}
          showOnlyChangedKpis={showOnlyChangedKpis}
          sumDoubleSplitsAvailable={anyMetricHasDoubledSplits}
          sumSplitAvailable={anyMetricHasIdForSumSplit}
          aggregationTypes={aggregationTypes}
          trendTypes={trendTypes}
      />;
    } else {
      return <div key={`empty-entity-${index}`} />;
    }
  };

  //TODO - we know the entity of the new kpi, we should be able to remove this
  React.useEffect(() => {
    // Note: this expands the entity group if it has a new KPI
    // This is a workaround because handleAddMetric doesn't have readonlyRootGroupEntity defined so we cant expand the
    // entity there Need to look into why
    const unsavedKpis = idToWrappedKpi.filter(wk => wk.get("isUnsaved"));
    unsavedKpis.count() > 0
    && unsavedKpis.map(uk => setExpandedEntityLabels(expandedEntityLabels => expandedEntityLabels.add(entityTypeToLabel.get(
        uk.getIn(["combinedKpi", "readOnlyRootGroupingEntity"])))));
  }, [entityTypeToLabel, idToWrappedKpi]);

  const otherEntityList = renderEntity(null, "Other", NoRootEntityToFilteredKpiIds, undefined, false);

  const simpleSumList = renderEntity(
      null,
      "Simple Sum",
      filteredSimpleSumKpiIds,
      undefined,
      false);

  const allRootEntities = entityTypeToLabel
      .delete("PLACEMENT_SPLIT")
      .delete("USER")
      .entrySeq()
      .sort(([_1, label1], [_2, label2]) => label1.localeCompare(label2))
      .map(([entityType, label], index) => {
        const kpiIds = rootEntityToFilteredKpiId.get(label) || Immutable.List();
        return renderEntity(entityType, label, kpiIds, index, (!(showOnlyEditableKpis
            || showOnlyFailingKpis
            || showOnlyChangedKpis)));
      });

  const handleCollapseAll = React.useCallback(() => {
    setExpandedEntityLabels(Immutable.Set());
  }, []);

  const handleExpandAll = React.useCallback(() => {
    const allLabels = entityTypeToLabel
        .delete("PLACEMENT_SPLIT")
        .delete("USER")
        .toSet();
    setExpandedEntityLabels(allLabels);
  }, [entityTypeToLabel]);

  const totalLabelCount = entityTypeToLabel.delete("PLACEMENT_SPLIT").delete("USER").count();

  return (
      <div>
        {showLoadingOverlay && <Overlay content={testAllProgress && getOverlayContent(testAllProgress)} />}
        {showSaveReasonDialog && <SaveReasonDialog
            onSubmit={handleSaveDialogSubmit}
            onCancel={handleSaveDialogCancel} />}
        <AddKpiDialog
            idToTemplate={idToTemplate}
            handleAddKpiClose={handleAddKpiClose}
            handleAddKpi={handleAddKpi}
            kpiIdToMasterKpis={kpiIdToMasterKpis}
            typeToGroupingEntity={typeToGroupingEntity}
            entityTypeToLabel={entityTypeToLabel}
            idToWrappedKpi={idToWrappedKpi}
            isOpen={isAddKpiOpen}
            handleClose={handleAddKpiClose}
            masterKpiTypeToKpiId={masterKpiTypeToKpiId}
        />
        <Drawer
            key="change-submissions-drawer"
            wideWidth={true}
            open={showChangeSubmissionsDrawer}
            onRequestClose={() => setShowChangeSubmissionsDrawer(false)}>
          <ChangeSubmissionsMenu
              submissions={changeSubmissions}
              loading={loadingChangeSubmissions}
              onRefreshClick={loadChangeSubmissions}
              onCloseClick={() => setShowChangeSubmissionsDrawer(false)}
              submissionRenderer={submission =>
                  <KpiChangeSubmission
                      submission={submission}
                      idToTemplate={idToTemplate}
                      idToWrappedKpi={originalIdToWrappedKpi}
                      onReject={handleRejectSubmission}
                      onRun={handleRunSubmission}
                      entityTypeToLabel={entityTypeToLabel}
                      onLoadIntoEditor={handleLoadIntoEditor} />} />
        </Drawer>
        <UnsavedChangesDialog
            onRetrieveClick={handleRetrieveStoredChanges}
            onDiscardClick={handleDiscardStoredChanges}
            lastUpdated={storedChangeDateTime && storedChangeDateTime.format("LLL")}
            open={!loading && !!storedChangeDateTime} />
        {kpiIdToRevertToLegacyFormat && <RevertToLegacyFormatDialog
            idToWrappedKpi={idToWrappedKpi}
            masterKpiTypeToKpiId={masterKpiTypeToKpiId}
            kpiId={kpiIdToRevertToLegacyFormat}
            onRevert={(wrappedKpi) => {
              setKpiIdToRevertToLegacyFormat(null);
              handleRevertToLegacyFormat(wrappedKpi);
            }}
            onCancel={() => setKpiIdToRevertToLegacyFormat(null)} />}
        {kpiIdToDisable && <DisableMetricDialog
            idToWrappedKpi={idToWrappedKpi}
            masterKpiTypeToKpiId={masterKpiTypeToKpiId}
            kpiId={kpiIdToDisable}
            onDisable={(wrappedKpi) => {
              const kpi = wrappedKpi.get("kpi");
              const allDependentKpis = kpiIdToDependentKpis.get(kpi.get("id"), Immutable.List());
              const dependencyIds = allDependentKpis.map(kpi => kpi.get("id"));
              handleKpiChange(kpi.set("enabled", "false"), dependencyIds);
              setKpiIdToDisable(null);
            }}
            onCancel={() => setKpiIdToDisable(null)} />}
        {kpiIdToDelete && <DeleteMetricDialog
            idToWrappedKpi={idToWrappedKpi}
            masterKpiTypeToKpiId={masterKpiTypeToKpiId}
            kpiId={kpiIdToDelete}
            onDelete={(wrappedKpi) => {
              const kpi = wrappedKpi.get("kpi");
              const allDependentKpis = kpiIdToDependentKpis.get(kpi.get("id"), Immutable.List());
              const dependencyIds = allDependentKpis.map(kpi => kpi.get("id"));
              handleKpiChange(kpi.set("deleted", "true"), dependencyIds);
              setKpiIdToDelete(null);
              auditor.audit("edit-metrics-admin:deleted", {id: kpi.get("id"), name: kpi.get("name")});
            }}
            onCancel={() => setKpiIdToDelete(null)} />}
        <div style={{display: "flex", marginBottom: "1.5rem", padding: "0.75rem 1rem 0 1rem", flexWrap: "wrap"}}>
          <div style={{display: "flex", justifyContent: "center"}}>
            <div data-test-id="search-metrics">
              <DelayedTextField
                  variant="standard"
                  style={{width: 300, marginRight: "0.5rem"}}
                  label="Search Metrics..."
                  value={filterText}
                  onBlur={() => setSearchFocused(false)}
                  onChange={(value) => {
                    setFilterText(value);
                    setSearchFocused(true);
                  }} />
            </div>
          </div>
          <div
              style={{
                display: "flex",
                flexGrow: 1,
                marginLeft: "2rem",
                justifyContent: "flex-end",
                alignItems: "center"
              }}>
            <div>
              <TextButton
                  icon="history"
                  label="Cancel"
                  onClick={handleResetClick}
                  disabled={!hasChanges}
                  style={{margin: "0.5rem"}} />
              <TextButton
                  type={(!hasChanges || hasValidationErrors) ? "default" : "primary"}
                  icon="floppy-o"
                  label={userIsKpiEditor && isCube19User ? "Test And Save" : "Save"}
                  onClick={handleSaveClick}
                  disabled={!hasChanges || hasValidationErrors}
                  style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} />
              {(userIsKpiEditor && isCube19User) && <TextButton
                  icon="tasks"
                  label="Test All"
                  onClick={handleTestAllClick}
                  style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} />}
              {(clientHasEdit || (userIsKpiEditor && isCube19User)) && <TextButton
                  icon="plus"
                  label="Add Metric"
                  onClick={handleAddClick} //use handleAddClick on dialog
                  style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} />}
              {(userIsKpiEditor && isCube19User) && <TextButton
                  icon="file"
                  label="Import / Export"
                  onClick={() => setShowImportExportDialog(true)}
                  style={{display: "none", marginLeft: "0.5rem", marginRight: "0.5rem"}} />}
              {(userIsKpiEditor && isCube19User) && <TextButton
                  icon="clock"
                  label="Change Submissions"
                  onClick={() => setShowChangeSubmissionsDrawer(true)}
                  style={{margin: "0.5rem"}} />}
            </div>
          </div>
        </div>
        <div style={{display: "flex", padding: "0 1rem"}}>
          <div style={{display: "flex", width: "70%", flexGrow: 1}}>
            {(clientHasEdit || (userIsKpiEditor && isCube19User)) &&
                <div className="TESTCAFE-editable-metrics-checkbox">
                  <Checkbox
                      style={{marginRight: "1rem"}}
                      label="Only Editable Metrics"
                      value={showOnlyEditableKpis}
                      onCheck={(e, isChecked) => setShowOnlyEditableKpis(isChecked)} />
                </div>
            }
            {(userIsKpiEditor && isCube19User) &&
                <div className="TESTCAFE-failing-metrics-checkbox">
                  <Checkbox
                      style={{marginRight: "1rem"}}
                      label="Only Failing Metrics"
                      value={showOnlyFailingKpis}
                      onCheck={(e, isChecked) => setShowOnlyFailingKpis(isChecked)} />
                </div>
            }
            {(clientHasEdit || (userIsKpiEditor && isCube19User)) &&
                <div className="TESTCAFE-changed-metrics-checkbox">
                  <Checkbox
                      style={{marginRight: "1rem"}}
                      label="Only Changed Metrics"
                      value={showOnlyChangedKpis}
                      onCheck={(e, isChecked) => setShowOnlyChangedKpis(isChecked)} />
                </div>}
          </div>
          <div style={{display: filterText ? "none" : "flex", textAlign: "right"}}>
            <span
                style={{
                  fontSize: 11,
                  textTransform: "uppercase",
                  marginRight: 20,
                  opacity: expandedEntityLabels.isEmpty() ? "0.6" : "1",
                  cursor: expandedEntityLabels.isEmpty() ? "not-allowed" : "pointer",
                  color: expandedEntityLabels.isEmpty() ? theme.palette.text.main : theme.palette.primary.main
                }} onClick={handleCollapseAll}>
              <i className="bhi-sort-asc" style={{marginRight: 5}} />
              Collapse all
            </span>
            <span
                style={{
                  fontSize: 11,
                  textTransform: "uppercase",
                  opacity: expandedEntityLabels.count() === totalLabelCount ? "0.6" : "1",
                  cursor: expandedEntityLabels.count() === totalLabelCount ? "not-allowed" : "pointer",
                  color: expandedEntityLabels.count() === totalLabelCount
                      ? theme.palette.text.main
                      : theme.palette.primary.main
                }}
                onClick={handleExpandAll}>
              <i className="bhi-sort-desc" style={{marginRight: 5}} />
              Expand all
            </span>
          </div>
        </div>

        <MultiMetricImportExportDialog
            open={showImportExportDialog}
            onClose={() => setShowImportExportDialog(false)}
            idToWrappedKpi={idToWrappedKpi}
            masterKpiTypeToKpiId={Immutable.Map()} />

        <div style={{padding: "0.5rem"}}>
          <ErrorsPanel
              idToWrappedKpi={idToWrappedKpi}
              nameToCount={nameToCount}
              onKpiClick={onNavigationClick}
          />
        </div>
        {loading
            ? <LoadingSpinner />
            : <div>
              <div style={{position: "relative"}}>
                {allRootEntities}
                {otherEntityList}
                {simpleSumList}
              </div>
            </div>}
      </div>);
});

const createPopupFromError = error => {
  if (error && error.responseJSON && error.responseJSON.type === "MISSING_TOKEN") {
    const title = "Missing Admin Console Token";
    const message = "In order to create or modify change submissions you need to login from admin console and have " +
        "CALL_ADMIN_CONSOLE_FROM_APP permission on your admin console user.";
    Popups.alert(message, {title});
  } else {
    const localErrorMessage = error && error.message;
    const remoteErrorMessage = error && error.responseJSON
        && error.responseJSON.type !== "UNCAUGHT_ERROR" && error.responseJSON.message;
    const message = localErrorMessage || remoteErrorMessage;
    if (message) {
      Popups.error(message);
    } else {
      Popups.contactSupport();
    }
  }
};

const mergeIdToWrappedKpiWithResults = (idToWrappedKpi, testResults) => {
  const kpiIdToTestResult = indexBy(testResult => testResult.get("kpiId"), testResults);
  return idToWrappedKpi.mergeWith(
      (wrappedKpi, testResult) => wrappedKpi.set("testResult", testResult),
      kpiIdToTestResult);
};

const ErrorsPanel = React.memo(({
  idToWrappedKpi,
  nameToCount,
  onKpiClick
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [viewAll, setViewAll] = React.useState(false);
  const cutOffAfter = 3;
  const wrappedKpisWithIssues = sortWrappedKpis(idToWrappedKpi)
      .map(kpiId => idToWrappedKpi.get(kpiId))
      .filter(wk => !!wk)
      .map((wk) => wk.set("issues", getIssuesWithWrappedKpi(wk, nameToCount)))
      .filter(wk => !wk.get("issues").isEmpty());

  if (wrappedKpisWithIssues.isEmpty()) {
    return null;
  } else {
    const errorsCount = wrappedKpisWithIssues.count();
    const showViewMoreLessToggle = errorsCount > cutOffAfter;
    let limitedWrappedKpisWithIssues;
    if (viewAll) {
      limitedWrappedKpisWithIssues = wrappedKpisWithIssues;
    } else {
      limitedWrappedKpisWithIssues = wrappedKpisWithIssues.take(cutOffAfter);
    }
    const style = theme.themeId === "light" ? {
          backgroundColor: theme.palette.danger.background,
          border: "1px solid " + theme.palette.danger.border,
          color: theme.palette.danger.color,
          fontWeight: "normal"
        } :
        {};
    const anchorStyle = theme.themeId === "light" ? {color: theme.palette.primary.main} : {};

    return (
        <ErrorMsg
            text={
              <>
                <p>You are unable to save due to these errors. Please fix in order to continue</p>
                <ol>
                  {limitedWrappedKpisWithIssues.map(wk => {
                    const kpi = wk.get("kpi");
                    const label = !kpi.get("name") ? "Unnamed" : kpi.get("name");
                    return <li key={kpi.get("id")}>
                      <a
                          onClick={() => {
                            onKpiClick(kpi.get("id"));
                          }}
                          style={anchorStyle}
                          href={`#${wk.getIn(["kpi", "id"])}`}>{label}</a>
                      {` - ${wk.get("issues").join(", ")}`}
                    </li>;
                  })}
                </ol>
                {!!showViewMoreLessToggle &&
                    <a
                        onClick={() => setViewAll(viewAll => !viewAll)}
                        style={anchorStyle}>
                      {viewAll ? "View Less" : "View More"}
                    </a>}
              </>
            }
            style={style}
        />);
  }
});

const loadEntities = () => Ajax
    .get({url: "data-explorer/entities"})
    .then(res => Immutable.fromJS(res));

const loadEntityColumns = () => Ajax
    .get({url: "entity-columns"})
    .then(res => Immutable.fromJS(res));

const loadSubmissions = () => Ajax
    .get({url: "kpi/change-submissions"})
    .then(res => Immutable.fromJS(res));

const rejectSubmission = (submissionId, comment) => Ajax.put(
    {
      url: "kpi/change-submissions/" + submissionId + "/reject",
      data: comment,
      contentType: "application/json"
    }
);

const runSubmission = (submissionId) => Ajax
    .put({url: "kpi/change-submissions/" + submissionId + "/run"})
    .then(res => Immutable.fromJS(res));

const testUserAndGroup = (kpiId, kpi, columns, timeframe, userId, groupId, template, modifiedKpis, isTrendable, traceLevel, abortTestsFn) => {
  return limit(() => testAction(KpiCalculator.testSummary, kpi, columns, {
    groupId,
    timeframe
  }, template, modifiedKpis))
      .then(() => Promise
          .all([
            userId && testAllActions(
                kpi,
                columns,
                {userId, timeframe, returnXhr: true, trace: traceLevel},
                template,
                modifiedKpis,
                isTrendable,
                abortTestsFn),
            testAllActions(
                kpi,
                columns,
                {groupId, timeframe, returnXhr: true, trace: traceLevel},
                template,
                modifiedKpis,
                isTrendable,
                abortTestsFn)
          ]))
      .then(([userResponse, groupResponse]) => {
        const sections = ["leaderboard", "report", "summary", "trend"];
        let userResult;
        if (userId) {
          const user = Users.getUser(userId);
          sections.forEach(section => {
            const xhrUser = userResponse[section] && (userResponse[section].xhr || userResponse[section].error);
            if (xhrUser && xhrUser.getResponseHeader) {
              userResponse[section].traceId = xhrUser.getResponseHeader("x-cube19-trace-id");
              delete userResponse[section].xhr;
            }
          });
          userResult = {
            userId,
            groupId: user.get("groupId"),
            name: user.get("fullName"),
            timeframe: timeframe.getRawJson(),
            valueFormat: kpi.get("valueFormat"),
            template,
            response: userResponse
          };
        }

        const group = Groups.getGroup(groupId);
        sections.forEach(section => {
          const xhrGroup = groupResponse[section] && (groupResponse[section].xhr || groupResponse[section].error);
          if (xhrGroup && xhrGroup.getResponseHeader) {
            groupResponse[section].traceId = xhrGroup.getResponseHeader("x-cube19-trace-id");
            delete groupResponse[section].xhr;
          }
        });

        return Immutable.fromJS({
          kpiId,
          user: userResult,
          group: {
            groupId,
            name: group.get("name"),
            timeframe: timeframe.getRawJson(),
            valueFormat: kpi.get("valueFormat"),
            template,
            response: groupResponse
          }
        });
      });
};

const testAllActions = (kpi, columns, options, template, modifiedKpis, isTrendable, abortTestsFn = () => {
}) => {
  const test = actionFn => limit(() => {
    if (abortTestsFn()) {
      return Promise.reject(new Error("Tests cancelled"));
    }
    return testAction(actionFn, kpi, columns, options, template, modifiedKpis)
        .then(result => {
          if (abortTestsFn()) {
            return Promise.reject(new Error("Tests cancelled"));
          }
          return result;
        });
  });
  return Promise.all([
    test(KpiCalculator.testSummary),
    test(KpiCalculator.testReport),
    isTrendable
        ? test(KpiCalculator.testTrend)
        : Promise.resolve(null),
    test(KpiCalculator.testLeaderboard)
  ]).then(([summary, report, trend, leaderboard]) => ({
    summary,
    report,
    trend,
    leaderboard
  }));
};

const testAction = (actionFn, kpi, columns, options, template, modifiedKpis) => {
  try {
    const start = new Date().getTime();
    return actionFn(kpi, columns, options, template, modifiedKpis)
        .then(result => {
          const end = new Date().getTime();
          result.time = end - start;
          return Promise.resolve(result);
        })
        .catch(error => ({error}));
  } catch (e) {
    return Promise.resolve({error: {message: e.message}});
  }
};

const testConcurrency = (kpi, columns, groupId, timeframe, template, modifiedKpis, abortTestsFn = () => {
}) => limit(() => {
  if (abortTestsFn()) {
    return Promise.reject(new Error("Tests cancelled"));
  }
  const numberOfTests = 5;
  let concurrencyPromises = [];
  const options = {groupId, timeframe, returnXhr: true, trace: "NONE"};
  const start = new Date().getTime();
  for (let i = 0; i < numberOfTests; i++) {
    concurrencyPromises.push(testAction(KpiCalculator.testSummary, kpi, columns, options, template, modifiedKpis));
  }

  return Promise.all(concurrencyPromises).then(results => {
    if (abortTestsFn()) {
      return Promise.reject(new Error("Tests cancelled"));
    }
    const end = new Date().getTime();
    const time = end - start;
    if (results.some(result => result.error)) {
      return {
        error: results.map(result => result.error).filter(x => x),
        time
      };
    } else {
      return {time};
    }
  });
});

const runTests = (wrappedKpisToTest, idToWrappedKpi, idToTemplate, runConcurrencyTest, kpiProgressFn, abortTestsFn) => {
  const testConfig = getDefaultTestConfig();
  const testUserId = testConfig.get("userId");
  const testGroupId = testConfig.get("groupId");
  const testTraceLevel = testConfig.get("traceLevel");
  const testTimeframe = TimeframeRepo.get("last_30_days");
  const modifiedKpis = getChangedKpisForTests(idToWrappedKpi);

  const kpiIdsToTest = wrappedKpisToTest.map(kpi => kpi.getIn(["kpi", "id"]));
  auditor.audit("edit-metrics-admin:test-metric", {kpiIdsToTest});

  const testPromises = wrappedKpisToTest.map(wk => {
    const kpiId = wk.getIn(["kpi", "id"]);
    const kpiToTest = wk.get("isUnsaved") ? wk.get("kpi").delete("id") : wk.get("kpi");
    const columns = wk.get("columns") || idToWrappedKpi.getIn([kpiToTest.get("columnsKpiId"), "columns"]);
    const template = idToTemplate.get(kpiToTest.get("templateId"));
    const combinedKpi = wk.get("combinedKpi");
    const isTrendable = combinedKpi.get("overrideTrendable") === null
        ? template.get("trendable")
        : combinedKpi.get("overrideTrendable");

    return Promise.all([
      testUserAndGroup(
          kpiId,
          kpiToTest,
          columns,
          testTimeframe,
          testUserId,
          testGroupId,
          template,
          modifiedKpis,
          isTrendable,
          testTraceLevel,
          abortTestsFn),
      runConcurrencyTest && testConcurrency(
          kpiToTest,
          columns,
          testGroupId,
          testTimeframe,
          template,
          modifiedKpis)
    ]).then(results => {
      if (abortTestsFn()) {
        return Promise.reject(new Error("Tests cancelled"));
      }
      kpiProgressFn();
      return results;
    });
  });
  return Promise.all(testPromises).then(results => Immutable.fromJS(results));
};

const retrieveStoredChangeDateTime = (storeKeyRoot) => {
  const dateStr = store.get(storeKeyRoot + ".unsaved-changes-datetime");
  return dateStr ? Time.parseDateTime(dateStr) : null;
};

const saveChangesToLocalStorage = (storeKeyRoot, expandedKpiIds, expandedEntityLabels, wrappedKpis) => {
  store.set(storeKeyRoot + ".unsaved-changes-datetime", Time.formatDateTime(moment()));
  store.set(storeKeyRoot + ".expanded-kpi-ids", expandedKpiIds.toJS());
  store.set(storeKeyRoot + ".expanded-entity-labels", expandedEntityLabels.toJS());
  store.set(storeKeyRoot + ".wrapped-kpis", wrappedKpis.toJS());
};

const retrieveStoredChanges = (storeKeyRoot) => {
  const expandedKpiIds = Immutable.fromJS(store.get(storeKeyRoot + ".expanded-kpi-ids")).toSet();
  const expandedEntityLabels = Immutable.fromJS(store.get(storeKeyRoot + ".expanded-entity-labels")).toSet();
  const wrappedKpis = Immutable.fromJS(store.get(storeKeyRoot + ".wrapped-kpis"));
  return {expandedKpiIds, expandedEntityLabels, wrappedKpis};
};

const clearStoredChanges = (storeKeyRoot) => {
  store.remove(storeKeyRoot + ".unsaved-changes-datetime");
  store.remove(storeKeyRoot + ".expanded-kpi-id");
  store.remove(storeKeyRoot + ".expanded-entity-labels");
  store.remove(storeKeyRoot + ".wrapped-kpis");
  store.remove(storeKeyRoot + ".kpi-id-to-columns");
};

export default EditKpisApp;