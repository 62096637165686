import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";
import Immutable from "immutable";
import {replaceEntityName} from "js/admin/kpis/edit-kpis/utils";
import LabelledSelect from "js/common/views/inputs/labelled-select";

const MetricTemplatePicker = betterMemo(
    {displayName: "MetricTemplatePicker"},
    ({label, labelTooltip, renderItemDescription, entityTypeToLabel, selectedEntityType, idToTemplate, templateId, ...props}) => {
      const options = idToTemplate
          .toList()
          .map(t => new Immutable.Map({
                label: replaceEntityName(t.get("name"), selectedEntityType, entityTypeToLabel),
                value: t.get("id"),
                itemDescription: renderItemDescription && replaceEntityName(t.get("description"), selectedEntityType, entityTypeToLabel)
              })
          )
          .sortBy(t => t.get("label"));
      return (
          <>
            {(label && labelTooltip) && <TooltipLabel label={label} tooltip={labelTooltip} />}
            <LabelledSelect
                isMulti={false}
                isClearable={false}
                dataTestId="template-picker"
                label={labelTooltip ? undefined : "Metric Type"}
                {...props}
                selectedValue={templateId}
                options={options} />
          </>
      );
    });

export default MetricTemplatePicker;