import React from "react";
import {Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import makeStyles from '@mui/styles/makeStyles';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";

const styles = makeStyles({
      root: props => ({...props.style}),
      label: props => ({fontSize: "14px !important", whiteSpace: "nowrap", ...props.labelStyle}),
    });

const checkboxStyles = makeStyles({
  root: props => ({
    "&&:hover": {
      backgroundColor: "transparent"
    },
    ...props.iconStyle,
    color: `${props.iconStyle && props.iconStyle.fill ? props.iconStyle.fill : undefined} !important`}),
});

const Checkbox = (props) => {
  const { theme, checked, label, onCheck, disabled, id, tooltip, testId } = props;
  const classes = styles(props);
  const checkboxClasses = checkboxStyles(props);
  return (
      <FormControlLabel
          id={id}
          data-test-id={testId}
          classes={classes}
          label={tooltip
              ? <TooltipLabel
                  label={label}
                  customTooltipStyle={{whiteSpace: 'normal'}}
                  tooltip={tooltip}/>
              : label}
          disabled={disabled}
          control={
              <MuiCheckbox
                  color="primary"
                  classes={checkboxClasses}
                  checked={checked}
                  icon={<CheckBoxOutlineBlankIcon style={{fontSize: 24, color: theme.palette.action.active}}/>}
                  checkedIcon={<CheckBoxIcon style={{fontSize: 24}}/>}
                  onChange={onCheck}/>}
      />
  );
};

const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
  return <Checkbox theme={theme} {...props} />;
};


Checkbox.displayName = "Checkbox";
export default Wrapper;
