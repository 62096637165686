import React from "react";
import Dialog from "js/common/views/dialog";
import {TextButton} from "js/common/views/inputs/buttons";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import * as Colors from "js/common/cube19-colors";

const SaveReasonDialog = ({onSubmit, onCancel}) => {
  const [reason, setReason] = React.useState("");

  const dialogTitle = <span>Please provide a reason for changing metrics.</span>;

  const closeButtonStyle = {
    backgroundColor: Colors.greyLight,
    color: "white",
    borderRadius: 60,
    padding: "8px 10px",
    fontSize: 12
  };

  return <Dialog
      fullWidth={false}
      maxWidth={500}
      transitionDuration={{exit: 0, enter: 200}}
      enableScroll={false}
      closeButtonStyle={closeButtonStyle}
      onClose={onCancel}
      title={dialogTitle}
      titleIcon="info"
      autoDetectWindowHeight={true}
      bodyStyle={{overflow: "visible"}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      open={true}
      onBackdropClick={onCancel}
      actions={[
        <TextButton
            key="cancel"
            type="dark"
            label="Cancel"
            style={{marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={onCancel} />,
        <TextButton
            testId="save-reason-button"
            key="submit"
            type="primary"
            label="Save"
            style={{marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={() => onSubmit(reason)} />
      ]}>
    <div style={{minWidth: 500}}>
      <DelayedTextField
          variant="standard"
          fullWidth
          placeholder="Leave blank to use login reason..."
          value={reason}
          onChange={setReason} />
    </div>
  </Dialog>;
};

export default SaveReasonDialog;