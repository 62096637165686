/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";
import Immutable from "immutable";

import * as DashboardUtils from "js/dashboards/utils";
import * as Overlays from "js/dashboards/components/overlays";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Text = React.memo(({
  dimensions,
  config,
  isEditing,
  data,
  component
}) => {
  const text = config.get("text");
  const border = config.get("textBorder");
  const alignment = config.get("textAlignment");
  const estimatedLetterHeight = component.getIn(["layout", "height"]) < 2 ? 16 : 28;
  const rowsAvailable = Math.floor(dimensions.height / estimatedLetterHeight);
  const calculatedFontSize = component.getIn(["layout", "height"]) < 2 ? 13 : 22;

  const borderMap = {
    "FULL": {border: `1px solid rgba(0,0,0,0.35)`, padding: "0.5rem"},
    "NONE": {border: "none"},
    "RIGHT": {border: "none", borderRight: `1px solid rgba(0,0,0,0.35)`, paddingRight: "10px"},
    "LEFT": {border: "none", borderLeft: `1px solid rgba(0,0,0,0.35)`, paddingLeft: "10px"},
    "TOP": {border: "none", borderTop: `1px solid rgba(0,0,0,0.35)`, paddingTop: "10px"},
    "BOTTOM": {border: "none", borderBottom: `1px solid rgba(0,0,0,0.35)`, paddingBottom: "10px"},
  };

  if (!text) {
    return <Overlays.Message coverOtherContent={false}>
      Enter some text
    </Overlays.Message>;
  } else {
    return (
          <span
              style={{
                display: "block",
                containerType: "inline-size",
                height: dimensions.height,
                marginLeft: isEditing ? 30 : 0,
                marginRight: isEditing ? 50 : 0,
                cursor: "default",
                ...borderMap[border]
          }}>
            <span style={{
              cursor: "default",
              textAlign: alignment.toLowerCase(),
              fontSize: calculatedFontSize,
              position: "absolute",
              width: "calc(100%)",
              overflow: "hidden",
              lineHeight: `${estimatedLetterHeight}px`,
              display: "-webkit-box",
              maxWidth: "calc(100%)",
              WebkitLineClamp: rowsAvailable,
              WebkitBoxOrient: "vertical",
              left: 0,
              top: 0}}>
              {text}
            </span>
          </span>
    );
  }
});

const TextEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const config = component.get("dataConfig");

  const { theme } = React.useContext(CustomThemeContext);

  const onConfigChange = React.useCallback(newDataConfig => {
    onComponentChange(component.set("dataConfig", newDataConfig));
  }, [onComponentChange, component]);

  const onBorderChange = (border) => {
    onConfigChange(config.set("textBorder", border));
  };

  const onAlignmentChange = (alignment) => {
    onConfigChange(config.set("textAlignment", alignment));
  };

  const labelStyle = {color: theme.palette.text.main};

  const switcherStyle = css`
        border-radius: 8px;
        background: ${theme.palette.background.card};
        border: 1px solid ${theme.palette.background.paper};
        box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        z-index: 1;
  `;

  const panelStyle = (attribute, value) => css`
      background: ${config.get(attribute) === value ? theme.themeId === "light" ? "#e4ecf7" : "#000" : "transparent"};
      display: flex;
      justify-content: center;
      flex-grow: 1;
      font-size: 16px;
      text-align: center;
      padding: 10px 10px;
      cursor: pointer;
      border-left: 1px solid ${theme.palette.background.paper};

      &:first-of-type {
          border-left: none;
      }

      &:hover {
          background: ${theme.palette.background.paper};
      }
  `;

  const borderIconStyle = {
    height: 20,
    width: 20,
  };

  return <div style={{padding: "0.5rem 1rem"}}>
    <div style={{width: 300, marginBottom: "1rem"}}>
      <label style={labelStyle}>Text</label>
      <SimpleTextInput
          data-test-id="text-box"
          placeholder="Text"
          value={config.get("text")}
          onChange={(e) => onConfigChange(config.set("text", e))} />
    </div>
    <div style={{width: 300, marginBottom: "1rem"}}>
      <label style={labelStyle}>Borders</label>
      <div css={switcherStyle}>
        <span css={panelStyle("textBorder","FULL")} onClick={() => onBorderChange("FULL")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.primary.main}`}}/>
        </span>
        <span css={panelStyle("textBorder","LEFT")} onClick={() => onBorderChange("LEFT")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.border.light}`, borderLeft: `2px solid ${theme.palette.primary.main}`}}/>
        </span>
        <span css={panelStyle("textBorder","RIGHT")} onClick={() => onBorderChange("RIGHT")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.border.light}`, borderRight: `2px solid ${theme.palette.primary.main}`}}/>
        </span>
        <span css={panelStyle("textBorder","TOP")} onClick={() => onBorderChange("TOP")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.border.light}`, borderTop: `2px solid ${theme.palette.primary.main}`}}/>
        </span>
        <span css={panelStyle("textBorder","BOTTOM")} onClick={() => onBorderChange("BOTTOM")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.border.light}`, borderBottom: `2px solid ${theme.palette.primary.main}`}}/>
        </span>
        <span css={panelStyle("textBorder","NONE")} onClick={() => onBorderChange("NONE")}>
          <div style={{...borderIconStyle, border: `2px solid ${theme.palette.border.light}`}}/>
        </span>
      </div>
    </div>
    <div style={{width: 300, marginBottom: "1rem"}}>
      <label style={labelStyle}>Alignment</label>
      <div css={switcherStyle}>
        <span css={panelStyle("textAlignment", "LEFT")} onClick={() => onAlignmentChange("LEFT")}>
          <i className="fa fa-align-left"/>
        </span>
        <span css={panelStyle("textAlignment", "CENTER")} onClick={() => onAlignmentChange("CENTER")}>
          <i className="fa fa-align-center"/>
        </span>
        <span css={panelStyle("textAlignment", "RIGHT")} onClick={() => onAlignmentChange("RIGHT")}>
          <i className="fa fa-align-right"/>
        </span>
      </div>
    </div>
  </div>

});

export default Immutable.fromJS({
  type: "Text",
  label: "Text",
  titleIsEditable: false,
  variant: "transparent",
  getDefaultData: () => Immutable.Map(),
  getReactComponent: () => Text,
  getEditorReactComponent: () => TextEditor,
  getTitle: component => undefined,
  canFullScreen: false,
  layout: {
    min: {width: 6, height: 1},
    max: {width: 48, height: 48},
    init: {width: 8, height: 1}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
});
