/** @jsxImportSource @emotion/react */

import {jsx} from "@emotion/react";
import React from "react";
import Immutable from "immutable";
import TextField from "@mui/material/TextField";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Colors from "js/common/cube19-colors";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Dialog from "js/common/views/dialog";
import {replaceEntityName} from "js/admin/kpis/edit-kpis/utils";
import MetricTemplatePicker from "js/admin/kpis/edit-kpis/tabs/common/template-picker";
import {betterMemo} from "js/common/utils/more-memo";
import LabelledSelect from "js/common/views/inputs/labelled-select";

const EntityPicker = betterMemo(
    {displayName: "EntityPicker"},
    ({selectedEntityType, entityTypeToLabel, ...props}) => {
      const options = entityTypeToLabel
          .delete("PLACEMENT_SPLIT")
          .delete("USER")
          .entrySeq()
          .sort(([_1, label1], [_2, label2]) => label1.localeCompare(label2))
          .map(([entityType, label]) => {
            return new Immutable.Map({label, value: entityType});
          });

      return <LabelledSelect
          isMulti={false}
          isClearable={false}
          label="Entity"
          dataTestId="entity-picker"
          {...props}
          selectedValue={selectedEntityType}
          options={options} />;
    });

const AddKpi = ({
  handleClose,
  idToTemplate,
  handleAddKpi,
  idToWrappedKpi,
  entityTypeToLabel,
  theme
}) => {

  const names = idToWrappedKpi.map(kpi => kpi.getIn(["kpi", "name"])).valueSeq().toList();
  const checkIfDuplicate = (name) => names.includes(name);

  const ActionButtons = ({readyToSubmit, onClick}) => {
    return <div style={{marginTop: 10, textAlign: "right"}}>
      <TextButton
          label={"Cancel"}
          style={{marginTop: 10, marginRight: 10}}
          onClick={handleClose} />
      <TextButton
          icon="add-thin"
          iconType="bhi"
          label={"Add Metric"}
          type="primary"
          disabled={!readyToSubmit}
          style={{marginTop: 10, marginLeft: 10}}
          onClick={onClick} />
    </div>;
  };

  const BlankKpiForm = () => {
    const [newKpi, setNewKpi] = React.useState(Immutable.Map({name: ""}));
    const [selectedEntityType, setSelectedEntityType] = React.useState(null);

    const isNameDuplicate = checkIfDuplicate(newKpi.get("name"));

    const isFormComplete = newKpi.get("name") &&
        !isNameDuplicate &&
        newKpi.get("templateId");

    const templatePickerDisabled = selectedEntityType === null;
    const genericEntityString = /ENTITY[0-9]+/;
    const selectedEntityIsGeneric = genericEntityString.test(selectedEntityType);
    const descriptionFallback = !newKpi.get("templateId")
        ? "No template selected."
        : "Metric Type description is unavailable. Reach out to Bullhorn Support if you have questions about your metric configuration.";

    const filteredTemplates = React.useMemo(() => {
      if (!selectedEntityType) {
        return Immutable.Map();
      }

      return idToTemplate.filter(
          t => !t.get("hidden") &&
              t.get("grouping_entity") === (selectedEntityIsGeneric ? null : selectedEntityType)
      );
    }, [selectedEntityType, selectedEntityIsGeneric]);

    React.useEffect(() => {
      if (!templatePickerDisabled &&
          filteredTemplates.size === 1 &&
          !newKpi.get("templateId")) {
        const singleTemplateId = filteredTemplates.first().get("id");
        setNewKpi(newKpi
            .set("templateId", singleTemplateId)
            .set("genericEntity", selectedEntityIsGeneric ? selectedEntityType : null)
        );
      }
    }, [filteredTemplates, templatePickerDisabled, selectedEntityIsGeneric, selectedEntityType, newKpi]);

    return <div>
      <p style={{fontSize: "0.8rem", marginBottom: 20}}>
        Create a new metric from scratch without using any pre-existing config.
      </p>
      <NameInput
          theme={theme}
          name={newKpi.get("name")}
          error={isNameDuplicate}
          onChange={value => setNewKpi(newKpi.set("name", value.substring(0, 64)))} />

      <EntityPicker
          entityTypeToLabel={entityTypeToLabel}
          selectedEntityType={selectedEntityType}
          onChange={value => {
            setSelectedEntityType(value);
            setNewKpi(newKpi
                .delete("templateId")
                .delete("genericEntity")
                .set("readOnlyRootGroupingEntity", value)
            );
          }}
      />

      <div
          style={{
            marginTop: 10,
            opacity: templatePickerDisabled ? 0.5 : 1,
            cursor: templatePickerDisabled ? "not-allowed" : "default"
          }}>
        <MetricTemplatePicker
            renderItemDescription
            entityTypeToLabel={entityTypeToLabel}
            selectedEntityType={selectedEntityType}
            templateId={newKpi.get("templateId", null)}
            showItemDescriptions
            containerStyle={{
              textAlign: "left",
              pointerEvents: templatePickerDisabled ? "none" : "auto"
            }}
            onChange={(id) => {
              setNewKpi(newKpi
                  .set("templateId", id)
                  .set("genericEntity", selectedEntityIsGeneric ? selectedEntityType : null)
              );
            }}
            idToTemplate={filteredTemplates}
        />
      </div>

      <div
          style={{
            marginTop: 10,
            opacity: templatePickerDisabled ? 0.5 : 1
          }}>
        <span style={{fontSize: "0.75rem"}}>Metric Description</span>
        <div
            style={{
              borderRadius: 4,
              background: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card,
              cursor: "not-allowed"
            }}>
          <p
              style={{
                opacity: 0.5,
                maxWidth: 450,
                fontSize: 12,
                padding: "12px 12px"
              }}>
            {replaceEntityName(
                idToTemplate.getIn([newKpi.get("templateId"), "description"]),
                selectedEntityType,
                entityTypeToLabel
            ) || descriptionFallback}
          </p>
        </div>
      </div>
      <ActionButtons readyToSubmit={isFormComplete} onClick={() => handleAddKpi(newKpi)} />
    </div>;
  };

  return <div style={{overflowY: "visible"}}>
    <BlankKpiForm />
  </div>;
};

const NameInput = ({name, onChange, error}) => {
  return <div>
    <TextField
        placeholder="Metric Name"
        hiddenLabel
        error={error}
        helperText={error && "Name already in use"}
        variant="standard"
        style={{marginBottom: 15, backgroundColor: "transparent", width: "100%", borderBottom: "none", padding: 3}}
        onChange={e => onChange(e.target.value)}
        value={name}
        inputProps={{"data-test-id": "kpi-name-input"}} />
  </div>;
};

const AddKpiDialog = ({
  idToWrappedKpi,
  isOpen,
  idToTemplate,
  handleClose,
  handleAddKpi,
  kpiIdToMasterKpis,
  typeToGroupingEntity,
  masterKpiTypeToKpiId,
  entityTypeToLabel,
  theme
}) => {

  const dialogTitle = <span style={{}}>Add a New Metric</span>;

  const closeButtonStyle = {
    backgroundColor: Colors.greyLight,
    color: "white",
    borderRadius: 60,
    padding: "8px 10px",
    fontSize: 12
  };

  return <Dialog
      maxWidth={500}
      fullWidth={false}
      transitionDuration={{exit: 0, enter: 200}}
      open={isOpen}
      enableScroll={false}
      closeButtonStyle={closeButtonStyle}
      onClose={handleClose}
      title={dialogTitle}
      titleIcon="add">
    <AddKpi
        idToTemplate={idToTemplate}
        handleAddKpi={handleAddKpi}
        kpiIdToMasterKpis={kpiIdToMasterKpis}
        typeToGroupingEntity={typeToGroupingEntity}
        idToWrappedKpi={idToWrappedKpi}
        isOpen={isOpen}
        handleClose={handleClose}
        masterKpiTypeToKpiId={masterKpiTypeToKpiId}
        entityTypeToLabel={entityTypeToLabel}
        theme={theme}
    />
  </Dialog>;
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <AddKpiDialog theme={theme} {...props} />;
};