import React from "react";
import {betterMemo} from "js/common/utils/more-memo";
import {cleanCombinedKpi} from "js/admin/kpis/edit-kpis/utils";
import Immutable from "immutable";
import EditKpiRow from "js/admin/kpis/edit-kpis/edit-kpi-row";
import {getNameForValidation, isKpiNameDupe} from "js/admin/kpis/edit-kpis/validation";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const EditKpiEntity = betterMemo(
    {debug: false, displayName: "EditKpiEntity"},
    ({
      index = 2,
      entityTypeToLabel,
      isEditor,
      entityType,
      entityLabel,
      kpiIds,
      isCube19User,
      clientHasEdit,
      entityExpanded,
      idToWrappedKpi,
      rootEntityToCombinedKpis,
      getParentWrappedKpi,
      masterKpiTypeToKpiId,
      columnsKpiIdToKpis,
      nameToCount,
      actionTypes,
      idToTemplate,
      typeToGroupingEntity,
      expandedKpiIds,
      idToEntityColumn,
      onKpiChange,
      onTestConfigChange,
      onTest,
      onColumnsChange,
      onUnmirror,
      onExpandClick,
      onExpandEntityClick,
      kpiIdToDependentKpis,
      kpiIdToContributingKpis,
      kpiIdToMasterKpis,
      onNavigationClick,
      handleDuplicateClick,
      handleInheritClick,
      onRevertToLegacyFormat,
      onDisableMetricToggle,
      onDeleteMetric,
      onChangeTab,
      onClearRequiresExplanationUpdate,
      filterText,
      searchFocused,
      showOnlyFailingKpis,
      showOnlyChangedKpis,
      sumDoubleSplitsAvailable,
      sumSplitAvailable,
      aggregationTypes,
      trendTypes,
    }) => {
      const hasKpis = !kpiIds.isEmpty();
      const {theme} = React.useContext(CustomThemeContext);

      const entityStyle = {
        background: theme.palette.background.card,
        boxShadow:
            entityExpanded ?
                "0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10)"
                :
                "0px 0px 1.6px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0)",
        marginTop: entityExpanded && index !== 0 ? 10 : 0,
        marginBottom: entityExpanded ? 10 : 0,
      };

      const entityHeadingContainerStyle = {
        display: "flex",
        padding: 10,
        alignItems: "center",
        cursor: "pointer"
      };

      const entityHeadingStyle = {
        fontSize: 16,
        fontWeight: 600
      };

      const shouldAutoExpandContent = ((filterText && filterText !== "") || showOnlyFailingKpis || showOnlyChangedKpis) && hasKpis;
      if (filterText && !hasKpis) {
        return <div />;
      }

      return (
          <div style={entityStyle} key={`entity-${entityLabel}-${index}`}>
            <div style={entityHeadingContainerStyle} onClick={() => onExpandEntityClick(entityLabel, entityExpanded)}>
              <h3 style={entityHeadingStyle}>{entityLabel}</h3>
              <span style={{flexGrow: 1}} />
              {!shouldAutoExpandContent && <i data-test-id={"expand-entity-section"} className={entityExpanded || shouldAutoExpandContent ? "bhi-sort-asc" : "bhi-sort-desc"} />}
            </div>
            {(entityExpanded || shouldAutoExpandContent) &&
            <ul style={{display: "block", padding: "0 10px 15px 10px"}}>
              {hasKpis ? kpiIds.map((id, index) => {
                const wrappedKpi = idToWrappedKpi.get(id);
                const columnsKpiId = wrappedKpi.getIn(["kpi", "columnsKpiId"]) || id;
                const mirrorColor = idToWrappedKpi.getIn([columnsKpiId, "mirrorColor"]);
                const parentWrappedKpi = getParentWrappedKpi(wrappedKpi.get("kpi"), masterKpiTypeToKpiId, idToWrappedKpi);
                const parentCombinedKpi = parentWrappedKpi
                    ? cleanCombinedKpi(parentWrappedKpi.get("combinedKpi"))
                    : null;
                const combinedKpi = wrappedKpi.get("combinedKpi");
                const combineError = wrappedKpi.get("combineError");
                const rootGroupingEntity = wrappedKpi.getIn(["kpi", "readOnlyRootGroupingEntity"])
                    ?? parentWrappedKpi?.getIn(["kpi", "readOnlyRootGroupingEntity"]);
                const kpisWithMatchingRootEntity = (wrappedKpi.getIn(["kpi", "templateId"]) && rootGroupingEntity)
                    ? rootEntityToCombinedKpis.get(rootGroupingEntity, Immutable.List())
                    : rootEntityToCombinedKpis.valueSeq().flatMap(x => x);
                const kpisWithMatchingColumns = columnsKpiIdToKpis.get(columnsKpiId, Immutable.List());
                return <EditKpiRow
                    theme={theme}
                    key={id}
                    isNameDupe={isKpiNameDupe(getNameForValidation(wrappedKpi.get("kpi")), nameToCount)}
                    isUnsaved={wrappedKpi.get("isUnsaved")}
                    hasChanged={wrappedKpi.get("changed") || wrappedKpi.get("columnsChanged")}
                    isEditor={isEditor}
                    isCube19User={isCube19User}
                    clientHasEdit={clientHasEdit}
                    actionTypes={actionTypes}
                    idToTemplate={idToTemplate}
                    typeToGroupingEntity={typeToGroupingEntity}
                    availableOwners={wrappedKpi.get("availableOwners")}
                    availableServerColumns={wrappedKpi.get("availableServerColumns")}
                    idToEntityColumn={idToEntityColumn}
                    entityTypeToLabel={entityTypeToLabel}
                    entityType={entityType}
                    kpi={wrappedKpi.get("kpi")}
                    parentCombinedKpi={parentCombinedKpi}
                    combinedKpi={combinedKpi}
                    combineError={combineError}
                    index={index}
                    expanded={expandedKpiIds.includes(id)}
                    onKpiChange={onKpiChange}
                    testConfig={wrappedKpi.get("testConfig")}
                    onTestConfigChange={onTestConfigChange}
                    testResult={wrappedKpi.get("testResult")}
                    onTest={onTest}
                    columns={idToWrappedKpi.getIn([columnsKpiId, "columns"])}
                    submitError={wrappedKpi.get("submitError")}
                    onColumnsChange={onColumnsChange}
                    onUnmirror={onUnmirror}
                    kpisWithMatchingRootEntity={kpisWithMatchingRootEntity}
                    kpisWithMatchingColumns={kpisWithMatchingColumns}
                    mirrorColor={mirrorColor}
                    onExpandClick={onExpandClick}
                    rootChanged={wrappedKpi.get("rootChanged")}
                    kpiIdToDependentKpis={kpiIdToDependentKpis}
                    kpiIdToContributingKpis={kpiIdToContributingKpis}
                    kpiIdToMasterKpis={kpiIdToMasterKpis}
                    onNavigationClick={onNavigationClick}
                    handleDuplicateClick={handleDuplicateClick}
                    handleInheritClick={handleInheritClick}
                    onRevertToLegacyFormat={onRevertToLegacyFormat}
                    onDisableMetricToggle={onDisableMetricToggle}
                    onDeleteMetric={onDeleteMetric}
                    requiresExplanationUpdate={wrappedKpi.get("requiresExplanationUpdate")}
                    tabId={wrappedKpi.get("tabId")}
                    onChangeTab={onChangeTab}
                    filterText={filterText}
                    searchFocused={searchFocused}
                    onClearRequiresExplanationUpdate={onClearRequiresExplanationUpdate}
                    sumDoubleSplitsAvailable={sumDoubleSplitsAvailable}
                    sumSplitAvailable={sumSplitAvailable}
                    aggregationTypes={aggregationTypes}
                    trendTypes={trendTypes}
                />;
              }) : <div style={{fontSize: 12}}>No Metrics to display</div>}
            </ul>}
          </div>
      );
    });

export default EditKpiEntity;