import React from "react";
import Immutable from "immutable";

import * as Users from "js/common/users";
import {betterMemo} from "js/common/utils/more-memo";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";
import InheritSwitch from "js/common/inherit-switch";
import EntityAndServerColumnPicker from "js/admin/kpis/edit-kpis/tabs/common/entity-and-server-column-picker";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const ConfigAggregation = betterMemo(
    {displayName: "ConfigAggregation"},
    ({
         inheritKeys,
         isCombined,
         isTrendable,
         toggleInheritance,
         sumDoubleSplitsAvailable,
         fieldGridStyle,
         selectedAggregationType,
         selectedTrendType,
         onAggregationTypeChange,
         onTrendTypeChange,
         sumEntityColumn,
         uniqueEntityColumn,
         valueEntityColumnId,
         valueJoinPath,
         entityType,
         sumAndValueColumns,
         uniqueColumns,
         onSumEntityColumnChange,
         onUniqueEntityColumnChange,
         onValueEntityColumnChange,
         aggregationTypes,
         trendTypes
     }) => {
      const {theme} = React.useContext(CustomThemeContext);
      const [aggregationOptions, setAggregationOptions] = React.useState(Immutable.OrderedSet());
      const [trendOptions, setTrendOptions] = React.useState(Immutable.OrderedSet());

      const errorStyle = (theme) => ({
        color: theme.palette.button.alert,
        fontSize: 10,
        marginLeft: 5
      });

      const currentUser = Users.getCurrentUser();
      const isCube19User = Users.isCube19User(currentUser);

      React.useEffect(() => {
        const filteredAggregationOptions = aggregationTypes
            .filter(option => {
              if ((!sumDoubleSplitsAvailable && !isCube19User) || entityType !== "PLACEMENT") {
                const aggregationType = option.get("type");
                return aggregationType !== "DEALING_WITH_DOUBLED_SPLITS_FOR_SALES" &&
                    aggregationType !== "DEALING_WITH_DOUBLED_SPLITS_FOR_PLACEMENTS";
              }
              return true;
            })
            .filter(option => {
              if (!isCube19User && selectedAggregationType !== "NPS") {
                const aggregationType = option.get("type");
                return aggregationType !== "NPS"
              }
              return true;
            })
            .map(option => Immutable.Map({
              label: option.get("label"),
              value: option.get("type"),
            }))
            .sortBy(option => option.get("value"));

        const filteredTrendOptions = trendTypes
            .map(option => Immutable.Map({
              label: option.get("label"),
              value: option.get("type"),
            }))
            .sortBy(option => option.get("value"));

        setAggregationOptions(Immutable.OrderedSet(filteredAggregationOptions));
        setTrendOptions(Immutable.OrderedSet(filteredTrendOptions));
      }, [aggregationTypes, entityType, isCube19User, selectedAggregationType, sumDoubleSplitsAvailable, trendTypes]);

      const selectedAggregationOption = aggregationTypes.find(at => at.get("type") === selectedAggregationType);
      const shouldShowSumColumn = selectedAggregationOption?.get("requiresSumColumn");
      const shouldShowUniqueColumn = selectedAggregationOption?.get("requiresUniqueColumn");
      const shouldShowValueColumn = selectedAggregationOption?.get("requiresValueColumn");
      const aggregationTypeTooltip = <div>
        Choose how records are combined together. For example, if you have a Job metric you can:
        <ul style={{margin: "10px 10px 0 15px"}}>
          <li>Choose Sum and then pick Job Openings as the Value column to show the number of Job Openings</li>
          <li>Choose Count to show the number of Job records</li>
          <li>Choose Count Unique and then pick Client ID to show the number of distinct Clients you have Jobs with.
          </li>
        </ul>
      </div>;

      return <>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          <div style={fieldGridStyle}>
            {isCombined && <InheritSwitch
                style={{position: "absolute", marginTop: -1}}
                section="metric"
                inheritKey="aggregationType"
                inheritKeys={inheritKeys}
                onToggle={toggleInheritance} />}
            <TooltipLabel label="Type" tooltip={aggregationTypeTooltip} />
            <LabelledSelect
                placeholder="Select"
                isMulti={false}
                clearable={true}
                selectedValue={selectedAggregationType}
                onChange={(value) => onAggregationTypeChange(value)}
                isDisabled={isCombined && inheritKeys.has("aggregationType")}
                options={aggregationOptions} />
          </div>
          <div style={fieldGridStyle}>
            {isTrendable && <>
              <TooltipLabel label="Trend" tooltip="Select the trend type you wish to use for this metric." />
              <LabelledSelect
                  placeholder="Select"
                  isMulti={false}
                  clearable={true}
                  selectedValue={selectedTrendType || "GROUP_AND_AGGREGATE"}
                  onChange={(value) => onTrendTypeChange(value)}
                  isDisabled={!isCube19User}
                  options={trendOptions} />
            </>}
          </div>
        </div>
        <div style={{display: "flex", flexWrap: "wrap"}}>
          {shouldShowSumColumn && <div style={{...fieldGridStyle, width: "32%", marginRight: "1%"}}>
            {isCombined && <InheritSwitch
                style={{position: "absolute", marginTop: -1}}
                section="metric"
                inheritKey="sumColumn"
                inheritKeys={inheritKeys}
                onToggle={toggleInheritance} />}
            <EntityAndServerColumnPicker
                entityColumnId={sumEntityColumn?.get("id")}
                joinPath={sumEntityColumn?.get("joinPath")}
                columns={sumAndValueColumns}
                label="Divide By Sum Of"
                labelTooltip="Choose the column you want to use instead of the count of records to produce an average."
                required={true}
                disabled={(isCombined && inheritKeys.has("sumColumn"))}
                onColumnSelect={onSumEntityColumnChange} />
            {!sumEntityColumn?.get("id") && <span style={errorStyle(theme)}>Required field</span>}
          </div>}
          {shouldShowValueColumn && <div style={{...fieldGridStyle, width: "32%", marginRight: "1%"}}>
            {isCombined && <InheritSwitch
                style={{position: "absolute", marginTop: -1}}
                section="metric"
                inheritKey="valueColumn"
                inheritKeys={inheritKeys}
                onToggle={toggleInheritance} />}
            <EntityAndServerColumnPicker
                entityColumnId={valueEntityColumnId}
                joinPath={valueJoinPath}
                columns={sumAndValueColumns}
                label="Value"
                required={true}
                labelTooltip="Choose the column representing the numerical value of a single record. For example, Job Openings for a Job metric. This will be used by your chosen Type to produce the metric result."
                disabled={(isCombined && inheritKeys.has("valueColumn"))}
                onColumnSelect={onValueEntityColumnChange} />
            {!valueEntityColumnId && <span style={errorStyle(theme)}>Required field</span>}
          </div>}
          {shouldShowUniqueColumn && <div style={{...fieldGridStyle, width: "32%"}}>
            {isCombined && <InheritSwitch
                style={{position: "absolute", marginTop: -1}}
                section="metric"
                inheritKey="uniqueColumn"
                inheritKeys={inheritKeys}
                onToggle={toggleInheritance} />}
            <EntityAndServerColumnPicker
                entityColumnId={uniqueEntityColumn?.get("id")}
                serverColumnName={uniqueEntityColumn?.get("name")}
                joinPath={uniqueEntityColumn?.get("joinPath")}
                columns={uniqueColumns}
                label="Unique"
                required={true}
                labelTooltip="Choose the column representing a unique value to be used when aggregating. For example, if you've chosen Count Unique for the Type then choosing Client ID here will show the number of distinct Clients you have jobs with."
                disabled={(isCombined && inheritKeys.has("uniqueColumn"))}
                onColumnSelect={onUniqueEntityColumnChange} />
            {(!uniqueEntityColumn?.get("name") && !uniqueEntityColumn?.get("id")) && <span style={errorStyle(theme)}>Required field</span>}
          </div>}
        </div>
      </>;
    });

export default ConfigAggregation;
