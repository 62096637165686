import React, {useContext} from "react";
import {Dialog, DialogContent, DialogActions, DialogTitle} from "@mui/material";

import DialogCube2021 from "js/common/views/dialog-cube2021";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Cube19Dialog = React.memo(
    ({
      open,
      title,
      actions,
      children,
      maxWidth,
      paperStyle,
      onBackdropClick,
      enableScroll,
      theme,
      hideBackdrop,
      disableEnforceFocus,
      type,
      iconStyle,
      titleStyle,
      titleIcon,
      closeButtonStyle,
      transitionDuration,
      onClose,
      fullWidth = true
    }) => {
      const {theme: cubeTheme} = useContext(CustomThemeContext);

      const childrenStyle = enableScroll === true ? {} : {overflowY: "visible"};

      if (theme === "cube2021") {
        return <DialogCube2021
            style={{zIndex: 9999999}}
            isOpen={open}
            iconStyle={iconStyle}
            childrenStyle={childrenStyle}
            onRequestClose={onBackdropClick}
            content={children}
            actions={actions}
            type={type}
            disableEnforceFocus={disableEnforceFocus}
            hideBackdrop={hideBackdrop}
            transitionDuration={transitionDuration}
            titleStyle={titleStyle}
            closeButtonStyle={closeButtonStyle}
            title={title} />;
      }

      return <Dialog
          style={{zIndex: 9999999}}
          open={open}
          onBackdropClick={onBackdropClick}
          fullWidth={fullWidth}
          hideBackdrop={hideBackdrop}
          maxWidth={maxWidth}
          PaperProps={{
            style: {
              ...paperStyle,
              overflowY: "visible",
              background: cubeTheme.themeId === "light"
                  ? cubeTheme.palette.background.card
                  : cubeTheme.palette.background.paper
            }
          }}>
        {title &&
            <DialogTitle
                disabletypography="true"
                style={{
                  padding: "8px 16px",
                  borderBottom: `2px solid ${cubeTheme.palette.border.modal}`,
            }}>
          <div style={{display: "flex", alignItems: "center"}}>
            {titleIcon && <i className={`bhi-${titleIcon}`} style={{marginRight: 8}} />}
            <div style={{flexGrow: 1}}>
              <h3
                  style={{
                    fontSize: "1rem",
                    paddingTop: 2,
                    fontWeight: 300,
                    color: cubeTheme.palette.text.main
                  }}>
                {title}
              </h3>
            </div>
            {onClose && <i style={{fontSize: "0.8rem", cursor: "pointer"}} className="bhi-times" onClick={() => onClose()} />}
          </div>
        </DialogTitle>}
        {children && <DialogContent style={{...childrenStyle, padding: 16}}>{children}</DialogContent>}
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>;
    });

export default Cube19Dialog;