import React from "react";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Tooltip from "js/common/views/tooltips";

const tooltipText = "You have unsaved changes.";
const tooltipStyle = {top: -20};

const indicatorStyle = (theme) => ({
  height: 15,
  width: 15,
  borderRadius: "50%",
  background: theme.palette.error.main,
  position: "absolute",
  right: 2,
  top: -20
});

const UnsavedChangesIndicator = () => {
  const {theme} = React.useContext(CustomThemeContext);
  return (
      <Tooltip
          width={200}
          styleType={theme.themeId === "light" ? "dark" : "light"}
          text={tooltipText}
          customStyle={tooltipStyle}
          position={"right"}>
        <span data-test-id="unsaved-changes-indicator" style={indicatorStyle(theme)} />
      </Tooltip>);
};

export default UnsavedChangesIndicator;
