import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import DropdownToggle from "js/common/views/react-dropdown-toggle";
import pure from "js/common/views/pure";
import { TextField } from '@mui/material';

export default createReactClass({

  mixins: [PureRenderMixin],
  displayName: "CustomKpiPicker",

  propTypes: {
    kpis: ImmutablePropTypes.list.isRequired,
    onKpiSelect: ReactPropTypes.func.isRequired,
    disableNonClientFilterableKpis: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    searchMinLimit: ReactPropTypes.number,
    closeOnSelect: ReactPropTypes.bool,
    label: ReactPropTypes.string
  },

  getDefaultProps() {
    return {
      label: "Select Metric",
      searchMinLimit: 10,
      closeOnSelect: true,
      disableNonClientFilterableKpis: false,
      isDisabled: false
    };
  },

  getInitialState() {
    return {
      searchFilteredKpis: this.props.kpis,
      searchText: "",
      isOpen: false
    };
  },

  componentDidUpdate(prevProps, prevState) {
    const currentSearchText = this.state.searchText;
    const previousSearchText = prevState.searchText;
    const currentKpis = this.props.kpis;
    const previousKpis = prevProps.kpis;
    if (!Immutable.is(currentKpis, previousKpis) || currentSearchText !== previousSearchText) {
      this.filterKpisBySearchText();
    }
  },

  render() {
    const {searchFilteredKpis, isOpen} = this.state;
    const {kpis, searchMinLimit, isDisabled, disableNonClientFilterableKpis, label} = this.props;
    const isSearchable = kpis.count() >= searchMinLimit;

    return (
        <DropdownToggle
            primaryText={label}
            isDisabled={isDisabled}
            isOpen={isOpen}
            onToggleButtonClick={this.toggleDropdown}
            onRequestClose={this.closeDropdown}>
          <KpiList
              kpis={searchFilteredKpis}
              onSelect={this.handleKpiSelect}
              isSearchable={isSearchable}
              onSearchTextChange={searchText => this.setState({searchText})}
              disableNonClientFilterableKpis={disableNonClientFilterableKpis} />
        </DropdownToggle>);
  },

  toggleDropdown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  },

  closeDropdown() {
    this.setState(this.getInitialState());
  },

  handleKpiSelect(kpiId) {
    this.props.onKpiSelect(kpiId);
    if (this.props.closeOnSelect) {
      this.closeDropdown();
    }
  },

  filterKpisBySearchText() {
    const {kpis} = this.props;
    const {searchText} = this.state;
    if (searchText.length > 0) {
      const text = searchText.toUpperCase();
      const searchFilteredKpis = kpis
          .filter(kpi => !kpi.get("deleted"))
          .filter(kpi => kpi.get("name").toUpperCase().includes(text));
      this.setState({searchFilteredKpis});
    } else {
      this.setState({searchFilteredKpis: kpis});
    }
  }

});

const KpiList = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    kpis: ImmutablePropTypes.list.isRequired,
    onSelect: ReactPropTypes.func.isRequired,
    isSearchable: ReactPropTypes.bool.isRequired,
    onSearchTextChange: ReactPropTypes.func.isRequired,
    disableNonClientFilterableKpis: ReactPropTypes.bool.isRequired
  },

  componentDidMount() {
    if (this.props.isSearchable) {
      this.timeout = setTimeout(() => this.searchTextInput.focus(), 250);
    }
  },

  componentWillUnmount() {
    clearTimeout(this.timeout);
  },

  render() {
    const {kpis, isSearchable, onSearchTextChange} = this.props;
    return (
        <div style={{maxHeight: "15rem", overflow: "auto"}} data-test-id="custom-kpi-selector">
          {isSearchable &&
          <div style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
            {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                            https://github.com/facebook/react/issues/6822 */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
            <TextField
                variant="standard"
                label={<span style={{whiteSpace: "nowrap"}}><i className="fa fa-search" />&nbsp;Type to search for Metrics</span>}
                style={{width: "100%", overflow: "hidden", margin: "5px 0"}}
                onChange={evt => onSearchTextChange(evt.target.value)}
                inputRef={ref => this.searchTextInput = ref} />
          </div>}
          {kpis.isEmpty() ? <NoResultsMatchMessage /> : this.renderKpiOptions()}
        </div>
    );
  },

  renderKpiOptions() {
    const {kpis, disableNonClientFilterableKpis, onSelect} = this.props;
    return kpis
        .filter(kpi => !kpi.get("deleted"))
        .map(kpi => {
      const isNotClientFilterable = !kpi.get("filterableByClient");
      const isDisabled = disableNonClientFilterableKpis && isNotClientFilterable;
      return <KpiOption key={kpi.get("id")} kpi={kpi} onClick={onSelect} isDisabled={isDisabled} />;
    });
  }

});

const KpiOption = pure(({
  kpi,
  onClick,
  isDisabled
}) => (
    <div key={kpi.get("id") || kpi.get("cid")}
         style={isDisabled ? disabledOptionStyle : optionStyle}
         onClick={() => {
           if (!isDisabled) {
             onClick(kpi.get("id"));
           }
         }}>
      {kpi.get("name")} {isDisabled && <ClientFilterWarning />}
    </div>
), "KpiOption");

const NoResultsMatchMessage = pure(() => (
    <div style={{opacity: 0.5, padding: "0.875rem"}}>No results found</div>
));

const ClientFilterWarning = pure(() => (
    <span style={{color: "#f2e207"}}>
        <i className="fa fa-exclamation-triangle" style={{padding: "0 5px"}} />
        Not Client Filterable
    </span>
));

const optionStyle = {
  fontSize: "0.875rem",
  cursor: "pointer",
  padding: "0.5rem 0.875rem 0 0.875rem",

  ":hover": {
    background: "#f2e207",
    color: "#111",
    fontWeight: "bold"
  }
};

const disabledOptionStyle = {
  fontSize: "0.875rem",
  opacity: 0.5,
  cursor: "not-allowed",
  padding: "0.5rem 0.875rem",

  ":hover": {
    background: "#252525",
    color: "#fff",
    fontWeight: "normal"
  }
};
