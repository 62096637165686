import React from "react";
import ConfigOverview from "js/admin/kpis/edit-kpis/tabs/config-overview";
import {TextField} from "@mui/material";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import * as Colors from "js/common/cube19-colors";
import Error from "js/common/views/error";
import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Explanation = ({
  columnNameToMap,
  idToTemplate,
  entityTypeToLabel,
  kpi,
  onKpiChange,
  showConfigOverview,
  requiresExplanationUpdate,
  onClearRequiresExplanationUpdate}) => {

  const helperText = showConfigOverview ? "Include an explanation of what this metric does.  The config overview is included below for reference." : "Include a description of what this metric does.";
  const areExplanationsEnabled = currentClient.areExplanationsEnabled();
  const { theme } = React.useContext(CustomThemeContext)

  return <div>
    <div
        style={{
          backgroundColor: theme.themeId === "light"? "transparent" : "#445",
          padding: "0.6rem",
          marginBottom: 10,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
      Explanation
    </div>
    {requiresExplanationUpdate && <Error
        text={<div>You have changed this metric's config. Please update the below explanation or <span
            style={{cursor: "pointer", textDecoration: "underline"}}
            onClick={() => onClearRequiresExplanationUpdate(kpi.get("id"))}>confirm no changes are required.</span>
        </div>}
        type="warn"
        style={{margin: "0 10px 10px"}} />}
    {!areExplanationsEnabled && <Error
        text="Metric Explanations are currently disabled and only visible to Admin Users.  Enable them in Company Settings to make them visible to all Users."
        type="warn"
        style={{margin: "0 10px 10px"}} />}

    <Hint style={{margin: "1rem"}}>
      <Icon icon="info" style={{color: theme.palette.hints.text}} />
      {helperText}
    </Hint>
    <EditExplanation
        onChange={explanation => onKpiChange(kpi.set("explanation", explanation || null))}
        explanation={kpi.get("explanation")}
    />
    {showConfigOverview && <>
    <div
        style={{
          backgroundColor: "transparent",
          padding: "0.6rem",
          marginBottom: 10,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
      Config Overview
    </div>
      <ConfigOverview
        columnNameToMap={columnNameToMap}
        idToTemplate={idToTemplate}
        entityTypeToLabel={entityTypeToLabel}/></>}
  </div>;
};

export const EditExplanation = ({explanation, onChange}) => {

  const [editedExplanation, setEditedExplanation] = React.useState(explanation);
  const characterCount = editedExplanation ? editedExplanation.length : 0;
  const maxCharacterCount = 1024;
  const characterCountExceeded = characterCount > maxCharacterCount;
  const delayInMillis = 1000;
  const { theme } = React.useContext(CustomThemeContext);

  React.useEffect(() => setEditedExplanation(explanation), [explanation]);

  const timeoutIdRef = React.useRef(null);

  const flushChanges = () => {
    clearTimeout(timeoutIdRef.current);
    onChange(editedExplanation);
  };

  const handleChange = newStr => {
    const truncatedStr = newStr.substring(0, maxCharacterCount + 1);
    setEditedExplanation(truncatedStr);
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      onChange(truncatedStr);
    }, delayInMillis);
  };

  return <div style={{margin: 10}}>
    <TextField
        placeholder="Include a description of what this metric does..."
        onBlur={flushChanges}
        multiline={true}
        minRows={3}
        maxRows={8}
        style={fieldStyle(theme)}
        inputProps={{style: inputStyle}}
        error={characterCountExceeded}
        helperText={characterCountExceeded && "Maximum number of characters reached"}
        onChange={e => handleChange(e.target.value)}
        value={editedExplanation || ""} />
  </div>;
};

const fieldStyle = theme =>({
  width: "100%",
  marginBottom: 5,
  backgroundColor: theme.themeId === "light" ? "#f7f7f7" : Colors.greyDark
});

const inputStyle = {
  fontSize: "0.9rem",
  lineHeight: 1.8
};

export default Explanation;
