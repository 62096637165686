import React from "react";
import Immutable from "immutable";
import {v4 as uuid} from "uuid";
import ReactTooltip from "react-tooltip";
import {clientPermissions} from "js/common/permissions";
import {betterMemo} from "js/common/utils/more-memo";
import {bullhornSupportTicketDocsUrl} from "js/common/branding-constants";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import TabSection from "js/admin/kpis/edit-kpis/tabs/common/tab-section";
import ConfigOwnership from "js/admin/kpis/edit-kpis/tabs/config-ownership";
import EntityAndServerColumnPicker from "js/admin/kpis/edit-kpis/tabs/common/entity-and-server-column-picker";
import {
  getQueryParam,
  useConfigFieldChange,
  isKpiEditable,
  replaceEntityName
} from "js/admin/kpis/edit-kpis/utils";
import ConfigInheritFrom from "js/admin/kpis/edit-kpis/tabs/config-inherits-from";
import Filters from "js/admin/kpis/edit-kpis/config-filters";
import InheritSwitch from "js/common/inherit-switch";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import currentClient from "js/common/repo/backbone/current-client";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";
import * as Branding from "js/common/branding-constants";
import Checkbox from "js/common/views/inputs/checkbox";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import ConfigAggregation from "js/admin/kpis/edit-kpis/tabs//config-aggregation";
import MetricTemplatePicker from "js/admin/kpis/edit-kpis/tabs/common/template-picker";
import * as Users from "js/common/users";

const fieldGridStyle = {
  display: "flex",
  flexDirection: "column",
  width: "49%",
  marginBottom: "1rem"
};

const filterSectionHeadingStyle = {
  fontWeight: 700,
  textTransform: "uppercase",
  fontSize: 14,
  marginBottom: 5
};

const inheritedFiltersSectionTitle = "Inherited Metric Filters";
const filtersSectionTitle = "Metric Specific Filters";

const renderFilterSectionTitle = (label, labelTooltip) => {
  return <div style={{display: "flex", alignContent: "center"}}>
    {labelTooltip && <TooltipLabel
        label={label}
        tooltip={labelTooltip}
        customLabelStyle={filterSectionHeadingStyle} />
    }
  </div>;
};

const notNewConfigTypeFallback = (theme) => <p
    style={{fontSize: 13, padding: 10, paddingBottom: 0}}
    data-test-id="cannot-be-edited-message">
  This metric cannot be edited, duplicated or inherited from this page.
  If you need to make changes, please <a
    target="_blank"
    style={{color: theme.palette.primary.main}}
    href={bullhornSupportTicketDocsUrl} rel="noreferrer">reach out to support</a>.
</p>;

const Config = betterMemo(
    {displayName: "Config"},
    ({
      kpi,
      entityTypeToLabel,
      availableOwners,
      entityType,
      onChange,
      columns,
      onKpiChange,
      kpisAvailableToCombineWith,
      kpiIdToDependentKpis,
      rootNodes,
      cyclingKpis,
      searchFocused,
      filterText,
      parentKpi,
      idToTemplate,
      combinedKpi,
      combineError,
      sumDoubleSplitsAvailable,
      sumSplitAvailable,
      aggregationTypes,
      trendTypes,
      availableServerColumns
    }) => {
      const {theme} = React.useContext(CustomThemeContext);
      const currentUser = Users.getCurrentUser();
      const isCube19User = Users.isCube19User(currentUser);

      const customValueFormatForAggregationType = {
        "DEALING_WITH_DOUBLED_SPLITS_FOR_PLACEMENTS": "NUMBER",
        "DEALING_WITH_DOUBLED_SPLITS_FOR_SALES": "CURRENCY"
      };

      const dataTypeToValueFormat = {
        "INTEGER": "NUMBER",
        "PERCENTAGE": "PERCENT",
        "CURRENCY": "CURRENCY"
      };

      const handleFiltersChange = useConfigFieldChange(kpi, onChange, "filter");

      const handleAggregationTypeChange = (aggregationType) => {
        const valueFormat = customValueFormatForAggregationType[aggregationType] || kpi.get("valueFormat");

        onChange(kpi
            .set("valueFormat", valueFormat)
            .setIn(["config", "aggregationType"], aggregationType));
      };

      const handleTrendTypeChange = useConfigFieldChange(kpi, onChange, "trendType");

      const handleOwnershipChange = useConfigFieldChange(kpi, onChange, "entityToOwnerTypes");


      const handleEntityColumnChange = (key, columnId, joinPath, dataType) => {
        const valueFormat = dataTypeToValueFormat[dataType] || kpi.get("valueFormat");
        onChange(kpi
            .set("valueFormat", valueFormat)
            .deleteIn(["config", key, "name"])
            .setIn(["config", key, "type"], "ENTITY_COLUMN")
            .setIn(["config", key, "id"], columnId)
            .setIn(["config", key, "joinPath"], joinPath));
      };

      const handleServerColumnChange = (key, columnId, joinPath, dataType) => {
        const valueFormat = dataTypeToValueFormat[dataType] || kpi.get("valueFormat");
        onChange(kpi
            .set("valueFormat", valueFormat)
            .deleteIn(["config", key, "joinPath"])
            .deleteIn(["config", key, "id"])
            .setIn(["config", key, "type"], "SERVER_COLUMN")
            .setIn(["config", key, "name"], columnId));
      };

      const handleSumEntityColumnChange = (columnId, joinPath, dataType) => handleEntityColumnChange(
          "sumColumn",
          columnId,
          joinPath,
          dataType);
      const handleUniqueEntityColumnChange = (columnId, joinPath, dataType, columnType) => {
        if (columnType === "SERVER_COLUMN") {
          handleServerColumnChange("uniqueColumn", columnId, joinPath, dataType);
        } else {
          handleEntityColumnChange("uniqueColumn", columnId, joinPath, dataType);
        }
      };

      // NOTE: eventually this will move to the same format as sum and unique, but for now it's a little different
      const handleValueEntityColumnChange = (columnId, joinPath, dataType) => {
        const valueFormat = dataTypeToValueFormat[dataType] || kpi.get("valueFormat");
        onChange(kpi
            .set("valueFormat", valueFormat)
            .setIn(["config", "valueEntityColumnId"], columnId)
            .setIn(["config", "valueJoinPath"], joinPath));
      };

      const handleDateEntityChange = (columnId, joinPath) => onChange(kpi.setIn(
          ["config", "dateEntityColumnId"],
          columnId).setIn(["config", "dateJoinPath"], joinPath));

      const dateColumns = React.useMemo(() => {
        return columns?.filter(c => c.get("dataType") === "DATE");
      }, [columns]);

      const sumAndValueColumns = React.useMemo(() => {
        let filterableTypes = Immutable.Set(["INTEGER", "CURRENCY", "PERCENTAGE"]);
        return columns?.filter(c => filterableTypes.includes(c.get("dataType")));
      }, [columns]);

      const handleTemplateChange = (templateId) => {
        onChange(kpi
            .set("templateId", templateId)
            .set("overrideTrendable", null)
        );
      };

      const filterableColumns = React.useMemo(() => {
        const multiCurrencyClient = currentClient.hasPermission(clientPermissions.canSeeMultipleCurrencies);
        let filterableTypes = Immutable.Set(["STRING", "DATE", "PERCENTAGE", "INTEGER", "BOOLEAN"]);
        if (!multiCurrencyClient) {
          filterableTypes = filterableTypes.add("CURRENCY");
        }
        return columns?.filter(c => filterableTypes.includes(c.get("dataType")));
      }, [columns]);

      const allowAutoFocus = !searchFocused && (!filterText || filterText === "");
      const isCombined = React.useMemo(() => kpi.get("combineType")
          === "MERGE_WITH_AND"
          && !!kpi.get("combineWithKpiId"), [kpi]);
      const isOverridableDateColumn = !!kpi.get("isOverridableDateColumn");
      const isCustomDateColumn = getQueryParam(isCombined ? combinedKpi : kpi, "overrideDateFilter");
      const dateTooltip = !isOverridableDateColumn
          ? "The date filtering on this metric cannot be changed"
          : (isCustomDateColumn
              ? "This metric has a custom date filter and can only be edited by support"
              : null);

      const hasKpiOverrideTimeframe = (kpiToCheck) => kpiToCheck
          && kpiToCheck.has("overrideTimeframe")
          && kpiToCheck.hasIn(["overrideTimeframe", "type"])
          && kpiToCheck.getIn(["overrideTimeframe", "type"])
          !== "NONE";

      const parentHasOverrideTimeframe = React.useMemo(() => hasKpiOverrideTimeframe(parentKpi), [parentKpi]);
      const isTimeframeFromPage = React.useMemo(() => (!kpi.hasIn(["overrideTimeframe"])
          || kpi.getIn(["overrideTimeframe", "type"])
          === "NONE") && !parentHasOverrideTimeframe, [kpi, parentHasOverrideTimeframe]);

      const getDefaultInheritKeysDefault = () => Immutable.Set([
        ...(!kpi.hasIn(["config", "entityToOwnerTypes"]) || Object.keys(kpi.getIn([
          "config",
          "entityToOwnerTypes"])).length === 0 ? ["entityToOwnerTypes"] : []),
        ...(!kpi.hasIn(["config", "dateEntityColumnId"]) ? ["dateEntityColumnId"] : []),
        ...(!kpi.hasIn(["config", "aggregationType"]) ? ["aggregationType"] : []),
        ...(!kpi.hasIn(["config", "sumColumn"]) ? ["sumColumn"] : []),
        ...(!kpi.hasIn(["config", "uniqueColumn"]) ? ["uniqueColumn"] : []),
        ...(!kpi.hasIn(["config", "valueColumn"]) ? ["valueColumn"] : []),
        ...(!kpi.has("overrideTimeframe") || kpi.getIn(["overrideTimeframe", "type"]) === "NONE" ? ["timeframe"] : [])
      ]);

      const [inheritKeys, setInheritKeys] = React.useState(isCombined
          ? getDefaultInheritKeysDefault()
          : Immutable.Set());
      const toggleInheritance = (key) => {
        if (inheritKeys.includes(key)) {
          setInheritKeys(inheritKeys.remove(key));
          if (key === "timeframe") {
            updateCustomTimeframe(parentKpi.getIn(["overrideTimeframe"]));
          }
          onChange(kpi.setIn(["config", key], parentKpi.getIn(["config", key])));
        } else {
          setInheritKeys(inheritKeys.add(key));
          if (key === "timeframe") {
            onChange(kpi.set("overrideTimeframe", Immutable.fromJS({type: "NONE"})));
          } else {
            onChange(kpi.deleteIn(["config", key]));
          }
        }
      };

      const updateCustomTimeframe = (timeframe) => {
        onChange(kpi.set("overrideTimeframe", timeframe.getImmutableTimeframe()));
      };
      const getKpiOrParentConfigValue = (inheritKey, customKey) =>
          isCombined && inheritKeys.has(inheritKey)
              ? parentKpi.getIn(["config", customKey || inheritKey])
              : kpi.getIn(["config", customKey || inheritKey]);

      const getAggregationColumnKpiOrParentConfigValue = (inheritKey, customKey) =>
          isCombined && inheritKeys.has("aggregationType") && inheritKeys.has(inheritKey)
              ? parentKpi.getIn(["config", customKey || inheritKey])
              : kpi.getIn(["config", customKey || inheritKey]);


      const toggleCustomTimeframe = () => {
        if (!isTimeframeFromPage) {
          onChange(kpi.set("overrideTimeframe", Immutable.fromJS({type: "NONE"})));
        } else {
          updateCustomTimeframe(timeframeRepo.getDefaultForClient());
        }
      };


      React.useCallback(() => {
        if (!isCombined) {
          setInheritKeys(Immutable.Set());
        }
      }, [isCombined]);

      const parentKpis = kpiIdToDependentKpis
          .get(kpi.get("id"), Immutable.List());

      const kpiIsCombinationParent = !parentKpis
          .filter(kpi => kpi.get("dependencyType") === "COMBINATION")
          .isEmpty();

      // Make server columns column picker compatible and do string replacement
      const additionalServerColumns = Immutable.List(
          availableServerColumns
              ?.filter(sc => {
                if ((!sumSplitAvailable && !isCube19User)) {
                  const scColumnType = sc.get("type");
                  return scColumnType !== "ID_FOR_SPLIT_SUM";
                }
                return true;
              }).map(sc => {
                const typeForLookup = sc.get("type") === "ID" ? kpi.get("readOnlyRootGroupingEntity") : sc.get("type")
                    .replace(/_ID$/, "");
                return sc
                    .set("name", sc.get("type"))
                    .set("hidden", sc.get("hidden"))
                    .set("columnType", "SERVER_COLUMN")
                    .set("label", sc.get("uiLabel") ? replaceEntityName(
                        sc.get("uiLabel"),
                        typeForLookup,
                        entityTypeToLabel) : sc.get("type"))
                    .set("readableJoinPath", Immutable.List(["System Columns"]));
              }
          )
      );

      const uniqueColumns = filterableColumns.concat(additionalServerColumns).filter(c => {
        const isServerColumn = c.get("columnType") === "SERVER_COLUMN";
        const isServerColumnVisible = isServerColumn && !c.get("hidden"); // Check hidden only for SERVER_COLUMN
        const isSelectedColumn = c.get("name") === getKpiOrParentConfigValue("uniqueColumn")?.get("name");
        return (!isServerColumn || isServerColumnVisible) || isSelectedColumn;
      });

      const isChildKpi = kpi.get("combineWithKpiId") != null;
      const combineErrorText = (parentKpi && !parentKpi.get("config"))
          ? "This metric is inheriting from a metric that is in a legacy format. Reach out to support to have this metric corrected."
          : "Inheritance failure. Reach out to support to have this metric corrected.";
      const kpiIsComponentOfForwardReport = parentKpis.some(kpi => kpi.get("dependencyType") === "FORWARD_REPORT");
      const useTimeframeFromPage = "Use Timeframe from Page";
      const timeframeLabelTooltip = "By choosing this option, metrics will always reflect data from the timeframe selected on the page you are on. To choose an override timeframe for this metric, uncheck this box and select a timeframe from the drop-down. For example, you may override the timeframe if using a metric to track sales during an incentive period.";
      const template = idToTemplate.get(kpi.get("templateId")) || Immutable.Map();
      const id = uuid();
      const isTrendable = combinedKpi.get("overrideTrendable") === null
          ? template.get("trendable")
          : combinedKpi.get("overrideTrendable");
      const descriptionTooltipId = "description-tooltip-" + id;
      const descriptionFallback = "Metric Type description is unavailable. Reach out to Bullhorn Support if you have questions about your metric configuration.";


      const tooltipBodyStyle = {
        fontSize: "0.8rem",
        textTransform: "none",
        maxWidth: 300,
        margin: 0,
        padding: 0
      };

      const descriptionContainerStyle = {
        fontSize: 12,
        maxHeight: 42,
        display: "-webkit-box",
        maxWidth: "calc(100%)",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        lineHeight: "1rem",
        padding: "5px 8px",
        paddingBottom: "0.2rem",
        marginTop: 3,
        marginBottom: 0,
        borderRadius: 4,
        opacity: 0.8,
        zIndex: 1,
        background: theme.themeId === "light"
            ? theme.palette.background.paper
            : theme.palette.background.card,
        cursor: "not-allowed"
      };

      return (
          <div>
            {!kpi.get("enabled") && <div style={{padding: 10}}>
              <Hint style={{marginBottom: 0}}>
                <Icon icon="info" style={{color: theme.palette.hints.text}} />
                <span>This metric has been disabled due to performance issues. {Branding.submitTicketInstructions}</span>
              </Hint>
            </div>}
            {isKpiEditable(kpi, idToTemplate)
                ? <>
                  {kpiIsCombinationParent &&
                      <div style={{padding: 10}}>
                        <Hint style={{marginBottom: 0}}>
                          <Icon icon="info" style={{color: theme.palette.hints.text}} />
                          <span>Other metrics are inheriting settings from this one.
                                Changes to this metric will impact those metrics that are set up to inherit configuration from this one.
                                Review the Relationships tab to understand how other metrics may be impacted.
                            </span>
                        </Hint>
                      </div>}
                  {isChildKpi && (combineError
                          ? <div style={{padding: 10}}>
                            <Hint
                                style={{
                                  marginBottom: 0,
                                  backgroundColor: theme.palette.error.background,
                                  borderColor: theme.palette.error.main
                                }}>
                              <Icon icon="caution" style={{color: theme.palette.hints.text}} />
                              <span>{combineErrorText}</span>
                            </Hint>
                          </div>
                          : <div style={{padding: 10}}>
                            <Hint style={{marginBottom: 0}}>
                              <Icon icon="info" style={{color: theme.palette.hints.text}} />
                              <span>You are viewing an inherited metric. This metric inherits some of its properties from
                                  <span style={{fontWeight: "bold"}}>&nbsp;{parentKpi.get("name")}</span>.
                                  Use the options below to select which fields you wish to override.
                              </span>
                            </Hint>
                          </div>
                  )}
                  {kpiIsComponentOfForwardReport &&
                      <div style={{padding: 10}}>
                        <Hint style={{marginBottom: 0}}>
                          <Icon icon="info" style={{color: theme.palette.hints.text}} />
                          <span>You are viewing a metric that is part of an actionable insight.
                                  Review the Relationships tab to understand how other metrics may be impacted.</span>
                        </Hint>
                      </div>}
                  <TabSection
                      autoExpand
                      title="setup"
                      description="Define initial parameters for your new metric.">
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                      <div style={fieldGridStyle}>
                        <MetricTemplatePicker
                            label="Metric Type"
                            labelTooltip="Select the metric type that you would like this metric to be based on."
                            renderItemDescription
                            entityTypeToLabel={entityTypeToLabel}
                            selectedEntityType={entityType}
                            templateId={kpi.get("templateId", null)}
                            showItemDescriptions
                            onChange={(id) => handleTemplateChange(id)}
                            idToTemplate={idToTemplate
                                .filter(t => (!t.get("hidden") && t.get("grouping_entity") === entityType)
                                    || t.get("id")
                                    === kpi.get("templateId"))}
                        />
                      </div>
                      <div style={fieldGridStyle} data-tip="" data-for={descriptionTooltipId}>
                        <span style={{fontSize: "0.8rem"}}>Metric Type Description</span>
                        <p
                            style={descriptionContainerStyle}>{template.get("description") ? replaceEntityName(
                            template.get("description"),
                            entityType,
                            entityTypeToLabel) : descriptionFallback}
                        </p>
                        {template.get("description") &&
                            <ReactTooltip
                                textColor={theme.palette.text.inverted}
                                backgroundColor={theme.palette.background.inverted}
                                place="top"
                                effect="solid"
                                id={descriptionTooltipId}>
                              <p style={tooltipBodyStyle}>{replaceEntityName(
                                  template.get("description"),
                                  entityType,
                                  entityTypeToLabel)}</p>
                            </ReactTooltip>}
                      </div>
                      <div
                          data-test-id="config-date-section"
                          style={fieldGridStyle}
                          title={(!isOverridableDateColumn || isCustomDateColumn) && dateTooltip}>
                        {isCombined && <InheritSwitch
                            style={{position: "absolute"}}
                            disabled={!isOverridableDateColumn || isCustomDateColumn}
                            section={"metric"}
                            inheritKey="dateEntityColumnId"
                            inheritKeys={inheritKeys}
                            onToggle={toggleInheritance} />}
                        <EntityAndServerColumnPicker
                            entityColumnId={isCombined && inheritKeys.has("dateEntityColumnId")
                                ? parentKpi?.getIn(["config", "dateEntityColumnId"])
                                : kpi?.getIn(["config", "dateEntityColumnId"])}
                            joinPath={isCombined && inheritKeys.has("dateEntityColumnId") ? parentKpi?.getIn([
                              "config",
                              "dateJoinPath"]) : kpi?.getIn(["config", "dateJoinPath"])}
                            columns={dateColumns}
                            label="Date"
                            labelTooltip="Select the date field that this metric should use to determine whether it should be included in your selected timeframe."
                            disabled={!isOverridableDateColumn || isCustomDateColumn || (isCombined && inheritKeys.has(
                                "dateEntityColumnId"))}
                            placeholder={"Default"}
                            title={dateTooltip}
                            onColumnSelect={handleDateEntityChange} />
                      </div>
                      <div style={fieldGridStyle}>
                        <ConfigInheritFrom
                            label="Inherits from"
                            labelTooltip="Select an existing metric to inherit settings from. This metric will inherit settings and filters from the parent metric and will be impacted by any changes made to the parent metric."
                            kpi={kpi}
                            kpis={kpisAvailableToCombineWith}
                            onKpiChange={onKpiChange}
                            kpiIdToDependentKpis={kpiIdToDependentKpis}
                            cyclingKpis={cyclingKpis}
                            rootNodes={rootNodes} />
                      </div>
                      <div style={fieldGridStyle}>
                        {isCombined && <InheritSwitch
                            style={{position: "absolute", marginTop: -1}}
                            section={"metric"}
                            inheritKey="entityToOwnerTypes"
                            inheritKeys={inheritKeys}
                            onToggle={toggleInheritance} />}
                        <ConfigOwnership
                            kpi={isCombined && inheritKeys.has("entityToOwnerTypes") ? parentKpi : kpi}
                            availableOwners={availableOwners}
                            entityTypeToLabel={entityTypeToLabel}
                            disabled={isCombined && inheritKeys.has("entityToOwnerTypes")}
                            onChange={handleOwnershipChange} />
                      </div>
                    </div>
                    {template.get("dateType") !== "INSTANT" &&
                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                          <div style={fieldGridStyle && {width: "100%"}}>
                            <div style={{display: "flex", width: 276, margin: "0.25rem 0rem"}}>
                              <Checkbox
                                  label={useTimeframeFromPage}
                                  tooltip={timeframeLabelTooltip}
                                  style={{marginRight: 0}}
                                  checked={isTimeframeFromPage && (!parentHasOverrideTimeframe || !inheritKeys.has(
                                      "timeframe"))}
                                  onCheck={toggleCustomTimeframe}
                                  disabled={parentHasOverrideTimeframe} />
                            </div>
                          </div>
                          {!isTimeframeFromPage &&
                              <div style={fieldGridStyle}>
                                {isCombined && <InheritSwitch
                                    style={{position: "absolute", marginTop: -1}}
                                    section={"metric"}
                                    inheritKey="timeframe"
                                    inheritKeys={inheritKeys}
                                    onToggle={toggleInheritance} />}
                                <TimeframePicker
                                    label={"Override Using"}
                                    timeframe={(isCombined && inheritKeys.has("timeframe"))
                                        ? (hasKpiOverrideTimeframe(parentKpi) ? timeframeRepo.parseFromImmutable(
                                            parentKpi.get("overrideTimeframe")) : timeframeRepo.getDefaultForClient())
                                        : (hasKpiOverrideTimeframe(kpi) ? timeframeRepo.parseFromImmutable(kpi.get(
                                            "overrideTimeframe")) : timeframeRepo.getDefaultForClient())}
                                    onChange={updateCustomTimeframe}
                                    isDisabled={isTimeframeFromPage || (isCombined && inheritKeys.has("timeframe"))} />
                              </div>
                          }
                        </div>
                    }
                  </TabSection>
                  <TabSection
                      autoExpand
                      title="Aggregation"
                      description="Configure how your metric will show when turned into a summary value, a trend and a leaderboard.">
                    <ConfigAggregation
                        selectedAggregationType={getKpiOrParentConfigValue("aggregationType")}
                        selectedTrendType={kpi.getIn(["config", "trendType"])}
                        sumEntityColumn={getAggregationColumnKpiOrParentConfigValue("sumColumn")}
                        uniqueEntityColumn={getAggregationColumnKpiOrParentConfigValue("uniqueColumn")}
                        valueEntityColumnId={getAggregationColumnKpiOrParentConfigValue("valueColumn", "valueEntityColumnId")}
                        valueJoinPath={getAggregationColumnKpiOrParentConfigValue("valueColumn", "valueJoinPath")}
                        queryType={template.get("queryType")}
                        entityType={entityType}
                        fieldGridStyle={fieldGridStyle}
                        inheritKeys={inheritKeys.includes("aggregationType") ?
                            inheritKeys :
                            inheritKeys
                                .remove("sumColumn")
                                .remove("uniqueColumn")
                                .remove("valueColumn")}
                        isCombined={isCombined}
                        isTrendable={isTrendable}
                        sumAndValueColumns={sumAndValueColumns}
                        uniqueColumns={uniqueColumns}
                        sumDoubleSplitsAvailable={sumDoubleSplitsAvailable}
                        sumSplitAvailable={sumSplitAvailable}
                        onAggregationTypeChange={handleAggregationTypeChange}
                        onTrendTypeChange={handleTrendTypeChange}
                        toggleInheritance={toggleInheritance}
                        onSumEntityColumnChange={handleSumEntityColumnChange}
                        onUniqueEntityColumnChange={handleUniqueEntityColumnChange}
                        onValueEntityColumnChange={handleValueEntityColumnChange}
                        aggregationTypes={aggregationTypes}
                        trendTypes={trendTypes}
                    />
                  </TabSection>
                  <TabSection
                      autoExpand
                      title="filter"
                      description="Create a filter to narrow down the results of this metric. Conditions can be combined with AND or OR statements and may include up to 3 nested groups.">
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                      {isCombined && parentKpi && <div style={{...fieldGridStyle, width: "100%"}}>
                        {parentKpi?.getIn(["config", "filter"])
                            && renderFilterSectionTitle(
                                inheritedFiltersSectionTitle,
                                "Inherited filters cannot be edited and are set on the parent metric.")}
                        <Filters
                            onChange={handleFiltersChange}
                            columns={filterableColumns}
                            filter={parentKpi?.getIn(["config", "filter"]) || undefined}
                            readOnly
                        />
                      </div>}
                      <div style={{...fieldGridStyle, width: "100%"}}>
                        {parentKpi?.getIn(["config", "filter"]) && renderFilterSectionTitle(
                            filtersSectionTitle,
                            "Metric-specific filters can be combined with inherited metric filters to further narrow down results of this metric.")}
                        <Filters
                            allowAutofocus={allowAutoFocus}
                            onChange={handleFiltersChange}
                            columns={filterableColumns}
                            filter={kpi.getIn(["config", "filter"]) || undefined} />
                      </div>
                    </div>
                  </TabSection>
                </>
                : notNewConfigTypeFallback(theme)
            }
          </div>
      );
    });

export default Config;
