import * as Immutable from "immutable";

import * as Ajax from "js/common/ajax";

const path = window.path;

const loadKpis = () => Ajax
  .get({url: "kpi"})
  .then(kpis => Immutable.fromJS(kpis));

const jsonObjectQueryParamKeys = Immutable.Set.of("forwardReport");
const jsonArrayQueryParamKeys = Immutable.Set.of("coreTypeFilters", "dynamicFilter", "idQueryJoinPaths", "kpisToSum", "ownedByLinkedEntities");

const jsonQueryParamKeys = jsonObjectQueryParamKeys.union(jsonArrayQueryParamKeys);

const isNodeValid = (filterNode) => {
  if (filterNode.get("type") === "CONDITION") {
    const operator = filterNode.get("operator");
    const value = filterNode.get("value");
    if (operator === "IS_BLANK" || operator === "NOT_BLANK") {
      return true
    } else {
      return (!!value || value === 0) && !(Immutable.isList(value) && value.isEmpty()) && value !== "Invalid date";
    }
  }
  return filterNode.get("type") === "GROUP" && !filterNode.get("children").isEmpty();
};

const stripInvalidNodes = (groupNode) => groupNode.update("children", children =>
    children.map(node => (node.get("type") === "GROUP" ? stripInvalidNodes(node) : node)).filter(isNodeValid)
);

const cleanFilters = (filter) => {
  if (!filter) { return filter; }
  return stripInvalidNodes(filter);
};
const parseQueryParams = params => {
  return jsonQueryParamKeys.reduce(
    (params, key) => {
      if (params.has(key)) {
        return params.update(key, param => {
          try {
            return Immutable.fromJS(JSON.parse(param));
          } catch (e) {
            console.error("unable to parse query param", key, param, e);
            if (jsonObjectQueryParamKeys.has(key)) {
              return Immutable.fromJS({error: e, invalidJsonString: param});
            } else if (jsonArrayQueryParamKeys.has(key)) {
              return Immutable.fromJS([{error: e, invalidJsonString: param}]);
            } else {
              throw new Error("assumed unreacheable branch, json query param must be either object or array");
            }
          }
        });
      } else {
        return params;
      }
    },
    params || Immutable.Map());
};

const parseKpi = kpi => kpi.update("queryParams", parseQueryParams);

const parseKpiResponse = kpisJson => Immutable.fromJS(kpisJson).map(parseKpi);

const parseSubmitResponse = response => Immutable.fromJS(response)
    .update("kpisAndErrors", kpisAndErrors => kpisAndErrors.map(kpiAndError => kpiAndError.update("config", parseKpi)));

const formatQueryParams = params => {
  return jsonQueryParamKeys.reduce(
    (params, key) => {
      if (params.has(key)) {
        return params.update(key, param => JSON.stringify(param.toJS()));
      } else {
        return params;
      }
    },
    params || Immutable.Map());
};
const formatConfig = (config) => {
  if (!config) {
    return config;
  }
  return config.update("filter", cleanFilters);
};

const formatKpi = kpi => kpi
    .update("queryParams", formatQueryParams)
    .update("config", formatConfig);


const loadEditableKpis = () => Ajax
  .get({url: "kpi/for-editing"})
  .then(parseKpiResponse);

const loadEntities = () => Ajax
    .get({url: "kpi/root-entities"})
    .then(entities => Immutable.fromJS(entities));

const loadTemplates = () => Ajax
  .get({url: "kpi/templates"})
  .then(templates => Immutable.fromJS(templates));

const loadActionTypes = () => Ajax
  .get({url: "kpi/action-types"})
  .then(res => Immutable.Set(res));

const submitKpis = (kpisWithColumns, reason = "") => Ajax
    .put({url: "kpi", json: {
      reason,
      kpis: kpisWithColumns.map(kpiWithColumns => kpiWithColumns.update("config", formatKpi)).toJS()
    }})
    .then(parseSubmitResponse);

const updateExplanation = (kpiId, explanation, reason) => Ajax
    .put({url: "kpi/explanation", json: {kpiId, explanation, reason}})
    .then(kpi => parseKpi(Immutable.fromJS(kpi)));

const loadSpeculativeChange = (kpi, previousKpi) => Ajax
  .put({url: "kpi/speculative-change", json: {
      kpi: formatKpi(kpi),
      previousKpi: previousKpi && formatKpi(previousKpi)
  }})
  .then(res => Immutable.fromJS(res).update("kpi", parseKpi));

const loadRevertToLegacyFormat = kpi => Ajax
    .put({url: "kpi/legacy-format", json: formatKpi(kpi)})
    .then(res => parseKpi(Immutable.fromJS(res)));

const loadComplexColumnsChange = (kpi, columns) => Ajax
    .put({url: "kpi/complex-columns", json: {kpi: formatKpi(kpi), columns}})
    .then(res => Immutable.fromJS(res));

const loadColumnsForKpi = kpiId => Ajax
  .get({url: path("kpi", kpiId, "columns")})
  .then(response => Immutable.fromJS(response));

const loadAvailableOwnersForKpi = kpiId => Ajax
    .get({url: path("kpi/available-owners/", kpiId)})
    .then(response => Immutable.fromJS(response));

const loadColumnsForEntityPaths = entityPaths => Ajax
    .put({url: "data-explorer/columns-for-entity-paths", json: entityPaths.toJS()})
    .then(res => Immutable.fromJS(res));

const loadEntityValues = (entityType) => Ajax
    .get({url: path("kpi", "entity-values", entityType)})
    .then(res => Immutable.fromJS(res));

const loadEntityColumnsForEntityPaths = entityPaths => Ajax
    .put({url: "data-explorer/entity-columns-for-entity-paths", json: entityPaths.toJS()})
    .then(res => Immutable.fromJS(res));

const loadMetabaseColumnsForEntityPaths = entityPaths => Ajax
    .put({url: "data-explorer/metabase-columns-for-entity-paths", json: entityPaths.toJS()})
    .then(res => Immutable.fromJS(res));
const loadCombination = (kpi, modifiedKpis) => Ajax
      .put({
        url: "kpi/combine",
        json: {kpi: formatKpi(kpi).delete("id"), modifiedKpis: modifiedKpis.map(formatKpi)}
      })
      .then(res => parseKpi(Immutable.fromJS(res)));

const loadEditorOptions = () => Ajax
    .get({url: path("kpi", "editor-options")})
    .then(res => Immutable.fromJS(res));

const loadServerColumnsForKpi = (kpiId) => Ajax
    .get({url: path("kpi/server-columns", kpiId)})
    .then(res => Immutable.fromJS(res));

export {
  loadKpis,
  loadEditableKpis,
  loadEntities,
  loadTemplates,
  loadActionTypes,
  submitKpis,
  loadSpeculativeChange,
  loadRevertToLegacyFormat,
  loadComplexColumnsChange,
  loadColumnsForKpi,
  loadAvailableOwnersForKpi,
  loadColumnsForEntityPaths,
  loadEntityColumnsForEntityPaths,
  loadMetabaseColumnsForEntityPaths,
  parseKpi,
  formatKpi,
  loadCombination,
  loadEntityValues,
  updateExplanation,
  loadEditorOptions,
  loadServerColumnsForKpi
};