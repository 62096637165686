import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import store from "store";
import dimensions from "react-dimensions";
import SelectField from "@mui/material/Select";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import pure from "js/common/views/pure";
import ErrorBoundary from "js/common/views/error-boundary";
import NumberField from "js/common/views/inputs/number-field";
import MenuBar from "js/common/views/menu-bar";
import {Layout} from "js/common/views/foundation-column-layout";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import {TextButton} from "js/common/views/inputs/buttons";
import Select from "js/common/views/inputs/immutable-react-select";
import Dialog from "js/common/views/tabs-dialog";
import DataDetailsTable from "js/oneview/kpi-details/data-details-table";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import LoadingSpinner from "js/common/views/loading-spinner";
import Info from "js/common/views/info-text-box";
import Paginator from "js/job-pipelines/paginator";
import MultiSortToolbar from "js/job-pipelines/multi-sort-toolbar";
import TwoOptionToggle from "js/common/views/inputs/two-option-toggle";
import ScrollInfoPopup from "js/job-pipelines/scroll-info-popup";
import VisibleColumnPicker from "js/job-pipelines/visible-column-picker";
import DayRangeSlider from "js/job-pipelines/day-range-slider";
import JobStatsBarChart from "js/job-pipelines/job-count-bar-chart";
import {candidateNameWidth, CandidateRowSvg, candidateStageWidth} from "js/job-pipelines/candidate-row-svg";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Colors from "js/common/cube19-colors";
import * as kpiCalculator from "js/common/kpi-calculator";
import * as Formatter from "js/common/utils/formatter";
import * as auditor from "js/common/auditer";
import * as numbers from "js/common/utils/numbers";
import currentClient from "js/common/repo/backbone/current-client";
import {leftPad} from "js/common/utils/strings";
import {directionToSortIcon, makeComparator, nextDirection} from "js/job-pipelines/sort-utils";
import {getUserAgent} from "js/common/ua-parser";
import {loadData, loadPipelines, loadStages, updateJob} from "js/job-pipelines/data-loading";
import {dayWord, formatDate, formatDateForDisplay, getTimeAgo, parseDate} from "js/job-pipelines/date-utils";
import {TextField, MenuItem, FormControl, InputLabel} from "@mui/material";
import Checkbox from "js/common/views/inputs/checkbox";
import {getActivityCategories, filterJobRowByActivity} from "js/job-pipelines/job-activity";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import {maybeBackboneToImmutable} from "js/common/utils/backbone-migration";
import PipelineActivityFilter from "js/job-pipelines/pipeline-activity-filter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as NovoComms from "js/common/utils/novo-comms";
import Tooltip from "react-tooltip";

const thinOverviewTextStyle = (theme) => ({
  display: "inline-block",
  fontSize: "0.9rem",
  whiteSpace: "nowrap",
  padding: "0.5rem",
  fontWeight: "400",
  textOverflow: "ellipsis",
  overflow: "hidden",
  color: theme.palette.primary.main
});


const thinOverviewHeaderStyle = (theme, tableWidthRem) => ({
  background: theme.themeId === "light" ? "white" : "rgba(0,0,0,0.4)",
  borderRadius: theme.themeId === "light" ? "0" : "0.2rem",
  marginBottom: theme.themeId === "light" ? "0" : "0.5rem",
  borderBottom: "none",
  whiteSpace: "nowrap",
  width: tableWidthRem + "rem",
  color: theme.themeId === "light" ? theme.palette.text.main : theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  minHeight: 50
});

const thinRowHeight = 35;
const thinOverviewStageDataStyle = {
  display: "inline-block",
  fontSize: "0.9rem",
  whiteSpace: "pre-line",
  padding: "0.1rem 0.5rem",

  height: thinRowHeight,
  textAlign: "center",
  verticalAlign: "top"
};

const expandColumnWidth = 1.6;
const stageTextWidth = 8.5;

const pagePadding = 2;

const indexBy = (f, xs) => xs.groupBy(f).map(xs => xs.first());

const handleJobTitleClick = (crmEntityName, originalCrmId) => {
  NovoComms.open(crmEntityName, originalCrmId);
};

// TODO take columns from click through
// TODO simplify sort / title and display fns by having types (string, num, date, custom)
const simpleColumns = Immutable.fromJS([
  {
    id: "JOB_ID",
    label: "Job ID",
    searchable: true,
    width: 5,
    sortFn: (jobRow, job) => job.get("crmId"),
    titleFn: (jobRow, job) => job.get("crmId"),
    displayFn: (jobRow, job) => job.get("crmId")
  }, {
    id: "CLIENT",
    label: "Client",
    searchable: true,
    width: 12,
    sortFn: jobRow => jobRow.getIn(["client", "name"], "").trim().toLowerCase(),
    titleFn: jobRow => jobRow.getIn(["client", "name"], ""),
    displayFn: jobRow => jobRow.getIn(["client", "name"], "")
  }, {
    id: "CLIENT_CONTACT",
    label: "Contact",
    searchable: true,
    width: 11,
    sortFn: jobRow => jobRow.getIn(["clientContact", "name"], "").trim().toLowerCase(),
    titleFn: jobRow => jobRow.getIn(["clientContact", "name"], ""),
    displayFn: jobRow => jobRow.getIn(["clientContact", "name"], "")
  }, {
    id: "JOB_TITLE",
    label: "Job",
    searchable: true,
    width: 17,
    required: true,
    sortFn: (jobRow, job) => job.get("title").trim().toLowerCase(),
    titleFn: (jobRow, job) => job.get("title"),
    displayFn: (jobRow, job, theme) => {
      const isLightMode = theme.themeId === "light";
      const isBullhornCrm = currentClient.isBullhornCrm();
      const displayStyle = isBullhornCrm ? {
        color: theme.palette.primary.main,
        cursor: "pointer",
        verticalAlign: "bottom"
      } : {verticalAlign: "bottom"};
      let crmLink;
      if (currentClient.get("crmId") === 31) {
        const url = `https://login.salesforce.com/${job.get("crmId")}`;
        const icon = "https://www.bullhorn.com/wp-content/uploads/2018/10/bf4force.png";
        crmLink = (
            <a
                style={{paddingRight: "0.5rem"}}
                target="_blank"
                onClick={() => auditor.audit("job-pipeline:crm-link", {jobId: job.get("crmId"), url})}
                href={url}>
              <img style={{width: "1rem", height: "1rem"}} src={icon} />
            </a>);
      } else {
        crmLink = null;
      }
      return (
          <span>
        {crmLink}
            {jobRow.get("noMatchingPipeline") && <i
                style={{
                  color: isLightMode ? "#ff7400" : "yellow",
                  paddingRight: "0.4rem",
                  position: "relative",
                  bottom: -1
                }}
                className="fa fa-exclamation-triangle"
                alt="This job did not match any pipeline, so we put it here by default"
                title="This job did not match any pipeline, so we put it here by default" />}
            <span
                data-tip
                data-for={"job-" + job.get("crmId")}
                style={displayStyle}
                onClick={() => isBullhornCrm && handleJobTitleClick("JobOrder", job.get("crmId"))}>
              {job.get("title")}
            </span>
            {isBullhornCrm &&
                <Tooltip id={"job-" + job.get("crmId")} type={isLightMode ? "dark" : "light"} place="top">
                  <div style={{width: 200, whiteSpace: "normal"}}>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: 5, marginTop: 2}}>
                      <span style={{fontWeight: "bold"}}>{job.get("title")}</span><span>ID: #{job.get("crmId")}</span>
                    </div>
                    Clicking this link will navigate you to the Bullhorn ATS JobOrder record in a new tab.
                  </div>
                </Tooltip>}
      </span>);
    }
  }, {
    id: "JOB_OPENINGS",
    label: "Openings",
    searchable: false,
    width: 8,
    sortFn: (jobRow, job) => job.get("openings"),
    titleFn: (jobRow, job) => job.get("openings"),
    displayFn: (jobRow, job) => job.get("openings")
  }, {
    id: "OWNER",
    label: "Job Owner",
    searchable: true,
    width: 8,
    sortFn: (jobRow, job) => getImmutableUser(job.get("userId")).get("fullName", "").trim().toLowerCase(),
    titleFn: (jobRow, job) => getImmutableUser(job.get("userId")).get("fullName", ""),
    displayFn: (jobRow, job) => getImmutableUser(job.get("userId")).get("fullName", "")
  }, {
    id: "JOB_VALUE",
    label: "Exp. Value",
    searchable: false,
    width: 8,
    sortFn: (jobRow, job) => job.get("value"),
    titleFn: (jobRow, job) => job.get("value") ?
        Formatter.format({currency: job.get("currencyCode"), value: job.get("value")}) :
        "",
    displayFn: (jobRow, job) => job.get("value") ?
        Formatter.format({currency: job.get("currencyCode"), value: job.get("value")}) :
        ""
  }, {
    id: "JOB_WEIGHTING",
    label: "Weight",
    searchable: false,
    width: 5,
    sortFn: (jobRow, job) => jobRow.get("weighting"),
    titleFn: (jobRow, job) => numbers.toPercentageStr(jobRow.get("weighting"), 1),
    displayFn: (jobRow, job) => numbers.toPercentageStr(jobRow.get("weighting"), 1)
  }, {
    id: "WEIGHTED_JOB_VALUE",
    label: "Weighted Exp. Value",
    searchable: false,
    width: 10,
    sortFn: (jobRow, job) => job.get("value") * jobRow.get("weighting"),
    titleFn: (jobRow, job) => job.get("value") ?
        Formatter.format({currency: job.get("currencyCode"), value: job.get("value") * jobRow.get("weighting")}) :
        "",
    displayFn: (jobRow, job) => job.get("value") ?
        Formatter.format({currency: job.get("currencyCode"), value: job.get("value") * jobRow.get("weighting")}) :
        ""
  }, {
    id: "JOB_CLOSE_DATE",
    label: "Exp. Placement Date",
    searchable: false,
    width: 10,
    sortFn: (jobRow, job) => {
      const date = job.get("closeDate") || job.get("createdDate");
      return (job.get("closeDate") ? 0 : 1)
          + "_" + (Number.MAX_SAFE_INTEGER - date.valueOf());
    },
    titleFn: (jobRow, job) => job.get("closeDate") ? formatDateForDisplay(job.get("closeDate")) : "",
    displayFn: (jobRow, job) => job.get("closeDate") ? formatDateForDisplay(job.get("closeDate")) : ""
  }, {
    id: "JOB_ADDED_DATE",
    label: "Job Added",
    searchable: false,
    width: 8,
    sortFn: (jobRow, job) => -job.get("createdDate").valueOf(),
    titleFn: (jobRow, job) => formatDateForDisplay(job.get("createdDate")),
    displayFn: (jobRow, job) => getTimeAgo(job.get("createdDate"))
  }, {
    id: "LATEST_ACTIVITY_DATE",
    label: "Latest Activity",
    searchable: false,
    width: 8.5,
    sortFn: (jobRow, job) => {
      const date = jobRow.get("lastActivityDate") || job.get("createdDate");
      return (jobRow.get("lastActivityDate") ? 0 : 1)
          + "_" + (Number.MAX_SAFE_INTEGER - date.valueOf());
    },
    titleFn: jobRow => jobRow.get("lastActivityDate") && formatDateForDisplay(jobRow.get("lastActivityDate")),
    displayFn: jobRow => jobRow.get("lastActivityDate") && getTimeAgo(jobRow.get("lastActivityDate"))
  }, {
    id: "PRIORITY",
    label: "Priority",
    searchable: true,
    width: 5,
    sortFn: (jobRow, job) => job.get("priority"),
    titleFn: (jobRow, job) => job.get("priority"),
    displayFn: (jobRow, job) => job.get("priority")
  }, {
    id: "CRM_STATUS",
    label: "CRM Status",
    searchable: true,
    width: 8,
    sortFn: (jobRow, job) => job.get("crmStatus"),
    titleFn: (jobRow, job) => job.get("crmStatus"),
    displayFn: (jobRow, job) => job.get("crmStatus")
  }]);

const idToColumn = indexBy(c => c.get("id"), simpleColumns);

const stageSortFn = (jobRow, job, sort) => {
  const stageId = sort.get("stageId");
  const stageIdToEntities = jobRow.get("stageIdToEntities");
  const stageIdToLatestEntityDate = jobRow.get("stageIdToLatestEntityDate");
  const date = stageIdToLatestEntityDate.get(stageId);
  const entities = stageIdToEntities.get(stageId, Immutable.List());
  if (sort.get("byEntityCount")) {
    return (entities.isEmpty() ? 1 : 0)
        + "_" + (Number.MAX_SAFE_INTEGER - leftPad(countUniqueIds(entities), 5))
        + "_" + (Number.MAX_SAFE_INTEGER - date.valueOf());
  } else {
    return (entities.isEmpty() ? 1 : 0)
        + "_" + (Number.MAX_SAFE_INTEGER - date.valueOf())
        + "_" + (Number.MAX_SAFE_INTEGER - leftPad(countUniqueIds(entities), 5));
  }
};

const columnIdToSortFn = simpleColumns
    .groupBy(c => c.get("id"))
    .map(cs => cs.map(c => c.get("sortFn")).first());

const getSortFnForColumnId = columnId => {
  if (columnId.indexOf("__STAGE_") === 0) {
    return stageSortFn;
  } else {
    return columnIdToSortFn.get(columnId);
  }
};

const defaultDropdownFilters = Immutable.fromJS([
  {
    id: "ALL",
    name: "All jobs",
    explanation: "All Jobs",
    fn: _ => true
  }, {
    id: "NO_LIVE_CANDIDATES",
    name: "Jobs with no live candidates",
    explanation: "Jobs with no live candidates",
    fn: jobRow => !jobRow.get("hasLiveCandidates") && !jobRow.get("candidateRows").isEmpty()
  }, {
    id: "NO_ACTIVITY",
    name: "Jobs with no activity",
    explanation: "Jobs with no activity",
    disableSlider: true,
    fn: jobRow => jobRow.get("candidateRows").isEmpty()
  }, {
    id: "LIVE_CANDIDATES",
    name: "Jobs with live candidates",
    explanation: "Jobs with live candidates",
    fn: jobRow => jobRow.get("hasLiveCandidates")
  }, {
    id: "PLACED_CANDIDATES",
    name: "Jobs with placed candidates",
    explanation: "Jobs with placed candidates",
    fn: jobRow => jobRow.get("hasPlacedCandidates")
  }]);

const storeGet = (k, notFound) => {
  const val = store.get(k);
  if (val === null || typeof (val) === "undefined") {
    return notFound;
  } else {
    return Immutable.fromJS(val);
  }
};
const storeSet = (k, v) => store.set(k, Immutable.isImmutable(v) ? v.toJS() : v);

const uniqueUserKey = key => "cube19.job-pipeline.user-id-" + getImmutableCurrentUser().get("id") + "." + key;

const getStoredIsSalesView = notFound => storeGet(uniqueUserKey("is-sales-view"), notFound);
const storeIsSalesView = flag => storeSet(uniqueUserKey("is-sales-view"), flag);

const getStoredLastUsedJobKpiId = () => storeGet(uniqueUserKey("job-kpi-id"));
const storeLastUsedJobKpiId = kpiId => storeSet(uniqueUserKey("job-kpi-id"), kpiId);

const getStoredJobKpiQualifier = () => storeGet(uniqueUserKey("job-kpi-qualifier"));
const storeJobKpiQualifier = qualifier => storeSet(uniqueUserKey("job-kpi-qualifier"), qualifier);

const getStoredScrollShortcutsPopupDismissed = () => storeGet(uniqueUserKey("scroll-shortcuts-popup-dismissed"));
const storeScrollShortcutsPopupDismissed = () => storeSet(uniqueUserKey("scroll-shortcuts-popup-dismissed"), true);

const getStoredActivityQualifier = () => storeGet(uniqueUserKey("activity-qualifier"));
const storeActivityQualifier = qualifier => storeSet(uniqueUserKey("activity-qualifier"), qualifier);

const getShouldIncludeActivityForOtherUsers = (notFound) => storeGet(
    uniqueUserKey("should-include-other-user-activity"),
    notFound);
const storeShouldIncludeActivityForOtherUsers = type => storeSet(
    uniqueUserKey("should-include-other-user-activity"),
    type);

const getStoredLastUsedDayRange = () => storeGet(uniqueUserKey("day-range"));
const storeLastUsedDayRange = dayRange => storeSet(uniqueUserKey("day-range"), dayRange);

const getStoredLastUsedJobDropdownFilterId = () => storeGet(uniqueUserKey("job-dropdown-filter-id"));
const storeLastUsedJobDropdownFilterId = dropdownFilterId => storeSet(
    uniqueUserKey("job-dropdown-filter-id"),
    dropdownFilterId);

const getStoredLastUsedTimeframe = () => {
  const lastTimeframe = storeGet(uniqueUserKey("timeframe"));
  if (!lastTimeframe) {
    return null;
  }

  if (lastTimeframe.get("type") === "custom") {
    return lastTimeframe.toJS();
  } else {
    return timeframeRepo.get(lastTimeframe.get("type")).getRawJson();
  }
};
const storeLastUsedTimeframe = timeframe => storeSet(uniqueUserKey("timeframe"), timeframe);

const getStoredPipelineIdToVisibleColumns = () => storeGet(uniqueUserKey("visible-columns"), Immutable.Map())
    .mapKeys(x => parseInt(x))
    .map(cs => cs.toSet());
const storePipelineIdToVisibleColumns = pipelineIdToVisibleColumns => storeSet(
    uniqueUserKey("visible-columns"),
    pipelineIdToVisibleColumns);

const getStoredPipelineIdToCollapseRowsState = () => storeGet(uniqueUserKey("collapse-rows"), Immutable.Map())
    .mapKeys(x => parseInt(x));
const storePipelineIdToCollapseRowsState = pipelineIdToCollapseRowsState => storeSet(
    uniqueUserKey("collapse-rows"),
    pipelineIdToCollapseRowsState);

export default () => (
    <ErrorBoundary>
      <Wrapper />
    </ErrorBoundary>
);

const getHighestQualifier = () => {
  const currentUser = getImmutableCurrentUser();
  if (currentUser.get("dataVisibility") === "SELF") {
    return Immutable.Map({id: currentUser.get("id"), type: "USER"});
  } else if (Users.isObserver(currentUser)) {
    return Immutable.Map({id: Groups.getRootGroup().get("id"), type: "GROUP"});
  } else {
    return Immutable.Map({id: Groups.getRootGroup().get("id"), type: "GROUP"});
  }
};

const getResourcerActivityQualifier = () => {
  const currentUser = getImmutableCurrentUser();
  const currentGroupId = currentUser.get("groupId");
  if (currentUser.get("dataVisibility") === "SELF") {
    return Immutable.Map({id: currentUser.get("id"), type: "USER"});
  }
  const storedActivityQualifier = getStoredActivityQualifier();
  if (storedActivityQualifier) {
    return storedActivityQualifier;
  } else if (Users.isObserver(currentUser)) {
    return Immutable.Map({id: currentGroupId, type: "GROUP"});
  } else {
    return Immutable.Map({id: currentUser.get("id"), type: "USER"});
  }
};

const getJobKpiQualifier = () => {
  const currentUser = getImmutableCurrentUser();
  const currentGroupId = currentUser.get("groupId");
  if (currentUser.get("dataVisibility") === "SELF") {
    return Immutable.Map({id: currentUser.get("id"), type: "USER"});
  }
  const storedJobKpiQualifier = getStoredJobKpiQualifier();
  if (storedJobKpiQualifier) {
    return storedJobKpiQualifier;
  } else if (Users.isObserver(currentUser)) {
    return Immutable.Map({id: currentGroupId, type: "GROUP"});
  } else {
    return Immutable.Map({id: currentUser.get("id"), type: "USER"});
  }
};

const Page = dimensions()(createReactClass({
  getInitialState() {
    const os = getUserAgent().getOS();
    const isWindowsDevice = os.name === "Windows";
    const isScrollShortcutsPopupDismissed = getStoredScrollShortcutsPopupDismissed();

    const sliderLimit = 91;

    return {
      pipelinesLoading: false,
      idToPipeline: Immutable.Map(),
      activityCategories: getActivityCategories(sliderLimit),

      timeframe: getStoredLastUsedTimeframe() || timeframeRepo.getDefaultForClient().getRawJson(),

      jobKpiQualifier: getJobKpiQualifier(),
      activityQualifier: getResourcerActivityQualifier(),
      shouldIncludeActivityForOtherUsers: getShouldIncludeActivityForOtherUsers(true),

      isSalesView: getStoredIsSalesView(true),

      pipelineIdToVisibleColumns: Immutable.Map(),
      pipelineIdToCollapseRowsState: Immutable.Map(),

      selectedJobKpiId: getStoredLastUsedJobKpiId(),
      jobFilterText: "",
      candidateFilterText: "",

      sliderLimit,
      dayRange: getStoredLastUsedDayRange() || Immutable.fromJS({min: 0, max: 7}),
      dropdownFilterId: getStoredLastUsedJobDropdownFilterId() || "ALL",

      pipelineIdToSortStageByCount: Immutable.Map(),

      pipelineIdToColumnIdToSort: Immutable.Map(),
      pipelineIdToMultiSort: Immutable.Map(),

      pipelineIdToCurrentPage: Immutable.Map(),
      rowsPerPage: 20,

      showDataDetails: false,
      dataDetailsLoading: false,
      dataDetails: Immutable.List(),
      dataDetailsForStage: null,

      dataLoading: false,
      pipelineIdToStages: Immutable.Map(),
      jobRows: Immutable.List(),
      filteredJobRows: Immutable.List(),
      idToJob: Immutable.Map(),

      pipelineIdToStats: null,

      jobIdToUiState: Immutable.Map(),

      showAllPipelinesToolbar: false,

      isScrollShortcutsPopupOpen: isWindowsDevice && !isScrollShortcutsPopupDismissed
    };
  },

  getActivityQualifier() {
    if (this.state.isSalesView) {
      return getHighestQualifier();
    } else {
      return this.state.activityQualifier;
    }
  },

  componentDidMount() {
    auditor.audit("job-pipeline:loaded");

    this.setState({pipelinesLoading: true});
    loadPipelines()
        .then(pipelines => {
          const idToPipeline = indexBy(p => p.get("id"), pipelines);
          const localPipelineIdToVisibleColumns = getStoredPipelineIdToVisibleColumns();
          const pipelineIdToVisibleColumns = idToPipeline
              .map(p => p.get("visibleColumns").toSet())
              .mergeWith(
                  (cs1, cs2) => cs2.isEmpty() ? cs1 : cs2,
                  localPipelineIdToVisibleColumns);
          const pipelineIdToColumnIdToSort = idToPipeline.map(pipeline => {
            return Immutable.fromJS({LATEST_ACTIVITY_DATE: {direction: "ASC", ordering: 0}});
          });
          const pipelineIdToSortStageByCount = idToPipeline.map(() => {return false;});
          const localPipelineIdToCollapseRowsState = getStoredPipelineIdToCollapseRowsState();
          const pipelineIdToCollapseRowsState = idToPipeline
              .map(p => localPipelineIdToCollapseRowsState.get(p.get("id")) || false);
          this.setState({
            idToPipeline,
            pipelineIdToVisibleColumns,
            pipelineIdToColumnIdToSort,
            pipelineIdToCollapseRowsState,
            pipelineIdToSortStageByCount,
            pipelinesLoading: false
          });
          return loadStages();
        })
        .then(stages => {
          const nameToLiveStages = stages.filter(s => s.get("type") === "LIVE").groupBy(s => s.get("name"));

          const stageDropdownFilters = nameToLiveStages
              .entrySeq()
              .sortBy(([_, stagesWithName]) => stagesWithName.map(s => s.get("ordering")).max())
              .map(([name, stagesWithName]) => Immutable.fromJS({
                id: name,
                name: "Live candidates up to " + name,
                explanation: "Jobs with furthest live candidates at " + name,
                fn: jobRow => {
                  const pipelineId = jobRow.get("pipelineId");
                  const stageForPipeline = stagesWithName.find(s => s.get("pipelineId") === pipelineId);

                  if (stageForPipeline) {
                    const latestCandidateRow = jobRow
                        .get("candidateRows")
                        .filter(cr => cr.get("type") === "LIVE")
                        .maxBy(cr => cr.get("furthestStageReachedOrder"));
                    return latestCandidateRow && latestCandidateRow.get("furthestStageReachedId") ===
                        stageForPipeline.get("id");
                  } else {
                    return false;
                  }
                }
              }));

          const dropdownFilters = defaultDropdownFilters.concat(stageDropdownFilters);
          const idToDropdownFilter = indexBy(d => d.get("id"), dropdownFilters);
          const pipelineIdToStages = stages
              .groupBy(s => s.get("pipelineId"))
              .map(ss => ss.sortBy(s => s.get("ordering")));

          // Check if the dropdownFilterId exists
          let dropdownFilterId = this.state.dropdownFilterId;
          if (!idToDropdownFilter.get(dropdownFilterId)) {
            dropdownFilterId = "ALL";
          }

          this.setState({
            dropdownFilters,
            dropdownFilterId,
            idToDropdownFilter,
            pipelineIdToStages
          }, () => this.state.selectedJobKpiId && this.loadAndSetData());
        });
  },

  render() {
    const jobKpis = getJobKpis();
    const selectedKpi = jobKpis.count() === 1 ? jobKpis.first() : getKpi(this.state.selectedJobKpiId);
    const isInstantKpi = selectedKpi && selectedKpi.getIn(["type", "dateType"]) === "INSTANT";
    const visibleTimeframes = timeframeRepo
        .getAll()
        .filter(t => t.get("visible") && !t.get("isDeleted"));
    const currentUser = getImmutableCurrentUser();
    const selectedKpiId = jobKpis.count() === 1 ? jobKpis.first().get("id") : this.state.selectedJobKpiId;
    const {theme} = this.props;

    return (
        <div style={{height: "100vh"}}>
          <MenuBar appView="jobs-pipeline" />
          <div
              style={theme.themeId === "light" ? {
                background: theme.palette.background.card,
                boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px",
                marginBottom: 30,
                padding: "10px 20px 30px 20px"
              } : {margin: "0 auto", maxWidth: "90rem", width: "98%"}}>
            <Layout
                allSmall={6}
                medium={12}
                smallCentered={true}
                rowStyle={{marginTop: "0.5rem", maxWidth: "100%", width: "100%"}}>
              <div style={{float: "none"}}>
                <TwoOptionToggle
                    leftLabel="Sales"
                    rightLabel="Recruiting"
                    isLeft={this.state.isSalesView}
                    onColor={theme.palette.primary.main}
                    offColor={theme.themeId === "light" ? theme.palette.primary.main : theme.palette.text.main}
                    onChange={isLeft => {
                      this.setState({isSalesView: isLeft});
                      storeIsSalesView(isLeft);
                    }} />
              </div>
            </Layout>
            <Layout
                allSmall={12}
                medium={this.state.isSalesView ? [3, 3, 4, 2] : [2, 2, 4, 3, 1]}
                rowStyle={{maxWidth: "100%", width: "100%"}}
                columnStyle={{paddingTop: "1rem", paddingLeft: "0.3em", paddingRight: "0.3em"}}>
              <KpiPicker
                  label={selectedKpiId
                      ? jobKpis.filter(j => j.get("id") === selectedKpiId).first().get("name")
                      : "Choose Job Metric..."}
                  kpis={jobKpis.filter(j => j.get("visible"))}
                  onKpiSelect={this.handleSelectJobKpi}
                  closeOnSelect={true} />
              <GroupUserPicker
                  prefix="Owned By: "
                  isDisabled={currentUser.get("dataVisibility") === "SELF"}
                  qualifierType={this.state.jobKpiQualifier.get("type")}
                  qualifierId={this.state.jobKpiQualifier.get("id")}
                  onGroupClick={groupId => this.handleSelectJobQualifier("GROUP", groupId)}
                  onUserClick={userId => this.handleSelectJobQualifier("USER", userId)} />
              {!this.state.isSalesView && <div style={{display: "flex", width: "100%"}}>
                <div style={{flexGrow: 1, maxWidth: 200, overflow: "hide"}} data-test-id="activity-filter">
                  <PipelineActivityFilter
                      isDisabled={currentUser.get("dataVisibility") === "SELF"}
                      value={this.state.shouldIncludeActivityForOtherUsers}
                      onValueChange={value => this.handleActivityFilterSelect(value)}
                  />
                </div>
                <div style={{flexGrow: 2}}>
                  <GroupUserPicker
                      isDisabled={currentUser.get("dataVisibility") === "SELF"}
                      qualifierType={this.state.activityQualifier.get("type")}
                      qualifierId={this.state.activityQualifier.get("id")}
                      onGroupClick={groupId => this.handleSelectActivityQualifier("GROUP", groupId)}
                      onUserClick={userId => this.handleSelectActivityQualifier("USER", userId)} />
                </div>
              </div>}
              <TimeframePicker
                  isDisabled={isInstantKpi}
                  timeframes={visibleTimeframes}
                  timeframe={timeframeRepo.parse(this.state.timeframe)}
                  onChange={timeframe => this.handleSelectTimeframe(timeframe.getRawJson())} />
              <TextButton
                  testId="jobs-pipeline-load-button"
                  disabled={this.state.dataLoading || !this.state.selectedJobKpiId}
                  fullWidth={true}
                  label="Load"
                  type="primary"
                  style={{fontSize: 15}}
                  onClick={() => this.loadAndSetData()} />
            </Layout>
            {(!this.state.dataLoading && !this.state.jobRows.isEmpty() && !this.state.idToPipeline.isEmpty())
                && this.renderAllPipelinesToolbar()}
          </div>
          <div style={{paddingLeft: pagePadding + "rem", paddingRight: pagePadding + "rem"}}>
            {this.renderPipelines()}
          </div>
          {this.state.showDataDetails && (
              <Dialog
                  onRequestClose={() => this.setState({showDataDetails: false})}
                  label={this.state.dataDetailsForStage.get("name")}
                  content={
                    <div>
                      {this.state.dataDetailsLoading
                          ? <LoadingSpinner />
                          : this.state.dataDetails.map((dataDetail, index) => <DataDetailsTable
                              key={index}
                              filenameForDownload="data.csv"
                              columns={dataDetail.get("columns")}
                              rows={dataDetail.get("rows")} />
                          )}
                    </div>
                  } />
          )}
          {this.state.isScrollShortcutsPopupOpen &&
              <ScrollInfoPopup onRequestClose={this.handleDismissScrollPopup} width={this.props.containerWidth} />}
        </div>
    );
  },

  handleDismissScrollPopup() {
    storeScrollShortcutsPopupDismissed();
    this.setState({isScrollShortcutsPopupOpen: false});
  },

  handleSelectTimeframe(timeframe) {
    storeLastUsedTimeframe(timeframe);
    this.setState({timeframe});
  },

  handleActivityClick(stage, entities) {
    this.setState({showDataDetails: true, dataDetailsForStage: stage, dataDetailsLoading: true});

    auditor.audit("job-pipeline:ct", {
      metricName: stage.get("name"),
      pipelineName: this.state.idToPipeline.get(stage.get("pipelineId")).get("name")
    });

    const kpiIdToEntities = entities.groupBy(e => e.get("kpiId"));
    const activityQualifier = getHighestQualifier();
    const promises = kpiIdToEntities
        .entrySeq()
        .map(([kpiId, entities]) => {
          const params = {
            start: parseDate("1970-01-01"),
            end: parseDate("2099-01-01"),
            qualifierId: activityQualifier.get("id"),
            qualifierType: activityQualifier.get("type"),
            entityIds: entities.map(e => e.get("id")).toArray()
          };
          return kpiCalculator
              .report(kpiId, params)
              .then(data => Immutable.Map({rows: data.values, columns: data.columns}));
        });
    Promise.all(promises).then(dataDetails => this.setState({dataDetails, dataDetailsLoading: false}));
  },

  handleSelectJobQualifier(type, id) {
    const jobKpiQualifier = this.state.jobKpiQualifier.set("type", type).set("id", id);
    this.setState({jobKpiQualifier});
    storeJobKpiQualifier(jobKpiQualifier);
  },

  handleSelectActivityQualifier(type, id) {
    const activityQualifier = this.state.activityQualifier.set("type", type).set("id", id);
    this.setState({activityQualifier});
    storeActivityQualifier(activityQualifier);
  },

  handleSelectJobKpi(jobKpiId) {
    this.setState({
      selectedJobKpiId: jobKpiId
    });
    storeLastUsedJobKpiId(jobKpiId);
  },

  handleActivityFilterSelect(option) {
    this.setState({
      shouldIncludeActivityForOtherUsers: option === "With Some Activity By"
    });
    storeShouldIncludeActivityForOtherUsers(option === "With Some Activity By");
  },

  renderPipelines() {
    if (this.state.dataLoading) {
      return <div><br /><br /><LoadingSpinner color={Colors.jobsPipelineColor} /></div>;
    } else if (this.state.idToPipeline.isEmpty()) {
      return <Info
          text="No pipelines set, add some using the admin page."
          style={{marginTop: "2rem", background: this.props.theme.palette.background.card}} />;
    } else if (this.state.jobRows.isEmpty()) {
      return <Info
          text="No data found, try changing the above options."
          style={{marginTop: "2rem", background: this.props.theme.palette.background.card}} />;
    } else {
      const {dropdownFilterId, idToDropdownFilter, dayRange, sliderLimit} = this.state;
      const isFullRange = dayRange.get("min") === 0 && dayRange.get("max") === sliderLimit;
      const allowJobsWithNoActivity = (isFullRange && dropdownFilterId === "ALL")
          || dropdownFilterId === "NO_ACTIVITY";
      const rows = this.state.filteredJobRows
          .filter(idToDropdownFilter.get(dropdownFilterId).get("fn"))
          .filter(jobRow => filterJobRowByActivity(
              jobRow,
              dayRange,
              sliderLimit,
              allowJobsWithNoActivity));
      return (
          <div>
            {this.renderPipelinesForRows(rows)}
          </div>
      );
    }
  },

  renderAllPipelinesToolbar() {
    const dropdownFilter = this.state.idToDropdownFilter.get(this.state.dropdownFilterId);

    const {dayRange, activityCategories} = this.state;
    const shortcuts = activityCategories
        .filter(activityCategory => activityCategory.has("dayRange"));
    let rangeMessage;
    const sliderIsShowingFullRange = dayRange.get("min") === 0 && dayRange.get("max") === this.state.sliderLimit;
    if (sliderIsShowingFullRange || dropdownFilter.get("disableSlider")) {
      rangeMessage = "";
    } else {
      if (dayRange.get("max") === this.state.sliderLimit) {
        rangeMessage = "whose latest activity is more than " + dayRange.get("min") + " days ago";
      } else if (dayRange.get("min") > 0) {
        rangeMessage =
            "whose latest activity is between " + dayRange.get("min") + " and " + dayRange.get("max") +
            " days ago";
      } else {
        rangeMessage = "whose latest activity is within the last " + dayRange.get("max") + " days";
      }
    }

    const selectedFilter = this.state.dropdownFilters.find(filter => filter.get("id") === this.state.dropdownFilterId);

    return (
        <Layout allSmall={12} allMedium={6} allLarge={3} rowStyle={{maxWidth: "100%", width: "100%"}}>
          <TextField
              data-test-id="search-title-contact-owner"
              variant="standard"
              style={{marginTop: "1rem"}}
              label={<span style={{fontSize: 13}}><i className="bhi-search" />&nbsp;Search title, contact, owner, etc...</span>}
              fullWidth={true}
              value={this.state.jobFilterText}
              onChange={e => this.handleChangeJobFilterText(e.target.value)} />
          <TextField
              data-test-id="search-candidate"
              variant="standard"
              style={{marginTop: "1rem"}}
              fullWidth={true}
              label={<span style={{fontSize: 13}}><i className="bhi-search" />&nbsp;Search candidate...</span>}
              value={this.state.candidateFilterText}
              onChange={e => this.handleChangeCandidateFilterText(e.target.value)} />
          <div style={{marginTop: "2.6rem"}}>
            <DayRangeSlider
                onChange={this.handleDayRangeChange}
                range={this.state.dayRange}
                limit={this.state.sliderLimit}
                shortcuts={shortcuts}
                disabled={dropdownFilter.get("disableSlider")}
                explanation={dropdownFilter.get("explanation") + " " + rangeMessage} />
          </div>
          <div style={{marginTop: "3rem"}} data-test-id="job-filter-dropdown">
            <Select
                keys={["id", "name"]}
                options={this.state.dropdownFilters}
                selectedValue={selectedFilter.get("id")}
                onChange={this.handleJobDropdownChange}
                isMulti={false}
                isClearable={false}
                isSearchable={false} />
          </div>
        </Layout>
    );
  },

  handleDayRangeChange(min, max) {
    const dayRange = Immutable.Map({min, max});
    storeLastUsedDayRange(dayRange);
    this.setState({
      pipelineIdToCurrentPage: Immutable.Map(),
      dayRange
    });
  },

  handleJobDropdownChange(dropdownFilterId) {
    storeLastUsedJobDropdownFilterId(dropdownFilterId);
    this.setState({
      pipelineIdToCurrentPage: Immutable.Map(),
      dropdownFilterId
    });
  },

  handleChangeJobFilterText(jobFilterText) {
    const filteredJobRows = this.state.jobRows.filter(jobRow => matchSearch(
        jobRow,
        this.state.idToJob.get(jobRow.get("jobId")),
        jobFilterText,
        this.state.candidateFilterText));
    this.setState({
      pipelineIdToCurrentPage: Immutable.Map(),
      filteredJobRows,
      jobFilterText
    });
  },

  handleChangeCandidateFilterText(candidateFilterText) {
    const filteredJobRows = this.state.jobRows.filter(jobRow => matchSearch(
        jobRow,
        this.state.idToJob.get(jobRow.get("jobId")),
        this.state.jobFilterText,
        candidateFilterText));
    this.setState({
      pipelineIdToCurrentPage: Immutable.Map(),
      filteredJobRows,
      candidateFilterText
    });
  },

  renderPipelinesForRows(rowsToRender) {
    const pipelineIdToTableWidthRem = this.state.idToPipeline.map(pipeline => {
      const pipelineId = pipeline.get("id");
      const stages = this.state.pipelineIdToStages.get(pipelineId, Immutable.List());
      const visibleColumns = this.state.pipelineIdToVisibleColumns.get(pipelineId);
      const simpleColumnsWidth = simpleColumns
          .filter(c => visibleColumns.includes(c.get("id")))
          .map(c => c.get("width"))
          .reduce((a, b) => a + b, 0);
      return expandColumnWidth
          + simpleColumnsWidth
          + (stageTextWidth * stages.count())
          + 1; // NOTE this excess 1rem is due to an unknown factor causing the true width to be slightly larger
               // than expected
    });

    const maxTableWidthRem = pipelineIdToTableWidthRem.valueSeq().max();
    const usableContainerWidthRem = pxToRem(this.props.containerWidth) - (pagePadding * 2);
    const containerStyle = {
      width: Math.min(maxTableWidthRem, usableContainerWidthRem) + "rem",
      marginTop: "1.2rem",
      marginLeft: "auto",
      marginRight: "auto"
    };

    const wrapperStyle = (theme) => ({
      display: "flex",
      overflow: "auto",
      padding: "20px",
      marginBottom: "1rem",
      backgroundColor: theme.palette.background.card,
      borderRadius: "3px",
      height: "10rem",
      width: "100%",
      msWidth: "100%",
      color: theme.palette.text.main,
      lineHeight: "1.2rem"
    });

    return (
        <div style={containerStyle}>
          {rowsToRender
              .groupBy(row => row.get("pipelineId"))
              .entrySeq()
              .sortBy(([pipelineId]) => this.state.idToPipeline.get(pipelineId).get("name"))
              .sortBy(([pipelineId]) => this.state.idToPipeline.get(pipelineId).get("ordering"))
              .toList() // NOTE bug in immutable js, mapping an ArraySeq seems to do the work 3 times
              .map(([pipelineId, rowsForPipeline]) => {
                const {
                  pipelineIdToColumnIdToSort,
                  pipelineIdToMultiSort,
                  pipelineIdToStages,
                  pipelineIdToSortStageByCount
                } = this.state;

                const stages = pipelineIdToStages.get(pipelineId, Immutable.List());
                const multiSort = pipelineIdToMultiSort.get(pipelineId);
                const columnIdToSort = pipelineIdToColumnIdToSort.get(pipelineId);
                const sortedRows = columnIdToSort
                    .entrySeq()
                    .sortBy(([_, sort]) => -sort.get("ordering"))
                    .reduce(
                        (rows, [columnId, sort]) => {
                          const sortFn = getSortFnForColumnId(columnId);
                          const asc = sort.get("direction") === "ASC";
                          return rows.sortBy(
                              jobRow => sortFn(
                                  jobRow,
                                  this.state.idToJob.get(jobRow.get("jobId")),
                                  sort.set("byEntityCount", pipelineIdToSortStageByCount.get(pipelineId))),
                              makeComparator(asc));
                        },
                        rowsForPipeline);

                const currentPage = this.state.pipelineIdToCurrentPage.get(pipelineId, 0);
                const startIndex = currentPage * this.state.rowsPerPage;
                const rowsForCurrentPage = sortedRows.slice(startIndex, startIndex + this.state.rowsPerPage);

                const pipeline = this.state.idToPipeline.get(pipelineId);
                const stagesForPipeline = this.state.pipelineIdToStages.get(pipelineId, Immutable.List());
                const visibleColumns = this.state.pipelineIdToVisibleColumns.get(pipelineId);

                const tableWidthRem = pipelineIdToTableWidthRem.get(pipelineId);
                const scrollableContainerWidthRem = Math.min(tableWidthRem, usableContainerWidthRem);

                const headingStyle = {...thinOverviewTextStyle(this.props.theme)};
                const collapseJobRows = this.state.pipelineIdToCollapseRowsState.get(pipelineId);
                return (
                    <div key={pipelineId}>
                      <div style={{paddingBottom: "0.7rem"}}>
                        <h3
                            onClick={() => this.handleCollapseJobRowsToggleClick(pipelineId)}
                            style={{
                              display: "inline-block",
                              fontWeight: 500,
                              textTransform: "uppercase",
                              fontSize: 20,
                              color: this.props.theme.palette.primary.main
                            }}>
                          <i
                              className={`fa fa-${collapseJobRows ? `plus-circle` : `minus-circle`}`}
                              data-test-id={collapseJobRows ? "jobs-pipeline-expand" : "jobs-pipeline-collapse"}
                              style={{
                                fontSize: "1.15rem",
                                padding: "0 0.5rem",
                                position: "relative",
                                top: -2
                              }} />
                          <span>{pipeline.get("name")}</span>
                        </h3>
                        <VelocityTransitionGroup
                            component="div"
                            style={{display: "inline-block", paddingLeft: "1rem"}}
                            enter="fadeIn"
                            leave="fadeOut">
                          {!collapseJobRows &&
                              <span>
                                            <Paginator
                                                itemsPerPage={this.state.rowsPerPage}
                                                currentPage={currentPage}
                                                totalItems={rowsForPipeline.count()}
                                                onChange={page => {
                                                  const pipelineIdToCurrentPage = this.state.pipelineIdToCurrentPage
                                                      .set(pipelineId, page);
                                                  this.setState({pipelineIdToCurrentPage});
                                                }} />
                                            <Checkbox
                                                testId="sort-by-activity-count"
                                                label="Sort by Activity Count"
                                                style={{
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  marginLeft: this.props.containerWidth >= 450 && "0.5rem",
                                                  width: "13rem"
                                                }}
                                                onCheck={() => this.setState({
                                                  pipelineIdToSortStageByCount: pipelineIdToSortStageByCount
                                                      .set(pipelineId, !pipelineIdToSortStageByCount.get(pipelineId))
                                                })}
                                                checked={pipelineIdToSortStageByCount.get(pipelineId)} />
                                            <MultiSortToolbar
                                                idToColumn={idToColumn}
                                                stages={stages}
                                                multiSort={multiSort}
                                                columnIdToSort={columnIdToSort}
                                                onChangeColumnIdToSort={columnIdToSort => {
                                                  this.setState({
                                                    pipelineIdToColumnIdToSort: pipelineIdToColumnIdToSort
                                                        .set(pipelineId, columnIdToSort)
                                                  });
                                                }}
                                                onToggleMultiSort={() => {
                                                  const newMultiSort = !multiSort;
                                                  if (!newMultiSort &&
                                                      (columnIdToSort.isEmpty() || columnIdToSort.count() > 1)) {
                                                    const newPipelineIdToColumnIdToSort =
                                                        pipelineIdToColumnIdToSort.set(
                                                            pipelineId,
                                                            Immutable.fromJS({
                                                              "LATEST_ACTIVITY_DATE": {
                                                                direction: "ASC",
                                                                ordering: 0
                                                              }
                                                            }));
                                                    this.setState({
                                                      pipelineIdToColumnIdToSort: newPipelineIdToColumnIdToSort
                                                    });
                                                  }
                                                  const newPipelineIdToMultiSort = pipelineIdToMultiSort
                                                      .set(pipelineId, newMultiSort);
                                                  this.setState({pipelineIdToMultiSort: newPipelineIdToMultiSort});
                                                }} />
                                        </span>
                          }
                        </VelocityTransitionGroup>
                      </div>
                      {stages.isEmpty() && <Info
                          text="Warning: This pipeline has no stages, add some using the admin page."
                          style={{marginBottom: "0.5rem"}} />}
                      <div style={wrapperStyle(this.props.theme)}>
                        {this.renderOverviewForPipeline(pipelineId, rowsForPipeline)}
                        {this.renderJobCountChartForPipeline(pipelineId)}
                        {this.renderCandidateStageChartForPipeline(pipelineId, rowsForPipeline)}
                      </div>
                      <div style={{width: scrollableContainerWidthRem + "rem", overflowX: "auto"}}>
                        <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
                          {!collapseJobRows &&
                              <div style={{marginBottom: "1rem"}}>
                                <div
                                    style={thinOverviewHeaderStyle(this.props.theme, tableWidthRem)}>
                                  <div style={{...headingStyle, width: expandColumnWidth + "rem"}}>
                                    <VisibleColumnPicker
                                        columns={simpleColumns}
                                        visibleColumns={visibleColumns}
                                        onChange={newVisibleColumns => {
                                          const pipelineIdToVisibleColumns =
                                              this.state.pipelineIdToVisibleColumns
                                                  .set(pipelineId, newVisibleColumns);
                                          this.setState({pipelineIdToVisibleColumns});
                                          storePipelineIdToVisibleColumns(pipelineIdToVisibleColumns);
                                        }} />
                                  </div>
                                  {simpleColumns
                                      .filter(c => visibleColumns.includes(c.get("id")))
                                      .map(column => {
                                        const sorted = columnIdToSort.has(column.get("id"));
                                        return (
                                            <div
                                                key={column.get("id")}
                                                onClick={() => {
                                                  this.handleColumnHeaderClick(
                                                      pipelineId,
                                                      column.get("id"));
                                                }}
                                                style={{
                                                  ...headingStyle,
                                                  width: column.get("width") + "rem"
                                                }}>
                                              {column.get("label")}
                                              {sorted &&
                                                  directionToSortIcon(columnIdToSort.get(column.get("id"))
                                                      .get("direction"))}
                                            </div>
                                        );
                                      })
                                  }
                                  {stagesForPipeline.map(stage => {
                                    const columnId = "__STAGE_" + stage.get("id");
                                    const sorted = columnIdToSort.has(columnId);
                                    return (
                                        <div
                                            key={stage.get("id")}
                                            onClick={() => this.handleColumnHeaderClick(
                                                pipelineId,
                                                columnId,
                                                Immutable.Map({stageId: stage.get("id")}))}
                                            style={{
                                              ...headingStyle,
                                              width: rem(stageTextWidth),
                                              textAlign: "center"
                                            }}>
                                          {stage.get("name")}
                                          {sorted && directionToSortIcon(columnIdToSort.get(columnId)
                                              .get("direction"))}
                                        </div>
                                    );
                                  })}
                                </div>
                                <div>
                                  {rowsForCurrentPage.map((jobRow, i) => {
                                    const job = this.state.idToJob.get(jobRow.get("jobId"));
                                    return (
                                        <ThinJobPanel
                                            key={job.get("id")}
                                            containerWidthRem={scrollableContainerWidthRem}
                                            tableWidthRem={tableWidthRem}
                                            pipeline={pipeline}
                                            visibleColumns={visibleColumns}
                                            stages={stagesForPipeline}
                                            jobUiState={
                                              this.state.jobIdToUiState.get(
                                                  job.get("id"),
                                                  this.state.defaultJobUiState)
                                            }
                                            onJobUiStateChange={this.handleJobUiStateChange}
                                            onActivityClick={this.handleActivityClick}
                                            jobRow={jobRow}
                                            job={job}
                                            onJobChange={this.handleUpdateJob}
                                            onLocalJobChange={this.handleLocalUpdateJob}
                                            index={i}
                                        />
                                    );
                                  })}
                                </div>
                              </div>}
                        </VelocityTransitionGroup>
                      </div>
                    </div>
                );
              })
          }
        </div>
    );
  },

  renderJobCountChartForPipeline(pipelineId) {
    const categories = this.state.activityCategories;
    const {dropdownFilterId, idToDropdownFilter, sliderLimit} = this.state;

    const counts = categories.map(category => {
      const allowJobsWithNoActivity = category.get("allowJobsWithNoActivity") &&
          (category.get("label") !== "All" || dropdownFilterId === "ALL");
      return this.state.filteredJobRows
          .filter(jobRow => jobRow.get("pipelineId") === pipelineId)
          .filter(idToDropdownFilter.get(dropdownFilterId).get("fn"))
          .filter(jobRow => filterJobRowByActivity(
              jobRow,
              category.get("dayRange"),
              sliderLimit,
              allowJobsWithNoActivity))
          .count() || null;
    });

    return (
        <div style={{float: "left", marginRight: "4rem", width: "20rem"}}>
          <JobStatsBarChart
              theme={this.props.theme}
              xAxisLabel={"Last Activity (Days Ago)"}
              yAxisLabel={"# Jobs"}
              categories={categories.map(category => category.get("label")).toArray()}
              seriesData={counts.toArray()}
          />
        </div>
    );
  },

  renderCandidateStageChartForPipeline(pipelineId, rows) {
    const {pipelineIdToStages} = this.state;
    const stages = pipelineIdToStages.get(pipelineId, Immutable.List());
    const idToStage = indexBy(s => s.get("id"), stages);

    if (rows.isEmpty()) {
      return <span />;
    } else {
      const stageIdToCandidateAtStageCount = rows.reduce(
          (m, row) => {
            return row.get("candidateRows").reduce(
                (m2, candidateRow) => {
                  return m2.update(candidateRow.get("furthestStageReachedId"), 0, x => x + 1);
                },
                m);
          },
          Immutable.Map());

      const stageNames = stageIdToCandidateAtStageCount
          .entrySeq()
          .sortBy(([stageId]) => idToStage.get(stageId).get("ordering"))
          .map(([stageId]) => idToStage.get(stageId).get("name"));

      const candidateCounts = stageIdToCandidateAtStageCount
          .entrySeq()
          .sortBy(([stageId]) => idToStage.get(stageId).get("ordering"))
          .map(([_, count]) => count);

      if (stageNames.toArray().length === 0) {
        return <span />;
      }
      const chartWidth = (3 + stageNames.toArray().length * 5) + "rem";

      return (
          <div style={{float: "left", marginRight: "4rem", width: chartWidth}}>
            <JobStatsBarChart
                theme={this.props.theme}
                xAxisLabel={null}
                yAxisLabel={"# Candidates"}
                xAxisLabelFormatter={createShortLabel}
                categories={stageNames.toArray()}
                seriesData={candidateCounts.toArray()} />
          </div>
      );
    }
  },

  renderOverviewForPipeline(pipelineId, rows) {
    const {idToJob} = this.state;

    if (rows.isEmpty()) {
      return <span />;
    } else {
      const currencyCode = idToJob.get(rows.first().get("jobId")).get("localCurrencyCode");
      const [totalValue, weightedValue] = rows.reduce(
          ([total, weighted], row) => {
            const job = idToJob.get(row.get("jobId"));
            return [
              total + job.get("localValue"),
              weighted + (job.get("localValue") * row.get("weighting"))
            ];
          },
          [0, 0]);

      return (
          <div style={{float: "left", marginRight: "1rem", width: "14rem"}}>
            <span style={{color: this.props.theme.palette.primary.main}}>Weighted Pipeline Value</span> <br />
            {Formatter.format({currency: currencyCode, value: weightedValue})} <br /><br />
            <span style={{color: this.props.theme.palette.primary.main}}>Total Pipeline Value</span> <br />
            {Formatter.format({currency: currencyCode, value: totalValue})}
          </div>
      );
    }
  },

  /*
  renderStatsForPipeline(pipelineId) {
      const {pipelineIdToStats, pipelineIdToStages} = this.state;
      const stats = pipelineIdToStats.get(pipelineId);
      const stages = pipelineIdToStages.get(pipelineId);

      const wrapperStyle = {
          padding: "0.5rem",
          marginBottom: "0.5rem",
          backgroundColor: "#555",
          borderRadius: "0.5rem"
      };

      if (stats) {
          const stageIdToCount = stats.get("stageIdToCountForPlacedJobs");
          const placedStageId = stages.find(s => s.get("type") === "PLACED").get("id");
          const placedCount = stageIdToCount.get(placedStageId);
          return (
              <div style={wrapperStyle}>
                  <div>
                      Stats based on placements for last 12 months of jobs added
                      <br />
                      {stats.get("placedJobIds").count()} placed jobs
                      <br />
                      Time to fill: {numbers.roundTo(stats.get("meanTimeToFill"))} days
                      <br />
                      Fill Rate: {numbers.toPercentageStr(stats.get("meanFillRate"), 1)}
                      <br />
                      Activity ratio on placed jobs: {stages
                          .map(s => {
                              const count = stageIdToCount.get(s.get("id"));
                              const scaledCount = numbers.roundTo(count / placedCount, 1);
                              return scaledCount + " " + s.get("name");
                          })
                          .join(" ⇒ ")}
                  </div>
              </div>
          );
      } else {
          return (
              <div style={wrapperStyle}>
                  Loading...
              </div>
          );
      }
  },*/

  handleCollapseJobRowsToggleClick(pipelineId) {
    const showJobRows = this.state.pipelineIdToCollapseRowsState.get(pipelineId);
    const pipelineIdToCollapseRowsState = this.state.pipelineIdToCollapseRowsState.set(pipelineId, !showJobRows);
    const pipelineName = this.state.idToPipeline.get(pipelineId).get("name");

    if (showJobRows) {
      auditor.audit("job-pipeline:expand-pipeline", {
        pipelineId,
        pipelineName
      });
    } else {
      auditor.audit("job-pipeline:collapse-pipeline", {
        pipelineId,
        pipelineName
      });
    }
    this.setState({pipelineIdToCollapseRowsState});
    storePipelineIdToCollapseRowsState(pipelineIdToCollapseRowsState);
  },

  handleColumnHeaderClick(pipelineId, columnId, sortOpts = Immutable.Map()) {
    const {pipelineIdToColumnIdToSort} = this.state;

    const multiSort = this.state.pipelineIdToMultiSort.get(pipelineId);
    if (multiSort) {
      let columnIdToSort = pipelineIdToColumnIdToSort.get(pipelineId, Immutable.Map());
      if (columnIdToSort.has(columnId)) {
        columnIdToSort = columnIdToSort.updateIn([columnId, "direction"], nextDirection);
      } else {
        const maxOrder = columnIdToSort.valueSeq().map(s => s.get("ordering")).max() || 0;
        const sort = sortOpts
            .set("direction", "ASC")
            .set("ordering", maxOrder + 1);
        columnIdToSort = columnIdToSort.set(columnId, sort);
      }
      this.setState({pipelineIdToColumnIdToSort: pipelineIdToColumnIdToSort.set(pipelineId, columnIdToSort)});
    } else {
      const columnIdToSort = pipelineIdToColumnIdToSort.get(pipelineId);
      const isSameColumn = columnIdToSort.has(columnId);

      let newSort = sortOpts.set("ordering", 0);
      if (isSameColumn) {
        const newDirection = nextDirection(columnIdToSort.get(columnId).get("direction"));
        newSort = newSort.set("direction", newDirection);
      } else {
        newSort = newSort.set("direction", "ASC");
      }
      const newColumnIdToSort = Immutable.Map().set(columnId, newSort);
      this.setState({pipelineIdToColumnIdToSort: pipelineIdToColumnIdToSort.set(pipelineId, newColumnIdToSort)});
    }
  },

  auditJobAttributeChange(job) {
    if (this.state.originalEditableJobValue !== job.get("value")) {
      auditor.audit("job-pipeline:job-changed", {
        jobId: job.get("id"),
        change: {
          attributeChanged: "value",
          originalValue: this.state.originalEditableJobValue,
          newValue: job.get("value")
        }
      });
    } else {
      const currentJobSettings = this.state.idToJob.get(job.get("id"));
      const attributeChanged = job
          .keySeq()
          .toList()
          .filter(key => job.get(key) !== currentJobSettings.get(key))
          .first();
      let originalValue = currentJobSettings.get(attributeChanged);
      let newValue = job.get(attributeChanged);
      if (attributeChanged === "closeDate") {
        const originalCloseDate = currentJobSettings.get(attributeChanged);
        const newCloseDate = job.get(attributeChanged);
        originalValue = originalCloseDate ? formatDate(originalCloseDate) : originalCloseDate;
        newValue = newCloseDate ? formatDate(newCloseDate) : newCloseDate;
      }
      auditor.audit("job-pipeline:job-changed", {
        jobId: job.get("id"),
        change: {
          attributeChanged,
          originalValue,
          newValue
        }
      });
    }
  },

  handleUpdateJob(job) {
    this.auditJobAttributeChange(job);
    updateJob(job).then(updatedJob => this.setState({
      idToJob: this.state.idToJob.set(job.get("id"), updatedJob),
      originalEditableJobValue: updatedJob.get("value")
    }));
  },

  handleLocalUpdateJob(job) {
    this.setState({
      idToJob: this.state.idToJob.set(job.get("id"), job)
    });
  },

  handleJobUiStateChange(jobId, jobUiState) {
    const permissions = getClientPermissions();
    const expanded = jobUiState.get("expanded");
    const normalisedJobUiState = jobUiState
        .update("valueIsEditable", flag => flag && permissions.includes("CAN_EDIT_JOB_VALUE") && expanded)
        .update("closeDateIsEditable", flag => flag && permissions.includes("CAN_EDIT_JOB_CLOSE_DATE") && expanded);
    const job = this.state.idToJob.get(jobId);
    const jobIdToUiState = this.state.jobIdToUiState.set(jobId, normalisedJobUiState);

    if (expanded && !this.state.jobIdToUiState.get(jobId).get("expanded")) {
      auditor.audit("job-pipeline:expand-job", {
        jobId
      });
    }
    this.setState({
      jobIdToUiState,
      originalEditableJobValue: normalisedJobUiState.get("valueIsEditable") ? job.get("value") : null
    });
  },

  loadAndSetData() {
    const {
      selectedJobKpiId,
      jobKpiQualifier,
      isSalesView,
      timeframe,
      pipelineIdToStages,
      idToPipeline,
      shouldIncludeActivityForOtherUsers
    } = this.state;
    this.setState({dataLoading: true});

    /*
    const predictPromise = loadPipelineIdToStats(
        selectedJobKpiId,
        jobKpiQualifier,
        this.getActivityQualifier(),
        !isSalesView,
        timeframe,
        pipelineIdToStages
    ).then(pipelineIdToStats => {
        this.setState({pipelineIdToStats});
    });
    */

    const dataPromise = loadData(
        selectedJobKpiId,
        jobKpiQualifier,
        this.getActivityQualifier(),
        !isSalesView,
        timeframe,
        pipelineIdToStages,
        idToPipeline,
        shouldIncludeActivityForOtherUsers
    ).then(data => {
      const jobRows = data.get("jobRows");
      const idToJob = data.get("idToJob");

      const defaultUiState = Immutable.Map({expanded: false, valueIsEditable: false, closeDateIsEditable: false});
      const jobIdToUiState = idToJob.map(() => defaultUiState);

      const filteredJobRows = jobRows.filter(jobRow => matchSearch(
          jobRow,
          idToJob.get(jobRow.get("jobId")),
          this.state.jobFilterText,
          this.state.candidateFilterText));

      this.setState({
        jobRows,
        filteredJobRows,
        idToJob,
        jobIdToUiState,
        pipelineIdToCurrentPage: Immutable.Map()
      });
    });

    Promise.all([dataPromise]).then(() => this.setState({dataLoading: false}));
  }

}));

const JobInfo = pure(({
  parentDivStyle = {},
  labelStyle = {},
  valueStyle = {},
  title = "",
  label = "",
  onClick = () => {},
  value = "",
  unavailableText = " -- "
}) => (
    <div title={title} onClick={onClick} style={{...parentDivStyle, display: "inline-block", marginRight: "1rem"}}>
      <span style={{...labelStyle, fontWeight: "600"}}>{label} </span>
      <span style={{...valueStyle, fontSize: "0.9rem"}}>{value || unavailableText}</span>
    </div>
));

const ThinJobPanel = React.memo(({
  tableWidthRem,
  visibleColumns,
  stages,
  jobRow,
  job,
  onJobChange,
  onLocalJobChange,
  jobUiState,
  onJobUiStateChange,
  onActivityClick,
  index
}) => {

  const {theme} = React.useContext(CustomThemeContext);
  const isOdd = (x) => x & 1;
  const bgColor = theme.themeId === "light" ?
      isOdd(index) ? theme.palette.background.card : "#f6f6f6"
      : theme.palette.background.card;

  return (
      <div
          key={job.get("id")}
          style={{
            border: `1px solid ${theme.themeId === "light" ? "#F6F6F6" : theme.palette.background.paper}`,
            borderRadius: theme.themeId === "light" ? 0 : "3px",
            background: bgColor,
            marginBottom: theme.themeId === "light" ? 0 : "0.1rem",
            whiteSpace: "nowrap",
            width: tableWidthRem + "rem",
            lineHeight: 1
          }}>

        {renderJobOverview(
            theme,
            visibleColumns,
            stages,
            jobRow,
            job,
            onJobChange,
            onLocalJobChange,
            jobUiState,
            onJobUiStateChange,
            onActivityClick,
            index
        )}

        {jobUiState.get("expanded") &&
            renderJobDetails(
                theme,
                visibleColumns,
                stages,
                jobRow,
                job,
                onJobChange,
                onLocalJobChange,
                jobUiState,
                onJobUiStateChange,
                onActivityClick,
                index
            )}

      </div>
  );
});

const renderJobOverview = (
    theme,
    visibleColumns,
    stages,
    jobRow,
    job,
    onJobChange,
    onLocalJobChange,
    jobUiState,
    onJobUiStateChange,
    onActivityClick,
    index) => {

  const isOdd = (x) => x & 1;
  const bgColor = theme.themeId === "light" ?
      isOdd(index) ? theme.palette.background.card : "#f6f6f6"
      : theme.palette.background.card;

  return (
      <div
          style={{
            borderBottom: theme.themeId === "dark" ? `1px solid ${Colors.greyDark}` : "none",
            background: bgColor
          }}>
        {renderExpandAndMinimiseButton(job, onJobUiStateChange, jobUiState, theme)}
        {renderSimpleColumns(visibleColumns, jobRow, job, theme)}
        {renderStageColumns(stages, jobRow, onActivityClick, theme)}
      </div>);
};

const renderExpandAndMinimiseButton = (job, onJobUiStateChange, jobUiState, theme) => {
  return (
      <div
          onClick={() => {
            onJobUiStateChange(job.get("id"), jobUiState.set("expanded", !jobUiState.get("expanded")));
          }}
          style={{
            ...thinOverviewTextStyle(theme),
            textOverflow: null,
            color: theme.palette.text.main,
            width: rem(expandColumnWidth)
          }}
          title="Expand to view more info">
        <i
            className={"fa " + (jobUiState.get("expanded") ? "fa-minus-circle" : "fa-plus-circle")}
        />
      </div>);
};

const renderSimpleColumns = (visibleColumns, jobRow, job, theme) => {
  return simpleColumns
      .filter(c => visibleColumns.includes(c.get("id")))
      .map(column => (
          <div
              key={column.get("id")}
              style={{
                ...thinOverviewTextStyle(theme),
                color: theme.palette.text.main,
                width: column.get("width") + "rem"
              }}
              title={column.get("titleFn")(jobRow, job)}>
            {column.get("displayFn")(jobRow, job, theme)}
          </div>));
};

const renderStageColumns = (stages, jobRow, onActivityClick, theme) => {
  const stageIdToEntities = jobRow.get("stageIdToEntities");
  return stages.map(stage => {
    const entities = stageIdToEntities.get(stage.get("id"), Immutable.List());
    const latestEntityDate = entities.map(e => e.get("date")).max();
    const stageColor = jobRow.get("furthestStageReachedOrder") === stage.get("ordering") ?
        theme.palette.success.main :
        theme.themeId === "light" ? theme.palette.text.main : "#ddd";
    return (
        <div
            key={stage.get("id")}
            onClick={entities.isEmpty() ? () => {} : () => onActivityClick(stage, entities)}
            style={{
              ...thinOverviewStageDataStyle,
              width: rem(stageTextWidth),
              color: stageColor,
              cursor: entities.isEmpty() ? null : "pointer"
            }}>
          {!entities.isEmpty() &&
              <span>
            <span style={{fontWeight: "bold", whiteSpace: "nowrap"}}>{countUniqueIds(entities)}</span>
                {"\n"}
                <span style={{whiteSpace: "nowrap"}} title={formatDateForDisplay(latestEntityDate)}>
              {getTimeAgo(latestEntityDate)}
            </span>
          </span>}
        </div>);
  });
};

const renderJobDetails = (
    theme,
    visibleColumns,
    stages,
    jobRow,
    job,
    onJobChange,
    onLocalJobChange,
    jobUiState,
    onJobUiStateChange,
    onActivityClick) => {

  const lastActivityDate = jobRow.get("lastActivityDate");
  const stageIdToEntities = jobRow.get("stageIdToEntities");
  const firstStageActivityDate = !stages.isEmpty() &&
      stageIdToEntities.get(stages.first().get("id"), Immutable.List()).map(e => e.get("date")).min();
  const daysToFirstStageDone = firstStageActivityDate &&
      firstStageActivityDate.diff(job.get("createdDate"), "days");
  const candidatePipelineWidth = candidateNameWidth + (candidateStageWidth * stages.count()) + candidateStageWidth;
  const typeToCandidateRows = jobRow.get("candidateRows").groupBy(row => row.get("type"));
  const liveCandidateRows = typeToCandidateRows
      .get("LIVE", Immutable.List())
      .sortBy(row => Number.MAX_SAFE_INTEGER - row.get("lastActivityDate").valueOf());
  const placedCandidateRows = typeToCandidateRows
      .get("PLACED", Immutable.List())
      .sortBy(row => Number.MAX_SAFE_INTEGER - row.get("lastActivityDate").valueOf());
  const deadCandidateRows = typeToCandidateRows
      .get("DEAD", Immutable.List())
      .sortBy(row => Number.MAX_SAFE_INTEGER - row.get("lastActivityDate").valueOf());

  return (
      <div style={{padding: "1rem", lineHeight: "1"}}>
        {renderBasicJobDetails(job, jobRow)}

        {lastActivityDate &&
            renderLastActivityDate(lastActivityDate)}

        {firstStageActivityDate &&
            renderFirstStageActivityDate(stages.first().get("name"), daysToFirstStageDone)}

        <RenderExpectedDetails
            job={job}
            jobUiState={jobUiState}
            onLocalJobChange={onLocalJobChange}
            onJobChange={onJobChange}
            onJobUiStateChange={onJobUiStateChange}
            theme={theme}
        />

        {renderCandidatesSection(job, liveCandidateRows, candidatePipelineWidth, stages, onActivityClick, theme)}

        {renderMinimisingCandidatesSection(
            placedCandidateRows,
            "placed",
            () => onJobUiStateChange(job.get("id"), jobUiState.update("placedExpanded", flag => !flag)),
            jobUiState.get("placedExpanded"),
            job,
            candidatePipelineWidth,
            stages,
            onActivityClick,
            theme)}

        {renderMinimisingCandidatesSection(
            deadCandidateRows,
            "rejected",
            () => onJobUiStateChange(job.get("id"), jobUiState.update("deadExpanded", flag => !flag)),
            jobUiState.get("deadExpanded"),
            job,
            candidatePipelineWidth,
            stages,
            onActivityClick,
            theme)}

      </div>);
};

const renderBasicJobDetails = (job, jobRow) => {
  return (
      <div style={jobDetailsRowStyle}>
        <JobInfo label="Type" value={job.get("employmentType")} />
        <JobInfo label="Priority" value={job.get("priority")} />
        <JobInfo label="Contact" value={jobRow.getIn(["clientContact", "name"])} />
        <JobInfo label="Status" value={job.get("crmStatus")} />
        <JobInfo label="Openings" value={job.get("openings")} />
      </div>);
};

const renderLastActivityDate = (lastActivityDate) => {
  return (
      <div style={jobDetailsRowStyle}>
        <JobInfo
            label="Last Activity"
            value={`${getTimeAgo(lastActivityDate)} (${formatDateForDisplay(lastActivityDate)})`} />
      </div>);
};

const renderFirstStageActivityDate = (firstStageName, daysToFirstStageDone) => {
  return (
      <div style={jobDetailsRowStyle}>
        <JobInfo
            label={`First ${firstStageName}  in`}
            value={`${daysToFirstStageDone} ${dayWord(daysToFirstStageDone)}`} />
      </div>);
};

const RenderExpectedDetails = ({job, jobUiState, onLocalJobChange, onJobChange, onJobUiStateChange, theme}) => {
  const [expectedValues, setExpectedValues] = React.useState(job);

  return (
      <div style={jobDetailsRowStyle}>
        {jobUiState.get("valueIsEditable") ?
            renderExpectedValueEditing(expectedValues, setExpectedValues) :
            renderExpectedElement(
                theme,
                job,
                onJobUiStateChange,
                jobUiState,
                "Expected Value",
                job.get("value") && Formatter.format({currency: job.get("currencyCode"), value: job.get("value")}),
                getClientPermissions().includes("CAN_EDIT_JOB_VALUE"))}

        {jobUiState.get("closeDateIsEditable") ?
            renderExpectedDateEditing(expectedValues, setExpectedValues) :
            renderExpectedElement(
                theme,
                job,
                onJobUiStateChange,
                jobUiState,
                "Expected Placement Date",
                job.get("closeDate") && formatDateForDisplay(job.get("closeDate")),
                getClientPermissions().includes("CAN_EDIT_JOB_CLOSE_DATE"))}

        {(jobUiState.get("closeDateIsEditable") || jobUiState.get("valueIsEditable")) &&
            <>
              {renderDetailsEditDoneButton(
                  expectedValues,
                  jobUiState,
                  onJobUiStateChange,
                  onLocalJobChange,
                  onJobChange)}
              {renderDetailsEditCancelButton(job, jobUiState, onJobUiStateChange, setExpectedValues)}
            </>}
      </div>);
};

const renderExpectedValueEditing = (expectedValue, setExpectedValue) => {
  return (
      <div style={{display: "inline-block", marginTop: "0.3rem"}}>
        <FormControl style={{width: 100, verticalAlign: "top"}}>
          <InputLabel>Currency</InputLabel>
          <SelectField
              variant="standard"
              value={expectedValue.get("currencyCode")}
              MenuProps={{MenuListProps: {style: {maxHeight: 200}}}}
              onChange={event => {
                const newJob = expectedValue.set("currencyCode", event.target.value);
                setExpectedValue(newJob);
              }}>
            {getCurrencies().map(c => <MenuItem
                key={c.get("code")}
                value={c.get("code")}>
              {currencyRepo.toSymbol(c)}
            </MenuItem>)}
          </SelectField>
        </FormControl>

        <NumberField
            variant="standard"
            style={{width: 150, verticalAlign: "top", marginLeft: "1rem"}}
            label="Expected Value"
            value={expectedValue.get("value")}
            onChange={value => setExpectedValue(expectedValue.set("value", value))} />
      </div>);
};

const renderExpectedDateEditing = (expectedValue, setExpectedValue) => {
  const divStyle = {
    display: "inline-block",
    verticalAlign: "top",
    width: 200,
    marginTop: "1rem",
    marginLeft: "0.5rem",
    position: "relative"
  };
  const labelStyle = {
    position: "absolute",
    top: "-1rem",
    fontSize: "0.7rem",
    pointerEvents: "none",
    userSelect: "none"
  };
  return (
      <div style={divStyle} className="expectedPlacementDate">
        <label style={labelStyle}>Expected Placement Date</label>
        <DatePicker
            onDateChange={(closeDate, inputState) => {
              if (closeDate.isValid() || inputState === "") {
                const newJob = expectedValue.set("closeDate", closeDate.isValid() ? closeDate : inputState);
                setExpectedValue(newJob);
              }
            }}
            value={expectedValue.get("closeDate")}
            hideError={true} />
      </div>);
};

const renderExpectedElement = (theme, job, onJobUiStateChange, jobUiState, label, value, canEdit) => {
  return (
      <JobInfo
          onClick={() => onJobUiStateChange(
              job.get("id"),
              jobUiState
                  .set("closeDateIsEditable", true)
                  .set("valueIsEditable", true))}
          label={label}
          parentDivStyle={{
            ...(jobUiState.get("closeDateIsEditable") ? {marginTop: "2.5rem"} : {}),
            verticalAlign: "top"
          }}
          valueStyle={!value && canEdit ? {color: theme.palette.primary.main} : {}}
          value={value}
          unavailableText={canEdit ? "(click to edit)" : " -- "}
          theme={theme}
      />);
};

const renderDetailsEditDoneButton = (expectedValues, jobUiState, onJobUiStateChange, onLocalJobChange, onJobChange) => {
  return (
      <TextButton
          label="Save Changes"
          type="success"
          style={{
            fontSize: "13px",
            verticalAlign: "top",
            marginTop: "0.9rem",
            marginLeft: "0.5rem"
          }}
          onClick={() => {
            onLocalJobChange(expectedValues);
            onJobChange(expectedValues);
            onJobUiStateChange(
                expectedValues.get("id"),
                jobUiState
                    .set("closeDateIsEditable", false)
                    .set("valueIsEditable", false));
          }} />);
};

const renderDetailsEditCancelButton = (job, jobUiState, onJobUiStateChange, setExpectedValues) => {
  return (
      <TextButton
          label="Cancel"
          style={{
            background: "none !important",
            verticalAlign: "top",
            marginTop: "1rem",
            marginLeft: "1rem"
          }}
          onClick={() => {
            setExpectedValues(job);
            onJobUiStateChange(
                job.get("id"),
                jobUiState
                    .set("closeDateIsEditable", false)
                    .set("valueIsEditable", false));
          }} />);
};

const renderCandidatesSection = (job, candidateRows, candidatePipelineWidth, stages, onActivityClick, theme) => {
  return (
      <div style={jobDetailsRowStyle}>
        <div style={{width: pxToRem(candidatePipelineWidth) - 1 + "rem"}}>
          {candidateRows.map(candidateRow => <CandidateRowSvg
              key={candidateRow.get("candidate").get("id")}
              stages={stages}
              job={job}
              onActivityClick={onActivityClick}
              candidateRow={candidateRow}
              theme={theme}
          />)}
        </div>
      </div>);
};

const renderMinimisingCandidatesSection = (
    candidateRows,
    candidateStage,
    onMinimiseToggle,
    isExpanded,
    job,
    candidatePipelineWidth,
    stages,
    onActivityClick,
    theme) => {
  const rowCount = candidateRows.count();
  if (rowCount === 0) {
    return null;
  } else {
    return (
        <div style={jobDetailsRowStyle}>
          <div onClick={onMinimiseToggle}>
            <span>{rowCount} {candidateStage} {candidateWord(rowCount)} </span>
            {rowCount > 0 &&
                <span style={{fontSize: "0.9rem", color: theme.palette.primary.main}}>
              (click to {isExpanded ? "hide" : "show"})
            </span>}
          </div>

          {isExpanded &&
              <div style={{width: pxToRem(candidatePipelineWidth) - 1 + "rem"}}>
                {candidateRows.map(candidateRow => <CandidateRowSvg
                    key={candidateRow.get("candidate").get("id")}
                    stages={stages}
                    job={job}
                    onActivityClick={onActivityClick}
                    candidateRow={candidateRow}
                    theme={theme} />)}
              </div>}
        </div>
    );
  }
};

const candidateWord = count => count === 1 ? "candidate" : "candidates";

const createShortLabel = function() {
  const maxWordLength = 9;
  const words = this.value.trim().split(" ", 2);
  const shortWords = words
      .map(word => {
        if (word.length > maxWordLength) {
          return word.substr(0, maxWordLength) + "…";
        } else {
          return word;
        }
      });
  let htmlString = "";
  shortWords.forEach((word, index) => {
    if (index === 0) {
      htmlString = word;
    } else {
      htmlString += "<br />" + word;
    }
  });

  if (htmlString[htmlString.length] !== "…" && this.value.trim().split(" ").length > 2) {
    htmlString += " …";
  }
  return `<span title="${this.value}">${htmlString}</span>`;
};

const matchSearch = (jobRow, job, jobFilterText, candidateFilterText) => {
  let matchesJobText;
  if (jobFilterText) {
    const words = Immutable.List(jobFilterText.toLowerCase().split(" "));
    const rowString = simpleColumns
        .filter(column => column.get("searchable"))
        .map(column => column.get("titleFn")(jobRow, job))
        .join(" ")
        .toLowerCase();
    matchesJobText = words.every(word => rowString.indexOf(word) !== -1);
  } else {
    matchesJobText = true;
  }

  let matchesCandidateText;
  if (candidateFilterText) {
    const words = Immutable.List(candidateFilterText.toLowerCase().split(" "));
    const rowString = jobRow
        .get("candidateRows")
        .map(cr => cr.getIn(["candidate", "name"]))
        .join(" ")
        .toLowerCase();
    matchesCandidateText = words.every(word => rowString.indexOf(word) !== -1);
  } else {
    matchesCandidateText = true;
  }

  return matchesJobText && matchesCandidateText;
};

const pxToRem = px => px / 16;
const rem = x => x + "rem";

const jobQueries = Immutable.Set.of("JOBS_ADDED", "LIVE_JOBS");
const getJobKpis = () => Immutable
    .fromJS(kpiRepo.getAll().toJSON())
    .filter(k => jobQueries.contains(k.get("type").get("query")));

const getClientPermissions = () => Immutable.Set(currentClient.get("permissions")).map(p => p.name);

const countUniqueIds = xs => xs.map(x => x.get("id")).toSet().count();

const getCurrencies = () => Immutable
    .fromJS(currencyRepo.getAll().toJSON())
    .map(c => c.set("symbol", currencyRepo.toSymbol(c)));

const getKpi = kpiId => {
  const kpi = kpiRepo.get(kpiId);
  if (kpi) {
    return Immutable.fromJS(kpi.toJSON());
  } else {
    return null;
  }
};

const getImmutableUser = userId => {
  return maybeBackboneToImmutable(Users.getUser(userId));
};
const getImmutableCurrentUser = () => maybeBackboneToImmutable(Users.getCurrentUser());

const jobDetailsRowStyle = {
  marginTop: "0.5rem",
  marginBottom: "0.5rem"
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Page theme={theme} {...props} />;
};
